import React from "react";
import { Stars } from "react-bootstrap-icons";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

function TableClicked(props: any) {
  const { t } = useTranslation("ViewEmail.TableClicked");

  return (
    <div className="table-responsive">
      <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle">
        <thead className="thead-light" style={{ position: "sticky", top: 0, zIndex: 90 }}>
          <tr>
            <th>{t("nameAndUsername")}</th>
            <th>
              {t("firstName")} <Stars size="14" className="mb-1" />
            </th>
            <th>{t("linkClicked")}</th>
            <th>{t("clickedAt")}</th>
            <th>{t("fromIP")}</th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((user: any, index: any) => (
            <Row data={user} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function Row(props: any) {
  return (
    <tr>
      <td>
        <a
          className="media align-items-center"
          href={`https://instagram.com/${props.data.username}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="avatar avatar-soft-dark avatar-circle mr-3">
            <span className="avatar-initials">
              {
                // the first letter of the username in upper case
                props?.data?.prospect.username?.charAt(0).toUpperCase()
              }
            </span>
          </div>
          <div className="media-body">
            <span className="d-block h5 text-hover-primary mb-0">
              {props.data.prospect.full_name}
            </span>
            <span className="d-block font-size-sm text-body">
              {props.data.prospect.username}
            </span>
          </div>
        </a>
      </td>
      <td>{props.data.prospect.first_name || "-"}</td>
      <td>{props.data.url ? props.data.url : "-"}</td>
      <td>
        {props.data.clickedAt ? (
          <Moment fromNow>{props.data.clickedAt.toDate()}</Moment>
        ) : (
          "-"
        )}
      </td>
      <td>{props.data.ip ? props.data.ip : "-"}</td>
    </tr>
  );
}

export default TableClicked;