import React from "react";
import { Modal } from "react-bootstrap";

function ConfirmPurchase({ show, setShow, plan, handlePurchase, loading, paymentIsLoading }: any) {
  return (
    <Modal show={show} onHide={() => setShow(false)} size="sm" backdrop={paymentIsLoading ? "static" : true}>
      <Modal.Header>
        <Modal.Title>Technical Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-body">
            <h6>One time payment</h6>
            <h4>
              {new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 3,
              }).format(plan?.price)}{" "}
            </h4>
          </div>
        </div>
        <button className="mt-4 btn btn-primary w-100" onClick={handlePurchase} disabled={loading || paymentIsLoading}>
          {paymentIsLoading ? (
            <div className="spinner-border spinner-border-sm m-0" role="status"></div>
          ) : (
            <>
              Pay{" "}
              {new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 3,
              }).format(plan?.price)}
            </>
          )}
        </button>
        <p className="small text-center mb-0 mt-3">One-time payment.</p>
        <div className="text-center">
          <img
            src="/assets/img/store/powered-by-stripe.png"
            alt="Secure payment badge"
            className="img-fluid mt-3 mx-auto text-center"
            style={{ maxWidth: 100 }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmPurchase;
