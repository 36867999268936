import React, { useContext, useEffect, useState } from "react";
import { getProspectByUsername } from "../../lib/firebase/prospects";
import { useParams } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import toast from "react-hot-toast";
import Loading from "../../components/Loading";
import { capitalizeString } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

function Prospect() {
  const { t } = useTranslation("Prospect.index");
  const { username } = useParams();
  const { user } = useContext(UserContext) as any;

  const [prospect, setProspect] = useState(null) as any;
  const [loading, setLoading] = useState(false);

  const fetchProspect = async () => {
    try {
      if (!username) return;
      setLoading(true);
      const prospect = await getProspectByUsername(user.firebaseUser.uid, username);
      setProspect(prospect);
      console.log("prospect", prospect);
      setLoading(false);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProspect();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="content container-fluid">
      <div className="row justify-content-lg-center">
        <div className="col-lg-10">
          <div className="profile-cover">
            <div className="profile-cover-img-wrapper">
              <img className="profile-cover-img" src="/assets/img/1920x400/img1.jpg" alt="Image Description" />
            </div>
          </div>
          <div className="text-center mb-5">
            <div className="avatar avatar-xxl avatar-circle profile-cover-avatar">
              <img className="avatar-img" src="/assets/img/160x160/img9.jpg" alt="Image Description" />
              <span className="avatar-status avatar-status-success" />
            </div>
            <h1 className="page-header-title">
              {prospect?.full_name && capitalizeString(prospect?.full_name)}
              <i
                className="bi-patch-check-fill fs-2 text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                aria-label="Top endorsed"
                data-bs-original-title="Top endorsed"
              />
            </h1>
            <ul className="list-inline list-px-2">
              <li className="list-inline-item">
                <i className="bi-building me-1" />
                <span>Htmlstream</span>
              </li>
              <li className="list-inline-item">
                <i className="bi-geo-alt me-1" />
                <a href="#">San Francisco,</a>
                <a href="#">US</a>
              </li>
              <li className="list-inline-item">
                <i className="bi-calendar-week me-1" />
                <span>{t("joinedDate")}</span>
              </li>
            </ul>
          </div>
          <div className="js-nav-scroller hs-nav-scroller-horizontal mb-5">
            <span className="hs-nav-scroller-arrow-prev" style={{ display: "none" }}>
              <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                <i className="bi-chevron-left" />
              </a>
            </span>
            <span className="hs-nav-scroller-arrow-next" style={{ display: "none" }}>
              <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                <i className="bi-chevron-right" />
              </a>
            </span>
            <ul className="nav nav-tabs align-items-center">
              <li className="nav-item">
                <a className="nav-link active" href="./user-profile.html">
                  {t("profile")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="./user-profile-teams.html">
                  {t("teams")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="./user-profile-projects.html">
                  {t("projects")} <span className="badge bg-soft-dark text-dark rounded-circle ms-1">3</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="./user-profile-connections.html">
                  {t("connections")}
                </a>
              </li>
              <li className="nav-item ms-auto">
                <div className="d-flex gap-2">
                  <div className="form-check form-check-switch">
                    <input className="form-check-input" type="checkbox" id="connectCheckbox" />
                    <label className="form-check-label btn btn-sm" htmlFor="connectCheckbox">
                      <span className="form-check-default">
                        <i className="bi-person-plus-fill" /> {t("connect")}
                      </span>
                      <span className="form-check-active">
                        <i className="bi-check-lg me-2" /> {t("connected")}
                      </span>
                    </label>
                  </div>
                  <a className="btn btn-icon btn-sm btn-white" href="#">
                    <i className="bi-list-ul me-1" />
                  </a>
                  <div className="dropdown nav-scroller-dropdown">
                    <button
                      type="button"
                      className="btn btn-white btn-icon btn-sm"
                      id="profileDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi-three-dots-vertical" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-end mt-1" aria-labelledby="profileDropdown">
                      <span className="dropdown-header">{t("settings")}</span>
                      <a className="dropdown-item" href="#">
                        <i className="bi-share-fill dropdown-item-icon" /> {t("shareProfile")}
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="bi-slash-circle dropdown-item-icon" /> {t("blockPageProfile")}
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="bi-info-circle dropdown-item-icon" /> {t("suggestEdits")}
                      </a>
                      <div className="dropdown-divider" />
                      <span className="dropdown-header">{t("feedback")}</span>
                      <a className="dropdown-item" href="#">
                        <i className="bi-flag dropdown-item-icon" /> {t("report")}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card card-body mb-3 mb-lg-5">
                <h5>{t("completeProfile")}</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="progress flex-grow-1">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "82%" }}
                      aria-valuenow={82}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <span className="ms-4">82%</span>
                </div>
              </div>
              <div id="accountSidebarNav" />
              <div
                className="js-sticky-block card mb-3 mb-lg-5 hs-kill-sticky"
                data-hs-sticky-block-options='{
            "parentSelector": "#accountSidebarNav",
            "breakpoint": "lg",
            "startPoint": "#accountSidebarNav",
            "endPoint": "#stickyBlockEndPoint",
            "stickyOffsetTop": 20
          }'
              >
                <div className="card-header">
                  <h4 className="card-header-title">{t("profile")}</h4>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled list-py-2 text-dark mb-0">
                    <li className="pb-0">
                      <span className="card-subtitle">{t("about")}</span>
                    </li>
                    <li>
                      <i className="bi-person dropdown-item-icon" /> Ella Lauda
                    </li>
                    <li>
                      <i className="bi-briefcase dropdown-item-icon" /> {t("noDepartment")}
                    </li>
                    <li>
                      <i className="bi-building dropdown-item-icon" /> Htmlstream
                    </li>
                    <li className="pt-4 pb-0">
                      <span className="card-subtitle">{t("contacts")}</span>
                    </li>
                    <li>
                      <i className="bi-at dropdown-item-icon" /> ella@site.com
                    </li>
                    <li>
                      <i className="bi-phone dropdown-item-icon" /> +1 (609) 972-22-22
                    </li>
                    <li className="pt-4 pb-0">
                      <span className="card-subtitle">{t("teams")}</span>
                    </li>
                    <li>
                      <i className="bi-people dropdown-item-icon" /> {t("memberOfTeams")}
                    </li>
                    <li>
                      <i className="bi-stickies dropdown-item-icon" /> {t("workingOnProjects")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="d-grid gap-3 gap-lg-5">
                <div className="card">
                  <div className="card-header card-header-content-between">
                    <h4 className="card-header-title">{t("activityStream")}</h4>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                        id="contentActivityStreamDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi-three-dots-vertical" />
                      </button>
                      <div className="dropdown-menu dropdown-menu-end mt-1" aria-labelledby="contentActivityStreamDropdown">
                        <span className="dropdown-header">{t("settings")}</span>
                        <a className="dropdown-item" href="#">
                          <i className="bi-share-fill dropdown-item-icon" /> {t("shareConnections")}
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="bi-info-circle dropdown-item-icon" /> {t("suggestEdits")}
                        </a>
                        <div className="dropdown-divider" />
                        <span className="dropdown-header">{t("feedback")}</span>
                        <a className="dropdown-item" href="#">
                          <i className="bi-chat-left-dots dropdown-item-icon" /> {t("report")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body card-body-height" style={{ height: "30rem" }}>
                    <ul className="step step-icon-xs mb-0">
                      <li className="step-item">
                        <div className="step-content-wrapper">
                          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                          <div className="step-content">
                            <h5 className="step-title">
                              <a className="text-dark" href="#">
                                {t("taskReportUploaded")}
                              </a>
                            </h5>
                            <p className="fs-5 mb-1">
                              {t("addedFilesToTask")}{" "}
                              <a className="text-uppercase" href="#">
                                <i className="bi-journal-bookmark-fill" /> Fd-7
                              </a>
                            </p>
                            <ul className="list-group">
                              <li className="list-group-item list-group-item-light">
                                <div className="row gx-1">
                                  <div className="col-sm-4">
                                    <div className="d-flex">
                                      <span className="flex-shrink-0">
                                        <img
                                          className="avatar avatar-xs"
                                          src="./assets/svg/brands/excel-icon.svg"
                                          alt="Image Description"
                                        />
                                      </span>
                                      <div className="flex-grow-1 text-truncate ms-2">
                                        <span className="d-block fs-6 text-dark text-truncate" title="weekly-reports.xls">
                                          weekly-reports.xls
                                        </span>
                                        <span className="d-block small text-muted">12kb</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="d-flex">
                                      <span className="flex-shrink-0">
                                        <img className="avatar avatar-xs" src="./assets/svg/brands/word-icon.svg" alt="Image Description" />
                                      </span>
                                      <div className="flex-grow-1 text-truncate ms-2">
                                        <span className="d-block fs-6 text-dark text-truncate" title="weekly-reports.xls">
                                          weekly-reports.xls
                                        </span>
                                        <span className="d-block small text-muted">4kb</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="d-flex">
                                      <span className="flex-shrink-0">
                                        <img className="avatar avatar-xs" src="./assets/svg/brands/word-icon.svg" alt="Image Description" />
                                      </span>
                                      <div className="flex-grow-1 text-truncate ms-2">
                                        <span className="d-block fs-6 text-dark text-truncate" title="monthly-reports.xls">
                                          monthly-reports.xls
                                        </span>
                                        <span className="d-block small text-muted">8kb</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <span className="text-muted small text-uppercase">{t("now")}</span>
                          </div>
                        </div>
                      </li>
                      <li className="step-item">
                        <div className="step-content-wrapper">
                          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                          <div className="step-content">
                            <h5 className="step-title">
                              <a className="text-dark" href="#">
                                {t("projectStatusUpdated")}
                              </a>
                            </h5>
                            <p className="fs-5 mb-1">
                              {t("markedAs")}{" "}
                              <a className="text-uppercase" href="#">
                                <i className="bi-journal-bookmark-fill" /> Fr-6
                              </a>{" "}
                              {t("as")}{" "}
                              <span className="badge bg-soft-success text-success rounded-pill">
                                <span className="legend-indicator bg-success" />
                                {t("completed")}
                              </span>
                            </p>
                            <span className="text-muted small text-uppercase">{t("today")}</span>
                          </div>
                        </div>
                      </li>
                      <li className="step-item">
                        <div className="step-content-wrapper">
                          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                          <div className="step-content">
                            <h5 className="step-title">
                              <a className="text-dark" href="#">
                                {t("newCardStylesAdded")}
                              </a>
                            </h5>
                            <p className="fs-5 mb-1">
                              {t("addedCardsTo")} <a href="#">Payments</a>
                            </p>
                            <ul className="list-group">
                              <li className="list-group-item list-group-item-light">
                                <div className="row gx-2">
                                  <div className="col">
                                    <img className="img-fluid rounded-2" src="./assets/svg/components/card-1.svg" alt="Image Description" />
                                  </div>
                                  <div className="col">
                                    <img className="img-fluid rounded-2" src="./assets/svg/components/card-2.svg" alt="Image Description" />
                                  </div>
                                  <div className="col">
                                    <img className="img-fluid rounded-2" src="./assets/svg/components/card-3.svg" alt="Image Description" />
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <span className="text-muted small text-uppercase">{t("may12")}</span>
                          </div>
                        </div>
                      </li>
                      <li className="step-item">
                        <div className="step-content-wrapper">
                          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                          <div className="step-content">
                            <h5 className="step-title">
                              <a className="text-dark" href="#">
                                {t("deanAddedNewMember")}
                              </a>
                            </h5>
                            <p className="fs-5 mb-1">{t("addedMemberToDashboard")}</p>
                            <span className="text-muted small text-uppercase">{t("may15")}</span>
                          </div>
                        </div>
                      </li>
                      <li className="step-item">
                        <div className="step-content-wrapper">
                          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                          <div className="step-content">
                            <h5 className="step-title">
                              <a className="text-dark" href="#">
                                {t("projectStatusUpdated")}
                              </a>
                            </h5>
                            <p className="fs-5 mb-1">
                              {t("markedAs")}{" "}
                              <a className="text-uppercase" href="#">
                                <i className="bi-journal-bookmark-fill" /> Fr-3
                              </a>{" "}
                              {t("as")}{" "}
                              <span className="badge bg-soft-primary text-primary rounded-pill">
                                <span className="legend-indicator bg-primary" />
                                {t("inProgress")}
                              </span>
                            </p>
                            <span className="text-muted small text-uppercase">{t("apr29")}</span>
                          </div>
                        </div>
                      </li>
                      <li className="step-item">
                        <div className="step-content-wrapper">
                          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                          <div className="step-content">
                            <h5 className="step-title">
                              <a className="text-dark" href="#">
                                {t("achievements")}
                              </a>
                            </h5>
                            <p className="fs-5 mb-1">
                              {t("earnedBadge")} <i className="tio-verified text-primary" /> {t("badge")}
                            </p>
                            <span className="text-muted small text-uppercase">{t("apr06")}</span>
                          </div>
                        </div>
                      </li>
                      <li id="collapseActivitySection" className="step-item collapse">
                        <div className="step-content-wrapper">
                          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                          <div className="step-content">
                            <h5 className="step-title">
                              <a className="text-dark" href="#">
                                {t("projectStatusUpdated")}
                              </a>
                            </h5>
                            <p className="fs-5 mb-1">
                              {t("updatedAs")}{" "}
                              <a className="text-uppercase" href="#">
                                <i className="bi-journal-bookmark-fill" /> Fr-3
                              </a>{" "}
                              {t("as")}{" "}
                              <span className="badge bg-soft-secondary text-secondary rounded-pill">
                                <span className="legend-indicator bg-secondary" />
                                {t("toDo")}
                              </span>
                            </p>
                            <span className="text-muted small text-uppercase">{t("feb10")}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    <a
                      className="link link-collapse"
                      data-bs-toggle="collapse"
                      href="#collapseActivitySection"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseActivitySection"
                    >
                      <span className="link-collapse-default">{t("viewMore")}</span>
                      <span className="link-collapse-active">{t("viewLess")}</span>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <div className="card h-100">
                      <div className="card-header">
                        <h4 className="card-header-title">{t("connections")}</h4>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled list-py-3 mb-0">
                          <li>
                            <div className="d-flex align-items-center">
                              <a className="d-flex align-items-center me-2" href="#">
                                <div className="flex-shrink-0">
                                  <div className="avatar avatar-sm avatar-soft-primary avatar-circle">
                                    <span className="avatar-initials">R</span>
                                    <span className="avatar-status avatar-sm-status avatar-status-warning" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h5 className="text-hover-primary mb-0">Rachel Doe</h5>
                                  <span className="fs-6 text-body">{t("connectionsCount")}</span>
                                </div>
                              </a>
                              <div className="ms-auto">
                                <div className="form-check form-check-switch">
                                  <input className="form-check-input" type="checkbox" id="connectionsCheckbox1" defaultChecked />
                                  <label className="form-check-label btn-icon btn-xs rounded-circle" htmlFor="connectionsCheckbox1">
                                    <span className="form-check-default">
                                      <i className="bi-person-plus-fill" />
                                    </span>
                                    <span className="form-check-active">
                                      <i className="bi-check-lg" />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex align-items-center">
                              <a className="d-flex align-items-center me-2" href="#">
                                <div className="flex-shrink-0">
                                  <div className="avatar avatar-sm avatar-circle">
                                    <img className="avatar-img" src="./assets/img/160x160/img8.jpg" alt="Image Description" />
                                    <span className="avatar-status avatar-sm-status avatar-status-success" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h5 className="text-hover-primary mb-0">Isabella Finley</h5>
                                  <span className="fs-6 text-body">{t("connectionsCount")}</span>
                                </div>
                              </a>
                              <div className="ms-auto">
                                <div className="form-check form-check-switch">
                                  <input className="form-check-input" type="checkbox" id="connectionsCheckbox2" />
                                  <label className="form-check-label btn-icon btn-xs rounded-circle" htmlFor="connectionsCheckbox2">
                                    <span className="form-check-default">
                                      <i className="bi-person-plus-fill" />
                                    </span>
                                    <span className="form-check-active">
                                      <i className="bi-check-lg" />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex align-items-center">
                              <a className="d-flex align-items-center me-2" href="#">
                                <div className="flex-shrink-0">
                                  <div className="avatar avatar-sm avatar-circle">
                                    <img className="avatar-img" src="./assets/img/160x160/img3.jpg" alt="Image Description" />
                                    <span className="avatar-status avatar-sm-status avatar-status-warning" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h5 className="text-hover-primary mb-0">David Harrison</h5>
                                  <span className="fs-6 text-body">{t("connectionsCount")}</span>
                                </div>
                              </a>
                              <div className="ms-auto">
                                <div className="form-check form-check-switch">
                                  <input className="form-check-input" type="checkbox" id="connectionsCheckbox3" defaultChecked />
                                  <label className="form-check-label btn-icon btn-xs rounded-circle" htmlFor="connectionsCheckbox3">
                                    <span className="form-check-default">
                                      <i className="bi-person-plus-fill" />
                                    </span>
                                    <span className="form-check-active">
                                      <i className="bi-check-lg" />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex align-items-center">
                              <a className="d-flex align-items-center me-2" href="#">
                                <div className="flex-shrink-0">
                                  <div className="avatar avatar-sm avatar-circle">
                                    <img className="avatar-img" src="./assets/img/160x160/img6.jpg" alt="Image Description" />
                                    <span className="avatar-status avatar-sm-status avatar-status-danger" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h5 className="text-hover-primary mb-0">Costa Quinn</h5>
                                  <span className="fs-6 text-body">{t("connectionsCount")}</span>
                                </div>
                              </a>
                              <div className="ms-auto">
                                <div className="form-check form-check-switch">
                                  <input className="form-check-input" type="checkbox" id="connectionsCheckbox4" />
                                  <label className="form-check-label btn-icon btn-xs rounded-circle" htmlFor="connectionsCheckbox4">
                                    <span className="form-check-default">
                                      <i className="bi-person-plus-fill" />
                                    </span>
                                    <span className="form-check-active">
                                      <i className="bi-check-lg" />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a className="card-footer text-center" href="user-profile-connections.html">
                        {t("viewAllConnections")} <i className="bi-chevron-right" />
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card h-100">
                      <div className="card-header">
                        <h4 className="card-header-title">{t("teams")}</h4>
                      </div>
                      <div className="card-body">
                        <ul className="nav nav-pills card-nav card-nav-vertical nav-pills">
                          <li>
                            <a className="nav-link" href="#">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <i className="bi-people-fill nav-icon text-dark" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <span className="d-block text-dark">#digitalmarketing</span>
                                  <small className="d-block text-muted">{t("membersCount")}</small>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <i className="bi-people-fill nav-icon text-dark" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <span className="d-block text-dark">#ethereum</span>
                                  <small className="d-block text-muted">{t("membersCount")}</small>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <i className="bi-people-fill nav-icon text-dark" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <span className="d-block text-dark">#conference</span>
                                  <small className="d-block text-muted">{t("membersCount")}</small>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <i className="bi-people-fill nav-icon text-dark" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <span className="d-block text-dark">#supportteam</span>
                                  <small className="d-block text-muted">{t("membersCount")}</small>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <i className="bi-people-fill nav-icon text-dark" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <span className="d-block text-dark">#invoices</span>
                                  <small className="d-block text-muted">{t("membersCount")}</small>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <a className="card-footer text-center" href="user-profile-teams.html">
                        {t("viewAllTeams")} <i className="bi-chevron-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header card-header-content-between">
                    <h4 className="card-header-title">{t("projects")}</h4>
                    <div className="dropdowm">
                      <button
                        type="button"
                        className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                        id="projectReportDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi-three-dots-vertical" />
                      </button>
                      <div className="dropdown-menu dropdown-menu-end mt-1" aria-labelledby="projectReportDropdown">
                        <span className="dropdown-header">{t("settings")}</span>
                        <a className="dropdown-item" href="#">
                          <i className="bi-share-fill dropdown-item-icon" /> {t("shareConnections")}
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="bi-info-circle dropdown-item-icon" /> {t("suggestEdits")}
                        </a>
                        <div className="dropdown-divider" />
                        <span className="dropdown-header">{t("feedback")}</span>
                        <a className="dropdown-item" href="#">
                          <i className="bi-chat-left-dots dropdown-item-icon" /> {t("report")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                      <thead className="thead-light">
                        <tr>
                          <th>{t("project")}</th>
                          <th style={{ width: "40%" }}>{t("progress")}</th>
                          <th className="table-text-end">{t("hoursSpent")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <span className="avatar avatar-xs avatar-soft-dark avatar-circle">
                                <span className="avatar-initials">U</span>
                              </span>
                              <div className="ms-3">
                                <h5 className="mb-0">UI/UX</h5>
                                <small>{t("updatedHoursAgo")}</small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-3">0%</span>
                              <div className="progress table-progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "0%" }}
                                  aria-valuenow={0}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="table-text-end">4:25</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <img className="avatar avatar-xs" src="./assets/svg/brands/spec-icon.svg" alt="Image Description" />
                              <div className="ms-3">
                                <h5 className="mb-0">{t("auditStore")}</h5>
                                <small>{t("updatedDayAgo")}</small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-3">45%</span>
                              <div className="progress table-progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "45%" }}
                                  aria-valuenow={45}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="table-text-end">18:42</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <img className="avatar avatar-xs" src="./assets/svg/brands/capsule-icon.svg" alt="Image Description" />
                              <div className="ms-3">
                                <h5 className="mb-0">{t("customerRelationships")}</h5>
                                <small>{t("updatedDaysAgo")}</small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-3">59%</span>
                              <div className="progress table-progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "59%" }}
                                  aria-valuenow={59}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="table-text-end">9:01</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <img className="avatar avatar-xs" src="./assets/svg/brands/mailchimp-icon.svg" alt="Image Description" />
                              <div className="ms-3">
                                <h5 className="mb-0">{t("subscriptionMethod")}</h5>
                                <small>{t("updatedDaysAgo")}</small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-3">57%</span>
                              <div className="progress table-progress">
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{ width: "57%" }}
                                  aria-valuenow={57}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="table-text-end">0:37</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <img className="avatar avatar-xs" src="./assets/svg/brands/figma-icon.svg" alt="Image Description" />
                              <div className="ms-3">
                                <h5 className="mb-0">{t("newTheme")}</h5>
                                <small>{t("updatedWeekAgo")}</small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-3">100%</span>
                              <div className="progress table-progress">
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{ width: "100%" }}
                                  aria-valuenow={100}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="table-text-end">24:12</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <span className="avatar avatar-xs avatar-soft-info avatar-circle">
                                <span className="avatar-initials">I</span>
                              </span>
                              <div className="ms-3">
                                <h5 className="mb-0">{t("socialBanners")}</h5>
                                <small>{t("updatedWeekAgo")}</small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-3">0%</span>
                              <div className="progress table-progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "0%" }}
                                  aria-valuenow={0}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="table-text-end">8:08</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a className="card-footer text-center" href="./projects.html">
                    {t("viewAllProjects")} <i className="bi-chevron-right" />
                  </a>
                </div>
              </div>
              <div id="stickyBlockEndPoint" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prospect;