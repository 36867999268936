import { addDoc, collection, doc, limit as firebaseLimit, getDoc, getDocs, query, updateDoc, where, writeBatch } from "firebase/firestore";
import { db } from "./firebase";

const PAGE_SIZE = 100;

export const createProject = async (userId: string, project: any) => {
  const userRef = doc(db, "users", userId);
  const docRef = await addDoc(collection(userRef, "projects"), project);
  return await getProjectById(userId, docRef.id);
};

export const getProjectById = async (userId: any, projectId: any) => {
  const userRef = doc(db, "users", userId);
  const projectRef = doc(userRef, "projects", projectId);
  const docSnapshot = await getDoc(projectRef);
  if (docSnapshot.exists()) {
    return { id: docSnapshot.id, ...docSnapshot.data() };
  } else {
    throw new Error("Project does not exist.");
  }
};

export const getProjects = async (userId: string) => {
  const projectsCollectionRef = collection(db, "users", userId, "projects");
  let q = query(projectsCollectionRef, firebaseLimit(PAGE_SIZE));

  const querySnapshot = await getDocs(q);
  const projects = [] as any[];
  querySnapshot.forEach((doc) => {
    projects.push({ id: doc.id, ...doc.data() });
  });

  return projects;
};

export const updateProject = async (userId: string, projectId: string, project: any) => {
  // Referencia al documento que quieres actualizar
  const emailRef = doc(db, "users", userId, "projects", projectId);
  return updateDoc(emailRef, project);
};

export const deleteProject = async (userId: string, projectId: string) => {
  const userRef = doc(db, "users", userId);
  const projectRef = doc(userRef, "projects", projectId);

  const batch = writeBatch(db);

  // Eliminar el proyecto
  batch.delete(projectRef);

  // Función auxiliar para eliminar documentos en una colección donde 'project' es projectId
  const deleteRelatedDocuments = async (collectionPath: any) => {
    const querySnapshot = await getDocs(query(collection(db, collectionPath), where("project", "==", projectId)));
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
  };

  // Eliminar todos los emails, senders y analysis asociados con este proyecto
  await Promise.all([
    deleteRelatedDocuments(`users/${userId}/emails`),
    deleteRelatedDocuments(`users/${userId}/senders`),
    deleteRelatedDocuments(`users/${userId}/analysis`),
  ]);

  // Ejecutar el batch
  await batch.commit();
};
