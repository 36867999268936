import db from "../db/db";

export const createUser = async (user) => {
  try {
    const id = await db.analyzedUsers.add(user);
    return id;
  } catch (error) {
    throw error;
  }
};

export const createUsers = async (users) => {
  try {
    const ids = await db.analyzedUsers.bulkAdd(users, { allKeys: true });
    return ids;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await db.analyzedUsers.delete(id);
  } catch (error) {
    throw error;
  }
};

export const deleteUsers = async (ids) => {
  try {
    await db.analyzedUsers.bulkDelete(ids);
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id, user) => {
  try {
    await db.analyzedUsers.update(id, user);
  } catch (error) {
    throw error;
  }
};

export const getAllUsers = async (page = 0, perPage = 100) => {
  try {
    const users = await db.analyzedUsers
      .offset(page * perPage)
      .limit(perPage)
      .toArray();
    return users;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersWith = async (fields = [], sortBy = "", page = 0, perPage = 100) => {
  try {
    let usersQuery = db.analyzedUsers;

    if (!usersQuery) return [];

    for (let field of fields) {
      usersQuery = usersQuery.filter((user) => user[field] !== undefined && user[field] !== "");
    }

    const users = await usersQuery
      .offset(page * perPage)
      .limit(perPage)
      .reverse()
      .sortBy(sortBy);

    return users;
  } catch (error) {
    throw error;
  }
};

export const clearAllAnalyzedUsers = async () => {
  db.analyzedUsers
    .clear()
    .then(() => {
      console.log("Todos los registros han sido eliminados.");
    })
    .catch((error) => {
      console.error("Error al eliminar los registros:", error);
      throw error;
    });
};
