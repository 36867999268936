import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

export const getInvitations = async (page: number = 1, limit: number = 10, token: string) => {
  const url = `${BACKEND_URL}/v1/invitations?page=${page}&limit=${limit}`;
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return await makeRequest({ url, method, headers });
};

export const createInvitation = async (email: string, token: string) => {
  const url = `${BACKEND_URL}/v1/invitations/create`;
  const method = "POST";
  const body = {
    email: email,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return await makeRequest({ url, method, body, headers });
};

/**
 * In order to accept the invitation
 * @param invitedBy: is the id of the user who invited the current user
 */
export const acceptInvitation = async (invitedById: string, token: string) => {
  const url = `${BACKEND_URL}/v1/invitations/accept`;
  const method = "POST";
  const body = {
    invitedById: invitedById,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return await makeRequest({ url, method, body, headers });
};
