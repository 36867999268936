import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Item(props: any) {
  return (
    <li className="breadcrumb-item">
      <Link className="breadcrumb-link" to={props.to}>
        {props.children}
      </Link>
    </li>
  );
}

function Breadcumb(props: any) {
  const { t } = useTranslation("Component.Breadcumb");

  const items = props.items.map((item: any, index: any) => {
    return (
      <Item to={item.href || item.to} key={index}>
        {item.title}
      </Item>
    );
  });

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb breadcrumb-no-gutter">
        <Item to="/">{t("home")}</Item>
        {items}
      </ol>
    </nav>
  );
}

export default Breadcumb;