import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import CountdownTimer from "../components/CountdownTimer";
import ImpersonateStrip from "../components/ImpersonateStrip";
import AccountStatusAlert from "../components/AccountStatusAlert";
import { UserContext } from "../contexts/UserContext";
import Survey from "../components/Survey";
import PremiumModal from "../components/PremiumModal";
import ProductTour from "../components/ProductTour";
import { useNavigate } from "react-router-dom";
import { ProjectProvider } from "../contexts/ProjectContext";
import { useTranslation } from "react-i18next";

interface Props {
  // any props that come into the component
  children?: ReactNode;
}

const MainLayout: FC<Props> = ({ children }) => {
  const { user } = useContext(UserContext) as any;
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { t } = useTranslation("Layouts.MainLayout");

  useEffect(() => {
    const hash = window.location.hash;
    setShowUpgradeModal(hash.includes("#upgrade"));
  }, []);

  useEffect(() => {
    if (!user) return;
    const wizardImplementationDate = new Date("April 25, 2024 00:00:00 GMT+0000").getTime();
    const userCreationDate = user?.firebaseUser?.metadata?.createdAt;
    if (!user?.firebaseUser?.wizard?.completed && Number(wizardImplementationDate) <= userCreationDate) {
      navigate(`/onboarding/step/${user?.firebaseUser?.wizard?.currentStep || "1"}`);
    }
  }, [user?.firebaseUser?.wizard]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ProjectProvider>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <CountdownTimer />
      <ImpersonateStrip />
      <ProductTour />

      <Navbar setSidebarOpen={setSidebarOpen} />
      <main id="main" className="main pointer-event">
        <div className="content container-fluid">
          <AccountStatusAlert />
          {children}
        </div>
      </main>
      <Footer />
      {user.firebaseUser &&
        !user.firebaseUser?.hasSubmittedSurvey &&
        user.firebaseUser.plan && // Verifica que plan sea truthy
        user.firebaseUser.plan !== "free" && // Asegura que plan no sea "free"
        user.firebaseUser.tour?.completed && <Survey />}

      <PremiumModal show={showUpgradeModal} setShow={setShowUpgradeModal} title={t("upgradeTitle")} />
    </ProjectProvider>
  );
};

export default MainLayout;
