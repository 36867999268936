import React, { useContext, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { createProject, getProjects } from "../lib/firebase/projects";
import { UserContext } from "../contexts/UserContext";
import { ProjectContext } from "../contexts/ProjectContext";
import { useTranslation } from "react-i18next";

const MAX_PROJECTS = 3;

function NewProjectModal({ show, setShow }: any) {
  const { t } = useTranslation("Component.NewProjectModal");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const { user } = useContext(UserContext) as any;
  const { setProject } = useContext(ProjectContext) as any;

  const getProjectList = () => {
    try {
      return getProjects(user.firebaseUser.uid);
    } catch (err: any) {
      toast.error(t("errorFetchingProjects"));
    }
  };

  const handleCreateProject = async () => {
    try {
      if (!projectName) throw new Error("Project name is required");
      setLoading(true);
      const projects = (await getProjectList()) as any;
      if (projects?.length >= MAX_PROJECTS) {
        throw new Error(t("projectLimitReached", { count: projects.length, max: MAX_PROJECTS }));
      }
      const newProject = await createProject(user.firebaseUser.uid, { name: projectName, createdAt: new Date() });
      setProject(newProject);
      toast.success(t("projectCreated", { name: projectName }));
      setProjectName("");
      handleClose();
    } catch (err: any) {
      toast.error(err.message || t("errorCreatingProject"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header>
        <Modal.Title>{t("createNewProject")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("namePlaceholder")}
              value={projectName}
              maxLength={20}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button variant="primary" onClick={handleCreateProject} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : t("confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewProjectModal;