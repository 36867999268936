import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function UpdateBillingModal() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const handleSubmit = () => {};

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Modal show={false} centered size="sm">
      <Modal.Header>
        <Modal.Title>Update Billing Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Address</label>
            <input type="text" name="address" className="form-control" value={formData.address} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">City</label>
            <input type="text" name="city" className="form-control" value={formData.city} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">State</label>
            <input type="text" name="state" className="form-control" value={formData.state} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Zip</label>
            <input type="text" name="zip" className="form-control" value={formData.zip} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Country</label>
            <select>
              <option>United States</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Germany</option>
              <option>France</option>
            </select>
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>
            Update
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateBillingModal;
