import React from "react";
import Notification from "../../components/Notification";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function AddNewSenderModal(props: any) {
  const { t } = useTranslation("Senders.AddNewSenderModal");

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>{t("selectEmailAccountType")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-4 mb-4">
            <div className="card" onClick={props.handleConnectGmail} style={{ cursor: "pointer" }}>
              <div className="card-body text-center">
                <img className="avatar mb-2" src="/assets/img/logos/gmail.jpg" alt="Gmail Logo" />
                <h6 className="m-0 p-0">Gmail</h6>
              </div>
            </div>
          </div>
          {/*<div className="col-4">
                    <div className="card" onClick={props.handleConnectOutlook} style={{ cursor: 'pointer' }}>
                       <div className="card-body text-center">
                          <img className="avatar mb-2" src="./assets/img/logos/outlook.png" alt="Outlook Logo" />
                          <h6 className="m-0 p-0">Outlook</h6>
                       </div>
                    </div>
                 </div>*/}
          <div className="col-4 mb-4">
            <div className="card" onClick={props.handleConnectSendgrid} style={{ cursor: "pointer" }}>
              <div className="card-body text-center">
                <img className="avatar mb-2" src="/assets/svg/brands/sendgrid.svg" alt="Sendgrid Logo" />
                <h6 className="m-0 p-0">Sendgrid</h6>
              </div>
            </div>
          </div>
          <div className="col-4 mb-4">
            <div className="card" onClick={props.handleConnectOther} style={{ cursor: "pointer" }}>
              <div className="card-body text-center">
                <img className="avatar mb-2" src="/assets/img/logos/ses.png" alt="Sendgrid Logo" />
                <h6 className="m-0 p-0">Amazon SES</h6>
              </div>
            </div>
          </div>
          <div className="col-4 mb-4">
            <div className="card" onClick={props.handleConnectOther} style={{ cursor: "pointer" }}>
              <div className="card-body text-center">
                <img className="avatar mb-2" src="/assets/svg/other.svg" alt="Other Logo" />
                <h6 className="m-0 p-0">{t("other")}</h6>
              </div>
            </div>
          </div>
        </div>
        <Notification
          message={t("mailerfindNotice")}
          type="primary"
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddNewSenderModal;