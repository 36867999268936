import debounce from "lodash.debounce";
import React from "react";
import AsyncSelect from "react-select/async";
import { STATUS } from "../../utils/constants";
import { ArrowLeft } from "react-bootstrap-icons";
import { searchPlaces } from "../../api/mailerfind/search";
import { auth } from "../../lib/firebase/firebase";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function LocationSelect(props: any) {
  const { t } = useTranslation("LocationSelect");
  return (
    <div>
      <div className="card mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-auto pr-2">
              <button
                type="button"
                className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                aria-label={t("previousCustomer")}
                data-bs-original-title={t("previousCustomer")}
                onClick={props.handleBack}
                disabled={props.status !== STATUS.NOT_STARTED}
              >
                <ArrowLeft />
              </button>
            </div>
            <div className="col-auto my-auto p-0">
              <h3 className="card-header-title m-0 p-0">Select Location</h3>
            </div>
          </div>
          <p>{t("selectLocationInstruction")}</p>
          <LocationSearchBar onChange={props.onSelect} disabled={props.status !== STATUS.NOT_STARTED} />
        </div>
      </div>
    </div>
  );
}

function LocationSearchBar(props: any) {
  const { t } = useTranslation("LocationSelect");

  const searchForLocations = async (locationName: any) => {
    try {
      const jwt = (await auth.currentUser?.getIdToken()) as any;
      const response = await searchPlaces(locationName, jwt);
      return response.places;
    } catch (err: any) {
      console.log(err);
      toast.error(err.message || t("searchError"));
    }
  };

  const giveResultsOfSearch = async (query: any) => {
    if (!query) return [];
    const locations: any = await searchForLocations(query);
    const options = locations.map((location: any) => {
      return {
        label: location.name,
        value: location.external_id,
      };
    });
    return options;
  };

  const loadOptions = debounce((inputValue: string, callback: (options: any) => void) => {
    giveResultsOfSearch(inputValue).then((options: any) => {
      callback(options);
    });
  }, 1000);

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      isMulti={false}
      cacheOptions={true}
      defaultOptions={true}
      onChange={props.onChange}
      isDisabled={props.disabled}
    />
  );
}

export default LocationSelect;
