import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

function AddUnsubscribeLink({ show, setShow, handleSubmit }: any) {
  const handleClose = () => setShow(false);
  const [text, setText] = useState("");

  const loading = false;

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header>
        <Modal.Title>Add an unsubscribe link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>
              Enter the text for an unsubscribe link. Mailerfind will automatically generate and attach a custom unsubscribe link to it
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="For example: Click here to unsubscribe"
              value={text}
              maxLength={100}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => handleSubmit(text)} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddUnsubscribeLink;
