import { useCallback } from "react";
import { send } from "../api/mailerfind/email";
import { auth } from "../lib/firebase/firebase";

const useEmailSender = (email: any) => {
  const sendEmail = useCallback(
    async (prospect: any, emailId: any) => {
      const jwt = await auth.currentUser!.getIdToken();
      await send(emailId, prospect, true, jwt);
    },
    [email]
  );

  return { sendEmail };
};

export default useEmailSender;
