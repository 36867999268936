import React from "react";
import { useTranslation } from "react-i18next";

function BeforeYouDiveIn() {
  const { t } = useTranslation("Component.BeforeYouDiveIn");

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">{t("welcomeTitle")}</h4>
      </div>
      <div className="card-body p-0">
        <div style={{ position: "relative", paddingBottom: "64.92%", height: 0 }}>
          <iframe
            src="https://www.loom.com/embed/eaf2f3a1fe1f4ddcb1876fb5e3137102?sid=e6d8920f-9961-48e8-9366-c6a50f87fb8d"
            frameBorder={0}
            title={t("videoTitle")}
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderBottomRightRadius: "0.75rem",
              borderBottomLeftRadius: "0.75rem",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default BeforeYouDiveIn;