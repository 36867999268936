import { signOut as firebaseSignOut } from "firebase/auth";
import { auth } from "./firebase";
import { Crisp } from "crisp-sdk-web";

const signOut = () => {
  firebaseSignOut(auth)
    .then(() => {
      console.log("log out successful");
      // Execute this sequence when your users are logging out
      Crisp.setTokenId(); // 1. Clear the token value
      Crisp.session.reset(); // 2. Unbind the current session
    })
    .catch((error: any) => {
      // An error happened.
      console.log("error logging out");
      console.error(error);
    });
};

export { signOut };
