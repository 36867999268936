import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Search, Stars } from "react-bootstrap-icons";
import { formatFollowers } from "../../utils/numberFormatter";
import Header from "../../components/Header";
import ExportLoadingPopUp from "../../components/ExportLoadingPopUp";
import { auth } from "../../lib/firebase/firebase";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Prospects() {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedOption, setFilterByOption] = useState("any");
  const [sortByOption, setSortByOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [user] = useState(auth.currentUser) as any;
  const { t } = useTranslation();
  const RESULTS_PER_PAGE = 100;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearchChange = (e: any) => {
    const searchValue = e.target.value;
    const filteredUsers = users.filter((user: any) => {
      return user.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setUsers(filteredUsers);
  };

  const handleSelectChange = (e: any) => {
    setFilterByOption(e.target.value);
  };

  const handleSortByChange = (e: any) => {
    setSortByOption(e.target.value);
  };

  const filterUsers = async () => {
    let filteredUsers = users as any;
    switch (selectedOption) {
      case "users_with_email":
        setUsers(filteredUsers);
        break;
      case "users_with_first_name_and_email":
        setUsers(filteredUsers);
        break;
      case "users_with_phone":
        setUsers(filteredUsers);
        break;
      case "users_with_website":
        setUsers(filteredUsers);
        break;
      default:
        setUsers(filteredUsers);
        break;
    }
  };

  useEffect(() => {
    filterUsers();
  }, [selectedOption, sortByOption]);

  const handleCsvFullExport = async () => {
    handleShow();
    handleClose();
  };

  const handleCsvFilteredExport = () => {};

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    filterUsers();
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    filterUsers();
  };

  return (
    <>
      <Header
        title={t("prospectExplorer")}
        subtitle={t("exploreProspects")}
        breadCrumbItems={[
          { title: t("home"), href: "/" },
          { title: t("prospectExplorer"), href: "#" },
        ]}
      />
      <div className="card" style={{ height: "80vh", overflowY: "auto" }}>
        <div className="card-header">
          <div className="row justify-content-between align-items-center flex-grow-1">
            <div className="col-12 col-md">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">{t("userExplorer")}</h3>
              </div>
            </div>
          </div>

          <div className="col-md-auto">
            <form>
              <div className="input-group input-group-merge input-group-flush">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <Search />
                  </div>
                </div>
                <input
                  id="datatableSearch"
                  type="search"
                  className="form-control"
                  placeholder={t("searchUsers")}
                  aria-label={t("searchUsers")}
                  onChange={handleSearchChange}
                />
              </div>
            </form>
          </div>

          <div className="col-md-auto">
            <div className="form-group mb-0">
              <select
                id="exampleFormControlSelect1"
                className="form-control btn-white text-muted dropdown-toggle"
                onChange={handleSortByChange}
                value={sortByOption}
                disabled={users.length === 0}
              >
                <option value="">{t("sortBy")}</option>
                <option value="username">{t("username")}</option>
                <option value="followers">{t("followers")}</option>
                <option value="following">{t("following")}</option>
              </select>
            </div>
          </div>

          <div className="col-md-auto">
            <div className="form-group mb-0">
              <select
                id="exampleFormControlSelect1"
                className="form-control btn-white text-muted dropdown-toggle"
                onChange={handleSelectChange}
                value={selectedOption}
                disabled={users.length === 0}
              >
                <option value="any">{t("filter")}</option>
                <option value="users_with_email">{t("usersWithEmail")}</option>
                <option value="users_with_first_name_and_email">{t("usersWithEmailAndFirstName")}</option>
                <option value="users_with_phone">{t("usersWithPhone")}</option>
                <option value="users_with_website">{t("usersWithWebsite")}</option>
              </select>
            </div>
          </div>

          <div className="col-md-auto">
            <div className="btn-group">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButtonWhite"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={users.length === 0}
              >
                <span className="mr-2">
                  <FontAwesomeIcon icon={faDownload} />
                </span>
                {t("export")}
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButtonWhite">
                <div className="dropdown-item" onClick={handleCsvFullExport} style={{ cursor: "pointer" }}>
                  <img
                    className="avatar avatar-xss avatar-4by3 mr-2"
                    src="/assets/svg/components/placeholder-csv-format.svg"
                    alt="Image Description"
                  />
                  {t("fullDataCsv")}
                </div>
                <div className="dropdown-item" onClick={handleCsvFilteredExport} style={{ cursor: "pointer" }}>
                  <img
                    className="avatar avatar-xss avatar-4by3 mr-2"
                    src="/assets/svg/components/placeholder-csv-format.svg"
                    alt="Image Description"
                  />
                  {t("filteredDataCsv")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle">
            <thead className="thead-light" style={{ position: "sticky", top: 0, zIndex: 90 }}>
              <tr>
                <th>{t("nameAndUsername")}</th>
                <th>
                  {t("firstName")} <Stars size="14" className="mb-1" />
                </th>
                <th>{t("email")}</th>
                <th>{t("website")}</th>
                <th>{t("phone")}</th>
                <th>{t("following")}</th>
                <th>{t("followers")}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user: any, index: any) => (
                <Row data={user} key={index} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
            <div className="col-sm mb-2 mb-sm-0"></div>
            <div className="col-sm-auto">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <nav id="datatablePagination" aria-label="Activity pagination">
                  <div className="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                    <ul id="datatable_pagination" className="pagination datatable-custom-pagination">
                      <li className={`paginate_item page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <button
                          className="paginate_button previous page-link"
                          onClick={handlePreviousPage}
                          aria-controls="datatable"
                          data-dt-idx={0}
                          tabIndex={0}
                          id="datatable_previous"
                        >
                          <span aria-hidden="true">{t("prev")}</span>
                        </button>
                      </li>
                      {currentPage - 2 > 0 && (
                        <li className="paginate_item page-item">
                          <button
                            className="paginate_button page-link"
                            onClick={() => setCurrentPage(currentPage - 2)}
                            aria-controls="datatable"
                            data-dt-idx={1}
                            tabIndex={0}
                          >
                            {currentPage - 2}
                          </button>
                        </li>
                      )}
                      {currentPage - 1 > 0 && (
                        <li className="paginate_item page-item">
                          <button
                            className="paginate_button page-link"
                            onClick={() => setCurrentPage(currentPage - 1)}
                            aria-controls="datatable"
                            data-dt-idx={2}
                            tabIndex={0}
                          >
                            {currentPage - 1}
                          </button>
                        </li>
                      )}
                      <li className="paginate_item page-item active">
                        <button
                          className="paginate_button page-link"
                          onClick={() => setCurrentPage(currentPage)}
                          aria-controls="datatable"
                          data-dt-idx={3}
                          tabIndex={0}
                        >
                          {currentPage}
                        </button>
                      </li>
                      <li className="paginate_item page-item">
                        <button
                          className="paginate_button next page-link"
                          onClick={handleNextPage}
                          aria-controls="datatable"
                          data-dt-idx={4}
                          tabIndex={0}
                          id="datatable_next"
                        >
                          <span aria-hidden="true">{t("next")}</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ExportLoadingPopUp show={show} />
    </>
  );
}

function Row(props: any) {
  return (
    <tr>
      <td>
        <Link className="media align-items-center" to={`/prospects/1`}>
          <div className="avatar avatar-soft-dark avatar-circle mr-3">
            <span className="avatar-initials">
              {props?.data?.username?.charAt(0).toUpperCase()}
            </span>
          </div>
          <div className="media-body">
            <span className="d-block h5 text-hover-primary mb-0">{props.data.full_name}</span>
            <span className="d-block font-size-sm text-body">{props.data.username}</span>
          </div>
        </Link>
      </td>
      <td>{props.data.first_name || "-"}</td>
      <td>{props.data.email ? props.data.email : "-"}</td>
      <td>
        <a href={props.data.website} target="_blank" rel="noopener">
          {props.data.website
            ? props.data.website.slice(0, 25) + (props.data.website.length > 25 ? "..." : "")
            : "-"}
        </a>
      </td>
      <td>{props.data.phone_number ? props.data.phone_number : "-"}</td>
      <td className="">{formatFollowers(props.data.following, 1)}</td>
      <td className="">{formatFollowers(props.data.followers, 1)}</td>
    </tr>
  );
}

export default Prospects;