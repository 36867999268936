import { faLifeRing } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ExclamationDiamondFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation("Component.Footer");
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="row justify-content-between align-items-center">
        <div className="col">
          <p className="font-size-sm mb-0">
            © Mailerfind <span className="d-none d-sm-inline-block">{currentYear}. {t("allRightsReserved")}</span>
          </p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-end">
            {/* List Dot */}
            <ul className="list-inline list-separator">
              <li className="list-inline-item">
                <a className="list-separator-link" href="https://help.mailerfind.com/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faLifeRing} className="mr-2" />
                  {t("helpCenter")}
                </a>
              </li>
              <li className="list-inline-item">
                <a className="list-separator-link" href="https://mailerfindstatus.com" target="_blank" rel="noreferrer">
                  <ExclamationDiamondFill className="mr-2" />
                  {t("status")}
                </a>
              </li>
            </ul>
            {/* End List Dot */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;