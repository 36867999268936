import React, { useEffect, useState } from "react";
import { EnvelopeOpen, Link45deg, Send } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { createInvitation, getInvitations } from "../../api/mailerfind/invitations";
import { auth } from "../../lib/firebase/firebase";
import { FRONTEND_URL } from "../../utils/constants";
import InvitesTable from "./InvitesTable";
import { useTranslation } from "react-i18next";

function Invitations() {
  const { t } = useTranslation("Invitations.index");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [invitations, setInvitations] = useState([] as any[]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 5;

  const fetchInvitations = async (page = 1) => {
    try {
      setLoading(true);
      const jwt = auth.currentUser ? await auth.currentUser.getIdToken() : "";
      const response = await getInvitations(page, limit, jwt);
      setInvitations(response.data);
      setPage(response.meta.page);
      setTotalPages(Math.ceil(response.meta.total / limit));
    } catch (err: any) {
      toast.error(err.message || t("error_occurred"));
    } finally {
      setLoading(false);
    }
  };

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(`${FRONTEND_URL}/register?plan=free&ref=${auth.currentUser?.uid}`);
    toast.success(t("link_copied"));
  };

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!email) return toast.error(t("no_email"));
    let toastId = null;
    try {
      setLoading(true);
      toastId = toast.loading(t("sending_invitation"));
      const jwt = auth.currentUser ? await auth.currentUser.getIdToken() : "";
      await createInvitation(email.trim(), jwt);
      await fetchInvitations();
      setEmail("");
      toast.success(t("invitation_sent", { email }), { id: toastId });
    } catch (err: any) {
      toast.error(err.message, { id: toastId });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvitations(page);
  }, [page]);

  const handlePageChange = (event: any) => {
    const page = event.selected + 1;
    console.log("user selected page", page);
    setPage(page);
  };

  return (
    <>
      <div className="card card-lg mb-5">
        <div className="card-header">
          <h4 className="card-header-title">{t("invite_users")}</h4>
        </div>
        <div className="card-body">
          <div className="row p-6">
            <div className="col-lg-6 my-auto">
              <h1>{t("get_free_credits")}</h1>
              <p>{t("receive_credits")}</p>
              <div className="mb-3">
                <label htmlFor="inputGroupMergeEmail" className="form-label">
                  {t("email")}
                </label>
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend input-group-text" id="inputGroupMergeEmailAddOn">
                    <EnvelopeOpen />
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={handleChange}
                    className="form-control"
                    id="inputGroupMergeEmail"
                    placeholder={t("email_placeholder")}
                    aria-label={t("email_placeholder")}
                    aria-describedby="inputGroupMergeEmailAddOn"
                  />
                </div>
              </div>
              <div className="d-flex">
                <button className="btn btn-white mr-2 w-100" onClick={handleCopyInviteLink}>
                  <Link45deg size={20} className="mr-2" />
                  <span className="align-middle">{t("copy_invite_link")}</span>
                </button>
                <button className="btn btn-primary w-100" onClick={handleSubmit} disabled={loading}>
                  {t("send_invite")}
                  <Send size={16} className="ml-2" />
                </button>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-none text-center my-auto">
              <img src="/assets/svg/illustrations/oc-referrals.svg" className="w-50" alt="Referrals" />
            </div>
          </div>
        </div>
      </div>
      <InvitesTable invitations={invitations} loading={loading} page={page} handlePageChange={handlePageChange} totalPages={totalPages} />
    </>
  );
}

export default Invitations;