import React from "react";
import { CloudFill } from "react-bootstrap-icons";

function Rocket() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="loader">
        <div className="rocket">
          <img src="/assets/vendor/duotone-icons/gen/gen002.svg" className="fas fa-rocket" alt="Rocket" />
          <CloudFill className="fas fa-cloud cloud-0" />
          <CloudFill className="fas fa-cloud cloud-1" />
          <CloudFill className="fas fa-cloud cloud-2" />
          <CloudFill className="fas fa-cloud cloud-3" />
        </div>
        <span>
          <i />
        </span>
      </div>
    </div>
  );
}

export default Rocket;
