import db from "../db/db";
import { faker } from "@faker-js/faker";
import { createUser } from "./analyzedUsersDAO";
import { STATUS } from "../../utils/constants";

export const createAnalysis = async (analysisName, userId, options) => {
  const newAnalysis = {
    name: analysisName,
    status: STATUS.NOT_STARTED,
    selectedItem: options?.selectedItem || null,
    mode: options?.mode || null,
    businessAccountsCount: 0,
    emailsFoundCount: 0,
    phoneNumbersFoundCount: 0,
    websitesFoundCount: 0,
    prospectsCount: 0,
    publicAccountsCount: 0,
    privateAccountsCount: 0,
    creditsUsed: 0,
    page: 0,
    cursor: null,
    ownerId: userId,
    createdAt: new Date(),
    project: options?.project || null,
  };

  try {
    const id = await db.analysis.add(newAnalysis);
    return id;
  } catch (error) {
    throw error;
  }
};

export const analysisExists = async (analysisId) => {
  const analysis = await db.analysis.where("id").equals(analysisId).first();
  return analysis !== undefined;
};

export const renameAnalysis = async (id, newName) => {
  try {
    await db.analysis.update(id, { name: newName });
  } catch (error) {
    throw error;
  }
};

export const createMultipleAnalyses = async (analysisName, usernamesEntered, count) => {
  const ids = [];

  for (let i = 0; i < count; i++) {
    const newAnalysis = {
      name: `${analysisName}_${i + 1}`, // Añade un sufijo al nombre para hacerlo único
      usernamesEntered: usernamesEntered || [],
      analyzedUsernames: [],
      status: STATUS.NOT_STARTED,
      progressPercentage: 0,
      emailsFoundCount: 0,
      businessAccountsCount: 0,
      createdAt: new Date(),
    };

    try {
      const id = await db.analysis.add(newAnalysis);
      ids.push(id);
    } catch (error) {
      throw error;
    }
  }

  return ids;
};

export const addUsersToAnalysis = async (analysisId, newUsers) => {
  try {
    await db.analysis
      .where("id")
      .equals(analysisId)
      .modify((analysis) => {
        if (!analysis.usernamesEntered) {
          analysis.usernamesEntered = [];
        }

        // Simplemente agregar los nuevos usuarios al final, sin verificar duplicados.
        analysis.usernamesEntered.push(...newUsers);
      });
  } catch (error) {
    throw error;
  }
};

export const createFakeEmptyAnalysis = (analysisName, usernamesEntered) => {
  const fakeUsernames = Array.from({ length: 1000000 }, () => faker.internet.userName().toLowerCase());
  usernamesEntered = fakeUsernames;

  const newAnalysis = {
    name: analysisName,
    usernamesEntered: usernamesEntered || [],
    analyzedUsernames: [],
    status: "NOT_STARTED",
    progressPercentage: 0,
    emailsFoundCount: 0,
    businessAccountsCount: 0,
    createdAt: new Date(),
  };

  try {
    const id = db.analysis.add(newAnalysis);
    return id;
  } catch (error) {
    throw error;
  }
};

export const createFakeAnalysis = async (analysisName, usernamesEntered) => {
  const fakeUsers = [];

  const hasEmail = faker.datatype.boolean();
  const hasPhoneNumber = faker.datatype.boolean();
  const hasWebsite = faker.datatype.boolean();
  const isBusiness = hasEmail && faker.datatype.boolean();

  // create a bunch of fake users (like 50.000)
  for (let i = 0; i < 10000; i++) {
    fakeUsers.push({
      username: faker.internet.userName().toLowerCase(),
      full_name: faker.internet.displayName(),
      first_name: faker.person.firstName(),
      email: hasEmail ? faker.internet.email().toLowerCase() : null,
      phone_number: hasPhoneNumber ? faker.phone.number() : null,
      website: hasWebsite ? faker.internet.url() : null,
      is_private: false,
      is_business: isBusiness,
      followers: faker.number.int({
        max: 1000000,
        min: 100,
      }),
      following: faker.number.int({
        max: 1000000,
        min: 100,
      }),
    });
  }

  // create as promises as needed to create all the fake users
  const createUserPromises = fakeUsers.map((user) => createUser(user));

  // wait for all the users to be created
  await Promise.all(createUserPromises);

  // get all the usernames entered
  usernamesEntered = fakeUsers.map((user) => user.username);

  const newAnalysis = {
    name: analysisName,
    usernamesEntered: usernamesEntered || [],
    analyzedUsernames: [],
    status: "COMPLETED",
    progressPercentage: 100,
    analyzedUsersCount: [],
    emailsFoundCount: 0,
    businessAccountsCount: 0,
    createdAt: new Date(),
  };
  try {
    const id = await db.analysis.add(newAnalysis);
    return id;
  } catch (error) {
    throw error;
  }
};

export const importAnalysis = async (analysis) => {
  try {
    const id = await db.analysis.add(analysis);
    return id;
  } catch (error) {
    throw error;
  }
};

export const getAllAnalyses = async (page = 0, pageSize = 500, userId, status, project) => {
  let analysis = await db.analysis
    .filter((analysis) => {
      // Verifica primero si el análisis coincide con el userId proporcionado.
      const isUserMatch = userId ? analysis.ownerId === userId || !analysis.ownerId : true;
      // Luego, si se proporciona un estado, verifica que el análisis tenga ese estado.
      const isStatusMatch = status ? analysis.status === status : true;
      // Finalmente si se proporciona un proyecto
      const isProjectMatch = analysis.project === project;
      // Retorna true solo si ambas condiciones se cumplen.
      return isUserMatch && isStatusMatch && isProjectMatch;
    })
    .offset(page * pageSize)
    .limit(pageSize)
    .toArray();

  return analysis;
};

export const getAnalysisCount = async () => {
  const analysisCount = await db.analysis.count();
  return analysisCount;
};

export const getAnalysis = async (userId, id) => {
  const analysis = await db.analysis.where("id").equals(Number(id)).first();
  return analysis;
};

export const updateAnalysis = async (id, analysis) => {
  try {
    await db.analysis.update(id, analysis);
  } catch (error) {
    throw error;
  }
};

export const removeAnalysis = async (id) => {
  try {
    await db.analysis.delete(id);
  } catch (error) {
    throw error;
  }
};

export const clearAllAnalyses = async () => {
  db.analysis
    .clear()
    .then(() => {
      console.log("Todos los registros han sido eliminados.");
    })
    .catch((error) => {
      console.error("Error al eliminar los registros:", error);
      throw error;
    });
};
