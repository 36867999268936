import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Boxes, ChevronRight, ExclamationTriangleFill } from "react-bootstrap-icons";
import { UserContext } from "../../../contexts/UserContext";
import toast from "react-hot-toast";
import { buySpeedAddon, previewSubscriptionItem } from "../../../api/mailerfind/stripe";
import { auth } from "../../../lib/firebase/firebase";
import ConfirmPurchase from "./ConfirmPurchase";
import Moment from "react-moment";
import ImageWithOverlay from "./ImageWithOverlay";
import { useTranslation } from "react-i18next";

const product = {
  id: 4,
  name: "Speed Boost",
  subtitle: "Save invaluable time in Mailerfind by increasing the speed of your analyses.",
  description:
    "Accelerate your analyses to collect much more data in less time and boost your company's productivity. Optimize operations and eliminate the need for constant progress monitoring. Perfect for companies aiming for efficiency.",
  tags: ["Productivity", "Popular", "Speed", "Unlimited", "Featured", "Essentials"],
  developer: {
    name: "Mailerfind",
    icon: "",
  },
  availableFor: ["unlimited"],
  images: [
    {
      src: "https://htmlstream.com/front/assets/img/900x450/img3.jpg",
      altText: "Placeholder",
      label: "",
      caption: "",
    },
    {
      src: "https://htmlstream.com/front/assets/img/900x450/img5.jpg",
      altText: "Placeholder",
      label: "",
      caption: "",
    },
  ],
  features: ["Speed up your analysis", "Multiply the data collected", "Meet tight deadlines", "Save invaluable time"],
  plans: [
    {
      name: "Normal",
      description: "Scrapes 1 user per second.",
      slug: "slow",
      price: 0,
    },
    {
      name: "Fast",
      description: "Scrapes 2 users per second.",
      slug: "fast",
      price: 250,
    },
    {
      name: "Ultra Fast",
      description: "Scrapes 8 users per second.",
      slug: "ultra_fast",
      price: 750,
    },
  ],
};

function SpeedBoost() {
  const navigate = useNavigate();
  const { t } = useTranslation("Product.SpeedBoost");

  const [loading, setLoading] = useState(false);
  const [simulatorIsLoading, setSimulatorIsLoading] = useState(false);
  const [paymentIsLoading, setPaymentIsLoading] = useState(false);

  const [showConfirmPurchaseModal, setShowConfirmPurchaseModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [price, setPrice] = useState(0);

  const { forceRefreshUser } = useContext(UserContext) as any;

  const handleConfirmPurchase = async (plan: any) => {
    setSelectedPlan(plan);
    setShowConfirmPurchaseModal(true);
  };

  useEffect(() => {
    if (!selectedPlan) return;
    previewPlan();
  }, [selectedPlan]);

  const previewPlan = async () => {
    if (!selectedPlan) return toast.error(t("noPlanSelected"));
    try {
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const result = await previewSubscriptionItem(selectedPlan.slug, jwt);
      setPrice(result.proration);
    } catch (err: any) {
      toast.error(err.message || t("errorOccurred"));
    } finally {
      setLoading(false);
    }
  };

  const handlePurchasePlan = async () => {
    if (!selectedPlan) return toast.error(t("noPlanSelected"));
    let toastId = null;
    try {
      toastId = toast.loading("Processing payment...");
      setPaymentIsLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      await buySpeedAddon(selectedPlan.slug, jwt);
      await forceRefreshUser();
      toast.success(`Success!`, { id: toastId });
      setShowConfirmPurchaseModal(false);
    } catch (err: any) {
      toast.error(err.message || t("errorOccurred"), { id: toastId });
    } finally {
      setPaymentIsLoading(false);
    }
  };

  const handleOpenSimulator = async () => {
    setSimulatorIsLoading(true);

    // Simulate that the simulator is loading
    await new Promise((resolve) => setTimeout(resolve, 2000));

    setPaymentIsLoading(false);

    // Redirect to the simulator
    navigate("/product/speed-boost/demo");
  };

  return (
    <div className="product-page mt-5">
      <div className="container content-space-t-2 content-space-b-lg-2">
        <div className="row">
          <div className="col-md-4 col-lg-3 mb-9 mb-md-0">
            <div className="card card-body py-6">
              <div className="text-center mb-4">
                <img className="avatar avatar-xl p-2" src="/assets/vendor/duotone-icons/gen/gen002.svg" alt="Logo" />
              </div>
              <div className="d-grid mb-7">
                <a className="btn btn-block btn-primary transition-3d-hover" href="#pricing">
                  {t("purchase")}
                </a>
              </div>
              <div className="mb-7">
                <h2 className="h4">{t("tags")}</h2>
                {product.tags.map((tag, index) => (
                  <a className="btn btn-soft-secondary btn-xs mr-1 mb-2" href="#" key={index}>
                    {tag}
                  </a>
                ))}
              </div>
              <div className="d-none d-md-block">
                <h4>{t("developer")}</h4>
                <a className="d-flex align-items-center" href="#">
                  <div className="flex-shrink-0">
                    <img className="avatar avatar-sm" src="/appicon.png" alt="Logo" />
                  </div>
                  <div className="flex-grow-1 ml-1">
                    <span className="link-secondary align-middle">{product.developer.name}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-9 product-column-divider column-divider-md">
            <div className="card card-body p-6">
              <div className="mb-4">
                <h1>
                  {product.name}
                  <img
                    className="avatar avatar-xs mb-1 ml-2"
                    src="/assets/svg/illustrations/top-vendor.svg"
                    alt="Badge"
                    title="Top Vendor"
                  />
                </h1>
                <p className="mb-0">{product.subtitle}</p>
              </div>

              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="featuresOne" role="tabpanel" aria-labelledby="featuresOne-tab">
                  <div className="mb-3">
                    <h4 className="mb-3">{t("demo")}</h4>
                    <ImageWithOverlay
                      Cta={
                        <button className="btn btn-primary btn-sm mt-3" onClick={handleOpenSimulator} disabled={simulatorIsLoading}>
                          {simulatorIsLoading ? (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          ) : (
                            <>
                              <Boxes className="mr-1" /> {t("launchSimulator")}
                            </>
                          )}
                        </button>
                      }
                    />
                  </div>

                  <h4 className="mb-3">{t("description")}</h4>
                  <p>{product.description}</p>

                  <p>{t("useAddonTo")}:</p>
                  <ul className="list-unstyled list-pointer mb-5">
                    {product.features.map((feature, index) => (
                      <li key={index}>
                        <ChevronRight size="12" className="align-middle mb-1 mx-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <Pricing plans={product.plans} purchasePlan={handleConfirmPurchase} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmPurchase
        show={showConfirmPurchaseModal}
        setShow={setShowConfirmPurchaseModal}
        handlePurchase={handlePurchasePlan}
        plan={selectedPlan}
        previewPrice={price}
        loading={loading}
        paymentIsLoading={paymentIsLoading}
      />
    </div>
  );
}

function Pricing({ plans, purchasePlan }: any) {
  const { t } = useTranslation("Product.SpeedBoost");
  const { user } = useContext(UserContext) as any;
  return (
    <div>
      {user.firebaseUser.plan !== "unlimited" ? (
        <div className="alert alert-light d-flex" role="alert">
          <span>
            <ExclamationTriangleFill className="mr-2" />
            <span className="align-middle">
              <b>{t("upgradePlan")}:</b> {t("featureAvailableForHigherPlans")}
            </span>
          </span>
          <Link to="/upgrade" className="btn btn-outline-secondary btn-xs ml-auto">
            {t("viewPlans")}
          </Link>
        </div>
      ) : (
        <div className="tab-pane fade active show" id="pricing" role="tabpanel" aria-labelledby="featuresThree-tab">
          <h4 className="mb-3">{t("pricingPlans")}</h4>
          <div className="d-grid gap-2 mb-5">
            {plans.map((plan: any, index: number) => (
              <Price plan={plan} purchasePlan={purchasePlan} key={index} />
            ))}
          </div>
        </div>
      )}
      {user.firebaseUser?.addons?.speed.cancels_at && (
        <div className="alert alert-light d-flex" role="alert">
          <span>
            <ExclamationTriangleFill className="mr-2" />
            <span className="align-middle">
              <b>{t("notice")}:</b> {t("subscriptionCancelledOn")}{" "}
              <Moment locale="en" format="dddd, MMMM D, YYYY">
                {user.firebaseUser?.addons?.speed?.cancels_at.toDate()}
              </Moment>
              .
            </span>
          </span>
        </div>
      )}
    </div>
  );
}

function Price({ plan, purchasePlan }: any) {
  const { t } = useTranslation("Product.SpeedBoost");
  const { user } = useContext(UserContext) as any;
  return (
    <div className="card card-sm card-bordered shadow-none mb-3">
      <div className="card-body">
        <div className="row align-items-sm-center">
          <div className="col">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <span className="svg-icon text-center text-primary" style={{ minWidth: "3rem" }}>
                  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                      <path
                        opacity="0.85"
                        d="M9.6 20.2C14.9019 20.2 19.2 15.9019 19.2 10.6C19.2 5.29807 14.9019 1 9.6 1C4.29807 1 0 5.29807 0 10.6C0 15.9019 4.29807 20.2 9.6 20.2Z"
                        fill="#377dff"
                      />

                      <path
                        opacity="0.85"
                        d="M22.4 20.2C27.702 20.2 32 15.9019 32 10.6C32 5.29807 27.702 1 22.4 1C17.0981 1 12.8 5.29807 12.8 10.6C12.8 15.9019 17.0981 20.2 22.4 20.2Z"
                        fill="#377dff"
                      />

                      <path
                        opacity="0.85"
                        d="M16.2001 31C21.502 31 25.8001 26.7019 25.8001 21.4C25.8001 16.0981 21.502 11.8 16.2001 11.8C10.8982 11.8 6.6001 16.0981 6.6001 21.4C6.6001 26.7019 10.8982 31 16.2001 31Z"
                        fill="#377dff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width={32} height={32} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <div className="flex-grow-1 ml-4">
                <h4 className="card-title mb-0">{plan.name}</h4>
              </div>
            </div>
          </div>
          <div className="col-sm-7 col-md-5">
            <p className="card-text small">{plan.description}</p>
          </div>
          <div className="col-12 col-lg-3 mt-3 mt-lg-0">
            <div className="d-grid">
              {user.firebaseUser?.analysis?.speed === plan.slug ? (
                <button className="btn btn-primary w-100" disabled={true}>
                  {t("current")}
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary w-100"
                  disabled={user.firebaseUser?.addons?.speed?.cancels_at && plan.slug === "slow"}
                  onClick={() => purchasePlan(plan)}
                >
                  {new Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3,
                  }).format(plan.price)}{" "}
                  / {t("month")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpeedBoost;
