const makeRequest = async (data: any) => {
  if (!data.url) return;
  if (!data.method) data.method = "GET"; // Set the default method to GET

  const config: RequestInit = {
    method: data.method,
    headers: data.headers,
  };

  if (data.method.toLowerCase() === "post") {
    if (data.isFormData) {
      config.body = data.body;
    } else {
      config.headers = { "Content-Type": "application/json", ...data.headers };
      config.body = JSON.stringify(data.body);
    }
  }

  const response = (await fetch(data.url, config)) as any;
  const responseBody = await response.json();

  if (response.ok) {
    return responseBody;
  } else {
    throw new Error(responseBody.message);
  }
};

// Export makeRequest
export default makeRequest;
