import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { updateUser } from "../../lib/firebase/user";
import toast from "react-hot-toast";
import Header from "../../components/Header";

function EmailSettings() {
  return (
    <>
      <Header
        title="Email Settings"
        subtitle="Manage sending settings"
        breadCrumbItems={[
          { title: "Emails", href: "/emails" },
          { title: "Settings", href: "/emails/settings" },
        ]}
      ></Header>
      <SendingSettings />
    </>
  );
}

function SendingSettings() {
  const { user } = useContext(UserContext) as any;
  const [preventRecontact, setPreventRecontact] = useState(false);

  const handleChange = async (e: any) => {
    setPreventRecontact(e.target.checked);
    try {
      await updateUser(user.firebaseUser.uid, {
        preventRecontact: e.target.checked,
      });
      toast.success("Settings updated");
    } catch (err: any) {
      console.error(err);
      toast.error("Failed: ", err.message);
    }
  };

  useEffect(() => {
    if (!user) return;
    setPreventRecontact(user?.firebaseUser?.preventRecontact);
  }, [user]);

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h4 className="card-header-title">Sending Settings</h4>
      </div>
      <div className="card-body">
        <div className="d-flex">
          <label className="switch my-auto">
            <input type="checkbox" checked={preventRecontact} onChange={handleChange} />
            <span className="slider round" />
          </label>
          <span className="ml-3 my-auto mb-0">Prevent sending emails to previously contacted prospects</span>
        </div>
      </div>
    </div>
  );
}

export default EmailSettings;
