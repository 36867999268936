import React, { useContext, useEffect, useState } from "react";
import Slider from "rc-slider";
import { Button, Modal } from "react-bootstrap";
import "rc-slider/assets/index.css";
import toast from "react-hot-toast";
import { buyCredits } from "../api/mailerfind/stripe";
import { auth } from "../lib/firebase/firebase";
import { UserContext } from "../contexts/UserContext";
import { useTranslation } from "react-i18next";

const { Handle } = Slider;

const handle = (props: any) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Slider.SliderTooltip prefixCls="rc-slider-tooltip" overlay={`${value} %`} visible={dragging} placement="top" key={index}>
      <Handle value={value} {...restProps} />
    </Slider.SliderTooltip>
  );
};

function BuyCreditsModal({ show, setShow }: any) {
  const { t } = useTranslation("Component.BuyCreditsModal");
  const { user, forceRefreshUser } = useContext(UserContext) as any;
  const handleClose = () => setShow(false);

  const [credits, setCredits] = useState(0);
  const [price, setPrice] = useState(0.02);
  const [loading, setLoading] = useState(false);

  const handleSliderChange = (value: number) => {
    setCredits(value);
  };

  useEffect(() => {
    if (!user.firebaseUser) return;
    if (!user.firebaseUser?.analysis?.pricePerAdditionalCredit) return;
    setPrice(user.firebaseUser?.analysis?.pricePerAdditionalCredit);
  }, [user.firebaseUser]);

  const handleInputChange = (e: any) => {
    setCredits(e.target.value === "" ? 0 : Number(e.target.value));
  };

  const handleConfirm = async () => {
    let toastId = null;
    try {
      setLoading(true);
      toastId = toast.loading(t("processingPayment"));
      const jwt = await auth.currentUser!.getIdToken();
      await buyCredits(credits, jwt);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await forceRefreshUser();
      toast.success(t("creditsPurchasedSuccessfully"), { id: toastId });
      setCredits(0);
      setShow(false);
    } catch (err: any) {
      toast.error(err.message, { id: toastId });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header>
        <Modal.Title>{t("buyAdditionalCredits")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="border p-5 rounded">
          <Slider
            min={0}
            max={100000}
            handle={handle}
            marks={{
              0: 0,
              25000: "25.000",
              50000: "50.000",
              75000: "75.000",
              100000: "100.000",
            }}
            step={500}
            value={credits}
            onChange={handleSliderChange}
            handleStyle={[
              {
                borderColor: "#377dff",
                width: 20,
                height: 20,
                backgroundColor: "#fff",
                opacity: 100,
                transform: "translateX(-3px) translateY(0px)",
              },
            ]}
            trackStyle={[
              {
                backgroundColor: "#377dff",
                padding: 6,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            ]}
            railStyle={{ padding: 6, backgroundColor: "rgb(234, 236, 240)" }}
            dotStyle={{
              borderColor: "white",
              width: 8,
              height: 8,
              backgroundColor: "white",
              transform: "translateY(4px)",
            }}
          />
          <div className="row mt-5 pt-3">
            <div className="col-lg-6">
              <div className="form-group">
                <label>{t("credits")}</label>
                <input
                  type="number"
                  value={credits}
                  onChange={handleInputChange}
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <label>{t("price")}</label>
              <p className="bg-light py-2 rounded px-2 text-center border">
                {new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 3,
                }).format(price)}{" "}
                / {t("credit")}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div>
            <p>{t("price")}</p>
            <p style={{ fontSize: 18 }}>
              {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(price)}{" "}
              x {credits}
            </p>
          </div>
          <div>
            <p>{t("total")}</p>
            <h2>
              {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(price * credits)}
            </h2>
          </div>
        </div>
        <Button variant="primary" className="w-100 mt-3" onClick={handleConfirm} disabled={credits === 0 || loading}>
          {t("confirmAndPay")}
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default BuyCreditsModal;
