import React, { useState, useEffect } from "react";
import useEmailSender from "../../hooks/useEmailSender";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Notification from "../../components/Notification";
import { useTranslation } from "react-i18next";

interface Props {
  sendTo: string;
  setShow: (show: boolean) => void;
  show: boolean;
  handleClose: () => void;
  emailData: any;
}

function SendExample({ sendTo: propsSendTo, setShow, show, handleClose, emailData }: Props) {
  const { t } = useTranslation("NewEmail.SendExample");
  const [sendTo, setSendTo] = useState(propsSendTo);
  const [loading, setLoading] = useState(false);
  const { sendEmail } = useEmailSender(emailData);

  useEffect(() => {
    setSendTo(propsSendTo);
  }, [propsSendTo]);

  const handleSendExample = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      await sendEmail(
        {
          email: sendTo,
        },
        emailData.id
      );
      toast.success(t("email_sent_successfully"));
      setShow(false);
    } catch (error: any) {
      toast.error(error.message || t("something_went_wrong"));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSendTo = event.target.value;
    setSendTo(newSendTo);
  };

  return (
    <Modal show={show} centered>
      <form onSubmit={handleSendExample}>
        <Modal.Header>
          <Modal.Title>{t("send_example")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="send-to">{t("send_to")}</label>
            <input
              type="text"
              autoFocus={true}
              onChange={handleChange}
              value={sendTo}
              className="form-control"
              id="send-to"
              placeholder={sendTo}
              disabled={loading}
            />
          </div>
          <Notification
            type="info"
            message={
              <>
                <span>
                  {t("validate_health")} <a target="_blank" rel="noopener noreferrer" href="https://www.mail-tester.com">mail-tester.com</a> {t("tool")}
                </span>
              </>
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-white" onClick={handleClose}>
            {t("cancel")}
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {t("send")}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default SendExample;