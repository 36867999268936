import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";
import { clearAllAnalyses } from "../../lib/daos/analysisDAO";
import toast from "react-hot-toast";
import { bytesToMB } from "../../utils/helpers";
import { clearAllProspects } from "../../lib/daos/prospectsDAO";
import { useTranslation } from "react-i18next";

function DeleteData() {
  const { t } = useTranslation("Settings.index");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [storageEstimate, setStorageEstimate] = useState<any>(null);

  const handleCheckBoxChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleDeleteData = async () => {
    try {
      setLoading(true);
      await clearAllAnalyses();
      await clearAllProspects();
      await loadStorageEstimate();
      toast.success(t("deleteSuccess"));
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
      setChecked(false);
    }
  };

  const loadStorageEstimate = async () => {
    try {
      const storageEstimate = await navigator.storage.estimate();
      setStorageEstimate(storageEstimate);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadStorageEstimate();
  }, []);

  return (
    <div className="card">
      <div className="card-header border-bottom">
        <h4 className="card-header-title">{t("purgeDataTitle")}</h4>
      </div>
      {/* Body */}
      <div className="card-body p-5">
        <p className="card-text">{t("purgeDataDescription")}</p>
        <p>
          {t("thisWillDelete")}
          <ul>
            <li>{t("deleteLocalProspects")}</li>
            <li>{t("deleteLocalAnalysis")}</li>
          </ul>
          {t("thisWillNotDelete")}
          <ul>
            <li>{t("notDeleteEmailSenderData")}</li>
            <li>{t("notDeleteSettings")}</li>
            <li>{t("notDeleteAccount")}</li>
          </ul>
        </p>
        <p>
          {t("totalStorageUsed")}: {storageEstimate ? bytesToMB(storageEstimate.usageDetails.indexedDB) + " MB" : t("calculating")}
        </p>
        <div className="mb-4">
          {/* Check */}
          <div className="form-check">
            <input
              checked={checked}
              type="checkbox"
              className="form-check-input"
              id="deleteAccountCheckbox"
              onChange={handleCheckBoxChange}
            />
            <label className="form-check-label" htmlFor="deleteAccountCheckbox">
              {t("confirmDelete")}
            </label>
          </div>
          {/* End Check */}
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-danger" onClick={handleDeleteData} disabled={!checked || loading}>
            {loading ? t("deleting") : t("delete")}
          </button>
        </div>
      </div>
      {/* End Body */}
    </div>
  );
}

function Settings() {
  const { t } = useTranslation("Settings.index");
  return (
    <div>
      <Header
        title={t("settingsTitle")}
        subtitle={t("settingsSubtitle")}
        breadCrumbItems={[
          { title: t("home"), href: "/" },
          { title: t("settingsTitle"), href: "/settings" },
        ]}
      />
      <DeleteData />
    </div>
  );
}

export default Settings;
