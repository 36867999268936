import React from "react";
import { useParams } from "react-router-dom";
import SpeedBoost from "./SpeedBoost";
import Error from "../Error";
import TechSetup from "./TechSetup";
import { useTranslation } from "react-i18next";

function Product() {
  const { t } = useTranslation("Product.index");
  const { id } = useParams();

  switch (id) {
    case "speed-boost":
      return <SpeedBoost />;

    case "tech-setup":
      return <TechSetup />;
  }
  return <Error />;
}

export default Product;
