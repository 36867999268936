import React, { useEffect, useRef, useState } from "react";
import { STATUS } from "../../../utils/constants";
import toast from "react-hot-toast";
import Progress from "../../Analysis/Progress";
import Header from "../../../components/Header";
import Notification from "../../../components/Notification";
import { Link } from "react-router-dom";
import { BoxArrowRight } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function Demo() {
  const { t } = useTranslation("Product.Demo");
  const [simulatorIsLoading, setSimulatorIsLoading] = useState(true);
  const hasToPauseSlowAnalysis = useRef(false);
  const hasToPauseFastAnalysis = useRef(false);
  const hasToPauseUltraFastAnalysis = useRef(false);

  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [slowAnalysis, setSlowAnalysis] = React.useState<any>({
    version: 2,
    mode: "followers",
    prospectsCount: 0,
    emailsFoundCount: 0,
    businessAccountsCount: 0,
    publicAccountsCount: 0,
    creditsUsed: 0,
    status: STATUS.NOT_STARTED,
  });

  const [fastAnalysis, setFastAnalysis] = React.useState<any>({
    version: 2,
    mode: "followers",
    prospectsCount: 0,
    emailsFoundCount: 0,
    businessAccountsCount: 0,
    publicAccountsCount: 0,
    creditsUsed: 0,
    status: STATUS.NOT_STARTED,
  });

  const [ultraFastAnalysis, setUltraFastAnalysis] = React.useState<any>({
    version: 2,
    mode: "followers",
    prospectsCount: 0,
    emailsFoundCount: 0,
    businessAccountsCount: 0,
    publicAccountsCount: 0,
    creditsUsed: 0,
    status: STATUS.NOT_STARTED,
  });

  useEffect(() => {
    setSimulatorIsLoading(true);
    toast
      .promise(new Promise((resolve) => setTimeout(resolve, 6410)), {
        loading: t("launchingSimulatorLoading"),
        success: t("launchingSimulatorSuccess"),
        error: t("launchingSimulatorError"),
      })
      .then(() => setSimulatorIsLoading(false));
  }, []);

  const startSlowAnalysis = async (setSlowAnalysis: any) => {
    if (slowAnalysis.status === STATUS.COMPLETED) return;
    setSlowAnalysis((prev: any) => ({ ...prev, status: STATUS.RUNNING }));
    for (let i = 0; i < 1000 - slowAnalysis.prospectsCount; i++) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setSlowAnalysis((prev: any) => ({
        ...prev,
        prospectsCount: prev.prospectsCount + 1,
        creditsUsed: Math.floor(prev.prospectsCount * 0.4),
        publicAccountsCount: Math.floor(prev.prospectsCount * 0.4),
        emailsFoundCount: Math.floor(prev.publicAccountsCount * 0.35),
        businessAccountsCount: Math.floor(prev.publicAccountsCount * 0.4),
      }));
      if (hasToPauseSlowAnalysis.current) {
        setSlowAnalysis((prev: any) => ({ ...prev, status: STATUS.PAUSED }));
        hasToPauseSlowAnalysis.current = false;
        return;
      }
    }
    setSlowAnalysis((prev: any) => ({ ...prev, status: STATUS.COMPLETED }));
  };

  const startFastAnalysis = async (setFastAnalysis: any) => {
    if (fastAnalysis.status === STATUS.COMPLETED) return;
    setFastAnalysis((prev: any) => ({ ...prev, status: STATUS.RUNNING }));
    for (let i = 0; i < 1000 - fastAnalysis.prospectsCount; i = i + 2) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setFastAnalysis((prev: any) => ({
        ...prev,
        prospectsCount: prev.prospectsCount + 2,
        creditsUsed: Math.floor(prev.prospectsCount * 0.4),
        publicAccountsCount: Math.floor(prev.prospectsCount * 0.4),
        emailsFoundCount: Math.floor(prev.publicAccountsCount * 0.35),
        businessAccountsCount: Math.floor(prev.publicAccountsCount * 0.4),
      }));
      if (hasToPauseFastAnalysis.current) {
        setFastAnalysis((prev: any) => ({ ...prev, status: STATUS.PAUSED }));
        hasToPauseFastAnalysis.current = false;
        return;
      }
    }
    setFastAnalysis((prev: any) => ({ ...prev, status: STATUS.COMPLETED }));
  };

  const startUltraFastAnalysis = async (setUltraFastAnalysis: any) => {
    if (ultraFastAnalysis.status === STATUS.COMPLETED) return;
    setUltraFastAnalysis((prev: any) => ({ ...prev, status: STATUS.RUNNING }));
    for (let i = 0; i < 1000 - ultraFastAnalysis.prospectsCount; i = i + 8) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setUltraFastAnalysis((prev: any) => ({
        ...prev,
        prospectsCount: prev.prospectsCount + 8,
        creditsUsed: Math.floor(prev.prospectsCount * 0.4),
        publicAccountsCount: Math.floor(prev.prospectsCount * 0.4),
        emailsFoundCount: Math.floor(prev.publicAccountsCount * 0.35),
        businessAccountsCount: Math.floor(prev.publicAccountsCount * 0.4),
      }));
      if (hasToPauseUltraFastAnalysis.current) {
        setUltraFastAnalysis((prev: any) => ({ ...prev, status: STATUS.PAUSED }));
        hasToPauseUltraFastAnalysis.current = false;
        return;
      }
    }
    setUltraFastAnalysis((prev: any) => ({ ...prev, status: STATUS.COMPLETED }));
  };

  useEffect(() => {
    if (slowAnalysis.status === STATUS.RUNNING || fastAnalysis.status === STATUS.RUNNING || ultraFastAnalysis.status === STATUS.RUNNING) {
      if (timerRef.current === null) {
        timerRef.current = setInterval(() => {
          setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);
      }
    } else {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    }
  }, [slowAnalysis.status, fastAnalysis.status, ultraFastAnalysis.status]);

  const handleStart = async () => {
    await Promise.all([
      startSlowAnalysis(setSlowAnalysis),
      startFastAnalysis(setFastAnalysis),
      startUltraFastAnalysis(setUltraFastAnalysis),
    ]);
  };

  const handlePause = () => {
    setSlowAnalysis((prev: any) => ({ ...prev, status: STATUS.PAUSING }));
    setFastAnalysis((prev: any) => ({ ...prev, status: STATUS.PAUSING }));
    setUltraFastAnalysis((prev: any) => ({ ...prev, status: STATUS.PAUSING }));

    hasToPauseSlowAnalysis.current = true;
    hasToPauseFastAnalysis.current = true;
    hasToPauseUltraFastAnalysis.current = true;
  };

  const handleRestart = () => {
    window.location.reload();
  };

  return (
    <>
      <Header
        title={t("header.title")}
        subtitle={t("header.subtitle")}
        breadCrumbItems={[
          { href: "/store", title: t("header.breadcrumb.store") },
          { href: "/product/speed-boost", title: t("header.breadcrumb.speedBoost") },
          { href: "", title: t("header.breadcrumb.demo") },
        ]}
      />
      {simulatorIsLoading ? (
        <div className="card card-body py-6 mt-4">
          <div className="d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div className="align-items-center justify-content-center w-50 py-6 mx-auto" style={{ height: 200 }}>
                <div className="cube">
                  <div className="sides">
                    <div className="top" />
                    <div className="right" />
                    <div className="bottom" />
                    <div className="left" />
                    <div className="front" />
                    <div className="back" />
                  </div>
                </div>
              </div>
              <h5 className="m-0">{t("simulatorLoading")}</h5>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5">
            <Notification message={t("notification.message")} type="info" />
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <Progress
                cardTitle={t("progress.normalSpeed")}
                analysis={slowAnalysis}
                totalUsersEntered={1000}
                handleStart={handleStart}
                handlePause={handlePause}
              />
            </div>
            <div className="col-lg-12 mb-4">
              <Progress
                cardTitle={t("progress.fastSpeed")}
                analysis={fastAnalysis}
                totalUsersEntered={1000}
                handleStart={handleStart}
                handlePause={handlePause}
              />
            </div>
            <div className="col-lg-12 mb-4">
              <Progress
                cardTitle={t("progress.ultraFastSpeed")}
                gradient={true}
                analysis={ultraFastAnalysis}
                totalUsersEntered={1000}
                handleStart={handleStart}
                handlePause={handlePause}
              />
            </div>
          </div>
          <div
            className="position-fixed start-50 bottom-0 w-100 zi-99 mb-3"
            style={{ maxWidth: "40rem", transform: "translateX(-50%)", left: "50%", zIndex: 99 }}
          >
            <div className="card card-sm bg-dark border-dark mx-2">
              <div className="card-body">
                <div className="row justify-content-center justify-content-sm-between">
                  <div className="col my-auto">
                    <p className="text-white my-auto m-0">
                      <div className="small">{t("elapsedTime")}</div>
                      <div className="badge bg-white text-primary">{elapsedTime} {t("seconds")}</div>
                    </p>
                  </div>
                  <div className="col-auto">
                    <div className="d-flex gap-3">
                      <button className="btn btn-ghost-light mr-3" onClick={handleRestart}>
                        {t("restart")}
                      </button>
                      <Link type="button" className="btn btn-primary" to="/product/speed-boost">
                        <span className="align-middle mr-2">{t("exit")}</span>
                        <BoxArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Demo;