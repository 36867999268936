import React from "react";

function ProgressBar(props: any) {
  return (
    <div className="progress mt-4" style={{ height: 20 }}>
      <div
        className={`progress-bar ${props.gradient ? "progress-gradient" : ""}`}
        role="progressbar"
        style={{
          width: props.progress + "%",
        }}
        aria-valuenow={0}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
}

export default ProgressBar;
