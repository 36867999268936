import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { auth, db } from "../../lib/firebase/firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getMySubscription } from "../../api/mailerfind/stripe";
import { useSearchParams } from "react-router-dom";
import { createMauticContact } from "../../api/mailerfind/users";
import { getBrowserLanguage, sanitizeName } from "../../utils/helpers";
import { getIdToken, getUser, updateUser } from "../../lib/firebase/user";
import { faker } from "@faker-js/faker";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import Slider from "react-slick";
import Testimonial from "./Testimonial";
import { doc } from "firebase/firestore";

export default function SignUp() {
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("register");
  const [searchParams] = useSearchParams();
  const [subscription, setSubscription] = useState(null) as any;
  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(false);
  const [user, setUser] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    communications: false,
    terms: false,
  });

  const userHasSubscriptionActive = () => {
    if (!subscription) return false;
    if (subscription.status === "active") return true;
    return false;
  };

  // if there is auth user, but no subscription, redirect to disclaimer
  useEffect(() => {
    if (!auth.currentUser) return;
    if (subscriptionIsLoading) return;
    if (isCreatingUser) return;
    if (!userHasSubscriptionActive()) {
      navigate("/disclaimer");
    } else {
      navigate("/");
    }
  }, [subscription, auth.currentUser, isCreatingUser]);

  useEffect(() => {
    if (!auth.currentUser) return;
    getUserSubscription();
  }, [auth.currentUser]);

  const getUserSubscription = async () => {
    try {
      setSubscriptionIsLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const { subscription } = await getMySubscription(jwt);
      setSubscription(subscription);
    } catch (err: any) {
      console.log(err);
    } finally {
      setSubscriptionIsLoading(false);
    }
  };

  const referralCodeIsValid = (referralCode: string) => {
    return referralCode.length === 36;
  };

  // Get query string plan use effect
  useEffect(() => {
    const plan = searchParams.get("plan");
    const billingPeriod = searchParams.get("period");
    const referral = searchParams.get("referral");
    const currency = searchParams.get("currency");
    const coupon = searchParams.get("coupon");
    const forcedPrice = searchParams.get("price");
    const source = searchParams.get("source");
    const invitedBy = searchParams.get("ref");
    const trial = searchParams.get("trial");

    if (plan) localStorage.setItem("plan", plan);
    if (billingPeriod) localStorage.setItem("billingPeriod", billingPeriod);
    if (referral && referralCodeIsValid(referral)) localStorage.setItem("referral", referral);
    if (currency) localStorage.setItem("currency", currency);
    if (coupon) localStorage.setItem("coupon", coupon);
    if (forcedPrice) localStorage.setItem("forcedPrice", forcedPrice);
    if (source) localStorage.setItem("source", source);
    if (invitedBy) localStorage.setItem("invitedBy", invitedBy);
    if (trial) localStorage.setItem("trial", "true");
  }, [searchParams]);

  const handleChange = (e: any) => {
    if (e.target.id === "termsCheckbox") {
      setUser({ ...user, terms: e.target.checked });
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!user.name) {
      toast.error("Please enter your name.");
      return;
    }

    if (!user.surname) {
      toast.error("Please enter your surname.");
      return;
    }

    setIsCreatingUser(true);
    try {
      const userCredential = (await createUserWithEmailAndPassword(auth, user.email, user.password)) as any;
      const actionCodeSettings = {
        url: `${window.location.origin}/?verified=true`,
        handleCodeInApp: true,
      };
      await sendEmailVerification(userCredential.user, actionCodeSettings);
      await updateFirestoreUser(user);
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      switch (errorCode) {
        case "auth/email-already-in-use":
          toast.error("Email already in use.");
          break;
        case "auth/invalid-email":
          toast.error("Invalid email.");
          break;
        case "auth/operation-not-allowed":
          toast.error("Operation not allowed.");
          break;
        case "auth/weak-password":
          toast.error("Weak password.");
          break;
        default:
          toast.error(errorMessage);
      }
    } finally {
      setIsCreatingUser(false);
    }
  };

  const handleSignUpWithGoogle = async () => {
    setIsCreatingUser(true);
    const provider = new GoogleAuthProvider();
    auth.useDeviceLanguage();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user as any;
      const parts = user.displayName.split(" ");
      const newUser = {
        name: parts[0],
        surname: parts.length > 1 ? parts[1] : "",
      };

      // Verificar la fecha de creación del usuario
      const userCreationTime = new Date(user.metadata.creationTime).getTime();
      const currentTime = Date.now();
      const timeDifferenceInSeconds = (currentTime - userCreationTime) / 1000;

      // Si el usuario fue creado hace menos de 20 segundos
      if (timeDifferenceInSeconds <= 20) {
        await updateFirestoreUser(newUser);
      } else {
        // Esperar sin hacer nada más
        await new Promise((resolve) => setTimeout(resolve, 1500));
      }
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsCreatingUser(false);
    }
  };

  /*
   * This function is used to update the user information in firestore
   * As the firestore user is initially created in the backend, we need to wait for the user to be created
   * before we can update the user information
   *
   */
  const updateFirestoreUser = async (user: any) => {
    let firebaseUser = await getUser(auth.currentUser!.uid);

    while (!firebaseUser) {
      firebaseUser = await getUser(auth.currentUser!.uid);
      await new Promise((r) => setTimeout(r, 500));
    }

    const invitedBy = localStorage.getItem("invitedBy");
    const invitedByFirestoreRef = invitedBy ? doc(db, "users", invitedBy) : null;

    await updateUser(auth.currentUser!.uid, {
      firstname: sanitizeName(user.name),
      surname: sanitizeName(user.surname),
      referral: localStorage.getItem("referral") || null,
      invitedBy: invitedByFirestoreRef,
      "preferences.communications": user.communications || false,
      "preferences.language": getBrowserLanguage(),
      "preferences.earlyRelease": false,
      "preferences.currency": localStorage.getItem("currency") || "eur",
    });

    const jwt = await getIdToken();

    await createMauticContact(jwt);
  };

  const generateRandomValues = () => {
    if (process.env.NODE_ENV !== "development") return;
    setUser({
      ...user,
      name: faker.person.firstName("male"),
      surname: faker.person.lastName("male"),
      email: faker.internet.email().toLowerCase(),
      password: faker.internet.password(),
    });
  };

  return (
    <div>
      <div>
        {/* Content */}
        <div className="container-fluid px-3">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100 bg-light px-0 py-6 order-lg-1 order-2">
              {/* Logo & Language */}
              <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3" style={{ position: "absolute", top: "0", left: "0" }}>
                <div className="d-none d-lg-flex justify-content-between">
                  <Link to="/">
                    <img
                      className="w-100"
                      src="/assets/img/logos/mailerfind.png"
                      alt="Logo"
                      data-hs-theme-appearance="default"
                      style={{ minWidth: "7rem", maxWidth: "7rem" }}
                    />
                  </Link>
                </div>
              </div>
              {/* End Logo & Language */}
              <div className="register-features">
                <div className="mb-3">
                  <h2 className="display-5">{t("promoTitle")}</h2>
                  <p>{t("promoSubtitle")}</p>
                </div>
                {/* List Checked */}
                <ul className="list-checked list-checked-lg list-checked-primary list-py-2">
                  <li className="list-checked-item">
                    <span className="d-block fw-semibold mb-1">{t("features.dataIn1Click")}</span>
                  </li>
                  <li className="list-checked-item">
                    <span className="d-block fw-semibold mb-1">{t("features.automatedMessages")}</span>
                  </li>
                  <li className="list-checked-item">
                    <span className="d-block fw-semibold mb-1">{t("features.segmentedAudiences")}</span>
                  </li>
                </ul>

                <div className="slider-container">
                  <Slider
                    {...{
                      dots: false,
                      infinite: true,
                      autoplaySpeed: 5000,
                      speed: 500,
                      centerPadding: "60px",
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      autoplay: true,
                    }}
                  >
                    {[...Array(7)].map((_, index) => (
                      <div className="px-3" key={index}>
                        <Testimonial
                          text={t(`testimonials.testimonial${index + 1}.text`)}
                          author={t(`testimonials.testimonial${index + 1}.author`)}
                          position={t(`testimonials.testimonial${index + 1}.position`)}
                          avatar={t(`testimonials.testimonial${index + 1}.avatar`)}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                {/* End List Checked */}
                <div className="row justify-content-between gx-3">
                  <div className="col">
                    <img className="img-fluid" src="/assets/img/logos/customers/5.png" alt="Logo" />
                  </div>
                  {/* End Col */}
                  <div className="col">
                    <img className="img-fluid" src="/assets/img/logos/customers/2.png" alt="Logo" />
                  </div>
                  {/* End Col */}
                  <div className="col">
                    <img className="img-fluid" src="/assets/img/logos/customers/1.png" alt="Logo" />
                  </div>
                  {/* End Col */}
                  <div className="col">
                    <img className="img-fluid" src="/assets/img/logos/customers/4.png" alt="Logo" />
                  </div>
                  {/* End Col */}
                </div>
                {/* End Row */}
                <div className="text-center">
                  <LanguageSwitcher />
                </div>
              </div>
            </div>
            {/* End Col */}
            <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100 py-6 order-lg-2 order-1">
              <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: "25rem" }}>
                <div className="text-center">
                  <div className="mb-5">
                    <h1 className="display-5">{t("createAccountTitle")}</h1>
                    <p>
                      {t("alreadyHaveAccount")}{" "}
                      <Link to="/login">
                        <b>{t("signInHere")}</b>
                      </Link>
                    </p>
                  </div>
                  <div className="d-grid mb-4">
                    <button className="btn btn-white btn-lg btn-block" onClick={handleSignUpWithGoogle} disabled={isCreatingUser}>
                      <span className="d-flex justify-content-center align-items-center">
                        <img className="avatar avatar-xss mr-2" src="/assets/svg/brands/google-icon.svg" alt="Google" />
                        Sign up with Google
                      </span>
                    </button>
                  </div>
                  <span className="divider-center text-muted mb-4">OR</span>
                </div>
                <form className="js-validate needs-validation" noValidate onSubmit={handleSubmit}>
                  <label className="form-label" htmlFor="fullNameSrEmail" onClick={generateRandomValues}>
                    {t("form.fullName")}
                  </label>
                  {/* Form */}
                  <div className="row">
                    <div className="col-sm-6">
                      {/* Form */}
                      <div className="mb-4">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={user.name}
                          className="form-control form-control-lg"
                          name="name"
                          id="name"
                          placeholder={t("form.namePlaceholder")}
                          aria-label={t("form.nameAria")}
                          disabled={isCreatingUser}
                          required
                        />
                        <span className="invalid-feedback">Please enter your first name.</span>
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-6">
                      {/* Form */}
                      <div className="mb-4">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={user.surname}
                          className="form-control form-control-lg"
                          placeholder={t("form.surnamePlaceholder")}
                          id="surname"
                          aria-label={t("form.surnameAria")}
                          disabled={isCreatingUser}
                          required
                          name="surname"
                        />
                        <span className="invalid-feedback">Please enter your last name.</span>
                      </div>
                      {/* End Form */}
                    </div>
                  </div>
                  {/* End Form */}
                  {/* Form */}
                  <div className="mb-4">
                    <label className="form-label" htmlFor="signupSrEmail">
                      {t("form.yourEmail")}
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      value={user.email}
                      onChange={handleChange}
                      name="email"
                      id="email"
                      placeholder={t("form.emailPlaceholder")}
                      disabled={isCreatingUser}
                      aria-label={t("form.emailAria")}
                      required
                    />
                    <span className="invalid-feedback">Please enter a valid email address.</span>
                  </div>
                  {/* End Form */}
                  {/* Form */}
                  <div className="mb-4">
                    <label className="form-label" htmlFor="signupSrPassword">
                      {t("form.password")}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        value={user.password}
                        onChange={handleChange}
                        className="js-toggle-password form-control form-control-lg"
                        name="password"
                        id="password"
                        disabled={isCreatingUser}
                        placeholder={t("form.passwordPlaceholder")}
                        aria-label={t("form.passwordAria")}
                        required
                        minLength={8}
                      />
                      <a className="js-toggle-password-target-1 input-group-append input-group-text" href="#">
                        <i className="js-toggle-password-show-icon-1 bi-eye" />
                      </a>
                    </div>
                    <span className="invalid-feedback">Your password is invalid. Please try again.</span>
                  </div>
                  {/* End Form */}
                  {/* Form Check */}
                  <div className="form-check mb-4">
                    <input
                      className="form-check-input"
                      disabled={isCreatingUser}
                      onChange={handleChange}
                      checked={user.terms}
                      name="terms"
                      type="checkbox"
                      id="termsCheckbox"
                      required
                    />
                    <label className="form-check-label" htmlFor="termsCheckbox">
                      {t("form.emailUpdates")}
                    </label>
                  </div>
                  {/* End Form Check */}
                  <div className="d-grid gap-2">
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary btn-lg w-100"
                      disabled={isCreatingUser || subscriptionIsLoading}
                    >
                      {isCreatingUser ? (
                        <>
                          <div className="spinner-border spinner-border-sm align-middle mr-2" role="status"></div>
                          <span className="align-middle">{t("form.creatingAccount")}</span>
                        </>
                      ) : (
                        t("form.createAccount")
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}
        </div>
      </div>
    </div>
  );
}
