import React, { useContext, useEffect, useState } from "react";
import { getCustomerPortalURLWithFlow, getMySubscription } from "../../api/mailerfind/stripe";
import { auth } from "../../lib/firebase/firebase";
import Loading from "../../components/Loading";
import { UserContext } from "../../contexts/UserContext";
import { PRICES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function Upgrade() {
  const { t } = useTranslation("Upgrade.index");
  const { user } = useContext(UserContext) as any;
  const [subscription, setSubscription] = useState(null) as any;
  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const buildStripeReturnUrl = () => {
    return `http://${window.location.hostname}:${window.location.port}/my-account`;
  };

  const getCoupon = () => {
    return new URLSearchParams(window.location.search).get("coupon");
  };

  const getFlowTypeAndPrice = () => {
    const plan = new URLSearchParams(window.location.search).get("plan");
    const period = new URLSearchParams(window.location.search).get("period") || "month";
    if (!plan) return { flowType: "subscription_update" };

    const enviroment = process.env.NODE_ENV as any;
    const price = PRICES[plan]?.[period]?.["eur"]?.[enviroment];
    return {
      flowType: "subscription_update_confirm",
      price,
    };
  };

  const validateSubscription = async () => {
    try {
      // Well, if the user does not have any subscription we will redirect him to the pricing page
      const jwt = await auth.currentUser!.getIdToken();
      const { subscription } = await getMySubscription(jwt);
      setSubscription(subscription);
      setSubscriptionIsLoading(false);
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  };

  const redirectToCustomerPortal = async () => {
    if (!user.firebaseUser) return;

    setLoading(true);
    const stripeReturnUrl = buildStripeReturnUrl();

    try {
      const { flowType, price } = getFlowTypeAndPrice();
      const coupon = getCoupon();
      const jwt = await auth.currentUser!.getIdToken();
      const response = await getCustomerPortalURLWithFlow(stripeReturnUrl, jwt, flowType, price, coupon);

      window.location.href = response?.link || "https://mailerfind.com/pricing";
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.firebaseUser) validateSubscription();
  }, [user.firebaseUser]);

  useEffect(() => {
    if (subscriptionIsLoading) return;

    if (!subscription) {
      window.location.href = "https://mailerfind.com/pricing";
      return;
    }

    if (subscription && subscription.status === "cancelled") {
      window.location.href = "https://mailerfind.com/pricing";
      return;
    }

    // Else, redirect to the customer portal
    redirectToCustomerPortal();
  }, [subscription]);

  return (
    <>
      {loading && <Loading />}
      {errorMessage && (
        <div className="container vh-100">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="alert alert-danger" role="alert">
              {t("errorMessage")}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Upgrade;
