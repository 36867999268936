import Papa from "papaparse";
import { getAllProspectsInAnalysis, getAllProspectsInAnalysisWithFilter } from "../lib/daos/prospectsDAO";

const exportAnalysisToCsv = async (analysisId: any, exportName: any, filter: any) => {
  let pageNumber = 0;
  const pageSize = 10000;

  while (true) {
    let prospects = [];
    if (filter === "all") {
      prospects = (await getAllProspectsInAnalysis(Number(analysisId), pageNumber, pageSize)) as any;
    } else {
      prospects = (await getAllProspectsInAnalysisWithFilter(Number(analysisId), filter, pageNumber, pageSize)) as any;
    }

    if (prospects.length === 0) {
      break;
    }

    prospects = prospects.map((prospect: any) => ({
      ...prospect,
      full_phone_number: prospect.phone_extension ? `+${prospect.phone_extension}${prospect.phone_number}` : "",
    }));

    const csv = Papa.unparse(prospects, {
      header: true,
      delimiter: ",",
      columns: [
        "username",
        "first_name",
        "full_name",
        "email",
        "is_business",
        "biography",
        "profile_pic_url",
        "phone_extension",
        "phone_number",
        "full_phone_number",
        "website",
        "city",
        "followers",
        "following",
      ],
    });
    pageNumber++;

    if (csv !== "") {
      const baseFileName = `Analysis_${analysisId}`;
      await downloadCsv(csv, `${baseFileName}.csv`);
    }
  }
};

const downloadCsv = (csvContent: any, fileName: any) => {
  return new Promise((resolve) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(resolve, 1000);
  });
};

export { exportAnalysisToCsv };
