import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

import { createAnalysis as createLocalAnalysis } from "../lib/daos/analysisDAO";
import { createAnalysis as createCloudAnalysis } from "../lib/firebase/analysis";

import { removeAnalysis as deleteLocalAnalysis } from "../lib/daos/analysisDAO";
import { deleteAnalysis as deleteCloudAnalysis } from "../lib/firebase/analysis";
import { ProjectContext } from "../contexts/ProjectContext";

const useAnalysis = (id: string | null) => {
  const { project } = useContext(ProjectContext) as any;
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext) as any;
  const navigate = useNavigate();

  /**
   * Creates and navigates to a new analysis
   * @returns {Promise<void>}
   */
  const createAnalysis = async () => {
    try {
      setLoading(true);
      let id;
      const name = "New Analysis";
      if (user?.firebaseUser?.analysis?.cloudEnabled === true) {
        const version = user?.firebaseUser?.preferences?.earlyRelease === true ? 3 : 2;
        id = await createCloudAnalysis(user?.firebaseUser?.uid, name, { project: project?.id || null, version });
      } else {
        id = await createLocalAnalysis(name, user?.firebaseUser?.uid, { project: project?.id });
      }
      navigate("/analysis/" + id);
    } catch (err: any) {
      toast.error(err.message || "Error creating analysis");
    } finally {
      setLoading(false);
    }
  };

  const deleteAnalysis = async (analysis: any) => {
    try {
      setLoading(true);
      if (analysis.isCloud) await deleteCloudAnalysis(user?.firebaseUser?.uid, analysis.id);
      else await deleteLocalAnalysis(analysis.id);
    } catch (err: any) {
      toast.error(err.message || "Error deleting analysis");
    } finally {
      setLoading(false);
    }
  };

  return {
    createAnalysis,
    deleteAnalysis,
    loading,
  };
};

// export it
export default useAnalysis;
