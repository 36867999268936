import React, { useContext, useEffect, useState } from "react";
import { auth } from "../../lib/firebase/firebase";
import toast from "react-hot-toast";
import Select from "react-select";
import { getAllAnalyses as getAllLocalAnalyses } from "../../lib/daos/analysisDAO";
import { getAllAnalysis as getAllCloudAnalyses } from "../../lib/firebase/analysis";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    },
  }),
};

function AnalysisSelect({ onChange, disabled, value }: any) {
  const { t } = useTranslation("NewEmail.AnalysisSelect");
  const [user] = useState(auth.currentUser) as any;
  const { project } = useContext(ProjectContext) as any;
  const [analysis, setAnalysis] = useState([]) as any;
  const [loading, setLoading] = useState(false);

  const fetchAllAnalyses = async () => {
    try {
      setLoading(true);
      const localAnalyses = await getAllLocalAnalyses(0, 500, user?.uid, null, project?.id || null);
      const cloudAnalyses = await getAllCloudAnalyses(user?.uid, null, project?.id);
      cloudAnalyses.forEach((ca: any) => (ca.createdAt = ca.createdAt.toDate()));
      const analyses = [...localAnalyses, ...cloudAnalyses];
      analyses.sort((a: any, b: any) => b.createdAt - a.createdAt);

      const formattedAnalyses = [] as any;
      analyses.forEach((doc: any) => {
        formattedAnalyses.push({ value: doc.id, label: `${doc.name} (${doc.emailsFoundCount} ${t("emails")})` });
      });

      setAnalysis(formattedAnalyses);
    } catch (err: any) {
      toast.error(err.message || t("fetch_error"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllAnalyses();
  }, []);

  const handleChange = (selectedOptions: any) => {
    onChange(selectedOptions);
  };

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isLoading={loading}
      isSearchable={true}
      onChange={handleChange}
      isDisabled={disabled}
      name="analysis"
      options={analysis}
      value={analysis.map((a: any) => a.value === value && a)}
      styles={customStyles}
    />
  );
}

export default AnalysisSelect;