import React from 'react';
import { useTranslation } from 'react-i18next';

function Loading() {
   const { t } = useTranslation('Upgrade.index');
   return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
         <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">{t('loadingMessage')}</span>
         </div>
      </div>
   );
}

export default Loading;
