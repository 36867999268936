import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "./firebase";

export const getIdToken = async () => {
  return await auth.currentUser!.getIdToken();
};

export const updateUser = async (userId: string, data: any) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, data);
};

export const getUser = async (userId: string) => {
  const userRef = doc(db, "users", userId);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) {
    return null;
  } else {
    return docSnap.data();
  }
};
