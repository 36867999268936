import React, { useState } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { changeContentTone, generateEmailBody, improveEmailBody, translateEmailBody } from "../../api/mailerfind/email";
import { auth } from "../../lib/firebase/firebase";
import { InfoCircleFill, Magic } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { EMAIL_AI_OPTIONS } from "../../utils/constants";
import { useTranslation } from "react-i18next";

function GenerateEmailModal({ show, handleClose, onResponse, body, mode }: any) {
  const { t } = useTranslation("NewEmail.GenerateEmailModal");
  const [loading, setLoading] = useState(false);

  const [generateEmailContent, setGenerateEmailContent] = useState({
    instructions: "",
    cta: "reply",
    targetURL: "",
    contentExplanation: "",
    language: "en",
  });

  const [improveWriting, setImproveWriting] = useState({
    mode: "grammar",
  });

  const [changeTone, setChangeTone] = useState({
    mode: "formal",
  });

  const [translate, setTranslate] = useState({
    language: "en",
  });

  const handleGenerate = async () => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const response = await generateEmailBody(generateEmailContent, jwt);
      setGenerateEmailContent({ ...generateEmailContent, instructions: "", cta: "reply" });
      onResponse(response.body);
      handleClose();
    } catch (error: any) {
      console.error("Error generating email body:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImprove = async () => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const content = body;
      const mode = improveWriting.mode;
      const response = await improveEmailBody(content, mode, jwt);
      setImproveWriting({ mode: "grammar" });
      onResponse(response.body);
      handleClose();
    } catch (error: any) {
      console.error("Error generating email body:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTone = async () => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const content = body;
      const mode = changeTone.mode;
      const response = await changeContentTone(content, mode, jwt);
      setChangeTone({ mode: "formal" });
      onResponse(response.body);
      handleClose();
    } catch (error: any) {
      console.error("Error generating email body:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTranslate = async () => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const content = body;
      const language = translate.language;
      const response = await translateEmailBody(content, language, jwt);
      setTranslate({ language: "en" });
      onResponse(response.body);
      handleClose();
    } catch (error: any) {
      console.error("Error generating email body:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    switch (mode) {
      case EMAIL_AI_OPTIONS.WRITE:
        handleGenerate();
        break;
      case EMAIL_AI_OPTIONS.IMPROVE:
        handleImprove();
        break;
      case EMAIL_AI_OPTIONS.TONE:
        handleChangeTone();
        break;
      case EMAIL_AI_OPTIONS.TRANSLATE:
        handleTranslate();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {t("email_ai")} <Magic />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mode === EMAIL_AI_OPTIONS.WRITE && (
            <GenerateForm generateEmailContent={generateEmailContent} setGenerateEmailContent={setGenerateEmailContent} />
          )}
          {mode === EMAIL_AI_OPTIONS.IMPROVE && <ImproveForm improveWriting={improveWriting} setImproveWriting={setImproveWriting} />}
          {mode === EMAIL_AI_OPTIONS.TONE && <ChangeToneForm changeTone={changeTone} setChangeTone={setChangeTone} />}
          {mode === EMAIL_AI_OPTIONS.TRANSLATE && <TranslateForm translate={translate} setTranslate={setTranslate} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" onClick={handleClose} disabled={loading}>
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
              <>
                <span className="align-middle mr-2">{t("generate")}</span>
                <Magic />
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function GenerateForm({ generateEmailContent, setGenerateEmailContent }: any) {
  const { t } = useTranslation("NewEmail.GenerateEmailModal");
  const instructionsTooltip = (
    <Tooltip id="tooltip">
      <div>{t("instructions_tooltip_line1")}</div>
      <div>{t("instructions_tooltip_line2")}</div>
      <div>{t("instructions_tooltip_line3")}</div>
    </Tooltip>
  );

  return (
    <>
      <label>
        {t("main_goal")}{" "}
        <OverlayTrigger placement="left" overlay={instructionsTooltip}>
          <InfoCircleFill size="10" />
        </OverlayTrigger>
      </label>
      <textarea
        className="form-control"
        placeholder={t("instructions_placeholder")}
        rows={3}
        maxLength={255}
        value={generateEmailContent.instructions}
        onChange={(e) => setGenerateEmailContent({ ...generateEmailContent, instructions: e.target.value })}
      ></textarea>
      {generateEmailContent.instructions.length > 0 && (
        <div className="text-right">
          <small>{generateEmailContent.instructions.length}/255</small>
        </div>
      )}
      <label className="mt-3">{t("cta")}</label>
      <select
        className="form-control"
        value={generateEmailContent.cta}
        onChange={(e) => setGenerateEmailContent({ ...generateEmailContent, cta: e.target.value })}
      >
        <option value="reply">{t("reply")}</option>
        <option value="meeting">{t("meeting")}</option>
        <option value="content">{t("content")}</option>
        <option value="rrss">{t("visit_rrss")}</option>
      </select>

      {generateEmailContent.cta === "content" && (
        <>
          <label className="mt-3">{t("content_explanation")}</label>
          <input
            className="form-control"
            placeholder={t("content_explanation_placeholder")}
            type="text"
            value={generateEmailContent.contentExplanation}
            onChange={(e) => setGenerateEmailContent({ ...generateEmailContent, contentExplanation: e.target.value })}
          ></input>
        </>
      )}

      {generateEmailContent.cta !== "reply" && (
        <>
          <label className="mt-3">{t("target_url")}</label>
          <input
            className="form-control"
            placeholder="https://example.com"
            type="url"
            onChange={(e) => setGenerateEmailContent({ ...generateEmailContent, targetURL: e.target.value })}
            value={generateEmailContent.targetURL}
          ></input>
        </>
      )}

      <label className="mt-3">{t("language")}</label>
      <select
        className="form-control"
        value={generateEmailContent.language}
        onChange={(e) => setGenerateEmailContent({ ...generateEmailContent, language: e.target.value })}
      >
        <option value="en">English</option>
        <option value="es">Spanish</option>
      </select>
    </>
  );
}

function ImproveForm({ improveWriting, setImproveWriting }: any) {
  const { t } = useTranslation("NewEmail.GenerateEmailModal");
  return (
    <>
      <label>{t("improve_email")}</label>
      <select
        className="form-control"
        value={improveWriting.mode}
        onChange={(e) => setImproveWriting({ ...improveWriting, mode: e.target.value })}
      >
        <option value="grammar">{t("fix_grammar")}</option>
        <option value="shorter">{t("make_shorter")}</option>
        <option value="longer">{t("make_longer")}</option>
        <option value="summarize">{t("summarize")}</option>
      </select>
    </>
  );
}

function ChangeToneForm({ changeTone, setChangeTone }: any) {
  const { t } = useTranslation("NewEmail.GenerateEmailModal");
  return (
    <>
      <label>{t("select_tone")}</label>
      <select className="form-control" value={changeTone.mode} onChange={(e) => setChangeTone({ ...changeTone, mode: e.target.value })}>
        <option value="formal">{t("make_formal")}</option>
        <option value="informal">{t("make_informal")}</option>
        <option value="personable">{t("make_personable")}</option>
        <option value="persuasive">{t("make_persuasive")}</option>
      </select>
    </>
  );
}

function TranslateForm({ translate, setTranslate }: any) {
  const { t } = useTranslation("NewEmail.GenerateEmailModal");
  return (
    <>
      <label>{t("translate_to")}</label>
      <select
        className="form-control"
        value={translate.language}
        onChange={(e) => setTranslate({ ...translate, language: e.target.value })}
      >
        <option value="en">English</option>
        <option value="zh">{t("chinese")}</option>
        <option value="hi">{t("hindi")}</option>
        <option value="es">{t("spanish")}</option>
        <option value="fr">{t("french")}</option>
        <option value="ar">{t("arabic")}</option>
        <option value="bn">{t("bengali")}</option>
        <option value="ru">{t("russian")}</option>
        <option value="pt">{t("portuguese")}</option>
        <option value="id">{t("indonesian")}</option>
      </select>
    </>
  );
}

export default GenerateEmailModal;