import React from "react";
import { Modal } from "react-bootstrap";
import { PlayFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function ImageWithOverlay({ Cta, VideoEmbed }: any) {
  const { t } = useTranslation("Product.ImageWithOverlay");
  const [show, setShow] = React.useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="card card-body">
        <div className="position-relative">
          <div className="img-dark-overlay">
            <img src="/assets/img/store/speed-addon.jpg" alt="Speed addon preview" className="img-fluid rounded img-darken" />
          </div>
          <span className="video-player-icon position-absolute shadow-sm" onClick={handleShow}>
            <PlayFill />
          </span>
        </div>
        {Cta}
      </div>
      <Modal show={show} onHide={() => setShow(false)} backdrop={true} size="lg" centered>
        <Modal.Body className="text-center p-0 m-0 rounded">
          <div style={{ position: "relative", paddingBottom: "54.29864253393666%", height: 0 }}>
            <iframe
              src="https://www.loom.com/embed/23069415a8ef491d93e536239baaa0ed?sid=9c51b580-77de-4152-b12c-e3b742673945"
              title={t("videoTitle")}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: 0,
                borderRadius: "0.8rem",
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageWithOverlay;