import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

interface Users {
  usernames: string[];
  pks: String[];
}

export const getUsersInfo = async (users: Users, analysisId: string, jwt: string): Promise<any> => {
  const response = await makeRequest({
    url: `${BACKEND_URL}/v1/mailerfind/get-user-details`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: {
      users: users,
      analysisId: analysisId,
    },
  });

  return response;
};

export const getUserInfo = async (username: string, jwt: string): Promise<any> => {
  const response = (await makeRequest({
    url: `${BACKEND_URL}/v1/mailerfind/get-user-info`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: {
      username: username,
    },
  })) as any;
  return response.user;
};

export const getCurrentPeriodCreditsUsed = async (jwt: string): Promise<any> => {
  const response = await makeRequest({
    url: `${BACKEND_URL}/v1/mailerfind/get-current-period-used-credits`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });

  return response;
};

export const getInstagramPicture = async (pictureUrl: string, jwt: string): Promise<Blob> => {
  try {
    const url = `${BACKEND_URL}/v1/mailerfind/get-instagram-picture?pictureUrl=${encodeURIComponent(pictureUrl)}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    const res = await fetch(url, options);

    return res.blob();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUserFollowers = async (userId: string, endCursor: string | null, isVerified: boolean, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-user-followers`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        userId,
        isVerified,
        amount: 46,
        endCursor,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUserFollowing = async (userId: string, endCursor: string | null, isVerified: boolean, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-user-following`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        userId,
        isVerified,
        amount: 46,
        endCursor,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const createMauticContact = async (accessToken: string) => {
  const url = `${BACKEND_URL}/v1/users/create-mautic-contact`;
  const method = "POST";
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await makeRequest({ url, method, headers });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const generateLicense = async (accessToken: string) => {
  const url = `${BACKEND_URL}/v1/users/generate-license`;
  const method = "POST";
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await makeRequest({ url, method, headers });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const completeReview = async (content: string, rating: number, accessToken: string) => {
  const url = `${BACKEND_URL}/v1/users/complete-review`;
  const method = "POST";
  const body = {
    content: content,
    rating: rating,
  };
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  return await makeRequest({ url, method, body, headers });
};

export const impersonate = async (userEmail: string, accessToken: string) => {
  const url = `${BACKEND_URL}/v1/users/impersonate`;
  const method = "POST";
  const body = {
    userEmail: userEmail,
  };
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  return await makeRequest({ url, method, body, headers });
};

export const sendPasswordResetEmail = async (email: string) => {
  const url = `${BACKEND_URL}/v1/users/send-password-reset-email`;
  const method = "POST";
  const body = {
    email: email,
  };
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await makeRequest({ url, method, body, headers });
    return response;
  } catch (error) {
    console.error(error);
  }
};
