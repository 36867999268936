import { doc, setDoc } from "firebase/firestore";
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import { ArrowRight } from "react-bootstrap-icons";
import { db } from "../lib/firebase/firebase";
import { UserContext } from "../contexts/UserContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

function Survey(props: any) {
  const { t } = useTranslation("Component.Survey");
  const { user, setUser } = useContext(UserContext) as any;
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    companySize: "",
    businessType: "",
    yourOccupation: "",
    mainGoal: "",
    phoneNumber: "",
    whereDidYouHearAboutUs: localStorage.getItem("source") || "",
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const saveSurveyInFirestore = async () => {
    const userRef = doc(db, "users", user.firebaseUser.uid);
    await setDoc(doc(db, "surveys", user.firebaseUser.uid), {
      companySize: formData.companySize,
      whereDidYouHearAboutUs: formData.whereDidYouHearAboutUs,
      businessType: formData.businessType,
      yourOccupation: formData.yourOccupation,
      mainGoal: formData.mainGoal,
      phoneNumber: formData.phoneNumber,
      userRef: userRef,
      submitedAt: new Date(),
    });
  };

  const updateUserSurveyFlag = async () => {
    await setUser({ hasSubmittedSurvey: true });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.companySize) toast.error(t("errors.companySize"));
    else if (!formData.whereDidYouHearAboutUs) toast.error(t("errors.whereDidYouHearAboutUs"));
    else if (!formData.businessType) toast.error(t("errors.businessType"));
    else if (!formData.yourOccupation) toast.error(t("errors.yourOccupation"));
    else if (!formData.mainGoal) toast.error(t("errors.mainGoal"));
    else {
      try {
        await updateUserSurveyFlag();
        await saveSurveyInFirestore();
        toast.success(t("success"));
        localStorage.removeItem("source");
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setShow(false);
      }
    }
    setLoading(false);
  };

  const handleNextStep = () => {
    if (!formData.businessType) toast.error(t("errors.businessType"));
    else if (!formData.companySize) toast.error(t("errors.companySize"));
    else if (!formData.yourOccupation) toast.error(t("errors.yourOccupation"));
    else setStep(step + 1);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Body className="p-0">
            <div className="row mr-0">
              <div className="col-lg-6 col-0 d-none d-lg-block p-0">
                <img
                  src="/assets/img/others/survey.jpg"
                  className="img-fluid"
                  alt="Survey"
                  style={{
                    borderTopLeftRadius: "0.75rem",
                    borderBottomLeftRadius: "0.75rem",
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 px-6 py-6 py-lg-0 my-auto">
                {step === 1 && (
                  <>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="businessType">
                        {t("labels.businessType")}
                      </label>
                      <select
                        id="businessType"
                        className="form-control"
                        name="businessType"
                        disabled={loading}
                        value={formData.businessType}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          {t("placeholders.chooseOption")}
                        </option>
                        <option value="agency">{t("options.agency")}</option>
                        <option value="ecommerce">{t("options.ecommerce")}</option>
                        <option value="saas">{t("options.saas")}</option>
                        <option value="it_outsource">{t("options.itOutsource")}</option>
                        <option value="content_creator">{t("options.contentCreator")}</option>
                        <option value="consultor">{t("options.consultor")}</option>
                        <option value="infoproduct">{t("options.infoproduct")}</option>
                        <option value="other">{t("options.other")}</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="companySize">
                        {t("labels.companySize")}
                      </label>
                      <select
                        id="companySize"
                        className="form-control"
                        name="companySize"
                        disabled={loading}
                        value={formData.companySize}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          {t("placeholders.chooseOption")}
                        </option>
                        <option value="1-10">1-10</option>
                        <option value="11-50">11-50</option>
                        <option value="51-150">51-150</option>
                        <option value="151-500">151-500</option>
                        <option value="501+">501+</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="yourOccupation">
                        {t("labels.yourOccupation")}
                      </label>
                      <select
                        id="yourOccupation"
                        className="form-control"
                        name="yourOccupation"
                        disabled={loading}
                        value={formData.yourOccupation}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          {t("placeholders.chooseOption")}
                        </option>
                        <option value="founder">{t("options.founder")}</option>
                        <option value="marketing_manager">{t("options.marketingManager")}</option>
                        <option value="sales_manager">{t("options.salesManager")}</option>
                        <option value="other_department_manager">{t("options.otherDepartmentManager")}</option>
                        <option value="consultant_freelancer">{t("options.consultantFreelancer")}</option>
                        <option value="seo_specialist">{t("options.seoSpecialist")}</option>
                        <option value="other">{t("options.other")}</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="phone">
                        {t("labels.phoneNumber")}
                      </label>
                      <PhoneInput
                        country={"es"}
                        preferredCountries={["es", "cl", "us", "ae", "co", "mx", "ar"]}
                        inputClass="w-100 form-control-custom"
                        buttonClass="px-2"
                        value={formData.phoneNumber}
                        onChange={(phone) => setFormData({ ...formData, phoneNumber: phone })}
                      />
                    </div>
                    <Button variant="primary" onClick={handleNextStep} className="w-100 mt-2" disabled={loading}>
                      {t("buttons.next")} <ArrowRight className="ms-1 align-middle" />
                    </Button>
                  </>
                )}
                {step === 2 && (
                  <>
                    <label className="form-label" htmlFor="whereDidYouHearAboutUs">
                      {t("labels.whereDidYouHearAboutUs")}
                    </label>
                    <div className="dropdown">
                      <button
                        className="btn btn-white dropdown-toggle w-100 text-dark mb-3 px-4"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {formData.whereDidYouHearAboutUs ? (
                          <>
                            {formData.whereDidYouHearAboutUs === "email" ? t("options.email") : ""}
                            {formData.whereDidYouHearAboutUs === "tiktok" ? t("options.tiktok") : ""}
                            {formData.whereDidYouHearAboutUs === "google" ? t("options.google") : ""}
                            {formData.whereDidYouHearAboutUs === "friend" ? t("options.friend") : ""}
                            {formData.whereDidYouHearAboutUs === "linkedin" ? t("options.linkedin") : ""}
                            {formData.whereDidYouHearAboutUs === "fbads" ? t("options.fbads") : ""}
                            {formData.whereDidYouHearAboutUs === "other" ? t("options.other") : ""}
                          </>
                        ) : (
                          t("placeholders.chooseOption")
                        )}
                      </button>
                      <div className="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            handleChange({
                              target: {
                                value: "email",
                                name: "whereDidYouHearAboutUs",
                              },
                            })
                          }
                        >
                          <img
                            src="/assets/img/logos/gmail.png"
                            className="avatar avatar-xs mr-2 py-1"
                            style={{ borderRadius: 0, height: "auto" }}
                            alt="Gmail"
                          />
                          Email
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            handleChange({
                              target: {
                                value: "tiktok",
                                name: "whereDidYouHearAboutUs",
                              },
                            })
                          }
                        >
                          <img src="/assets/svg/logos/tiktok.svg" className="avatar avatar-xs mr-2" alt="Gmail" />
                          TikTok
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            handleChange({
                              target: {
                                value: "google",
                                name: "whereDidYouHearAboutUs",
                              },
                            })
                          }
                        >
                          <img src="/assets/img/logos/google.png" className="avatar avatar-xs mr-2" alt="Gmail" />
                          Google
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            handleChange({
                              target: {
                                value: "friend",
                                name: "whereDidYouHearAboutUs",
                              },
                            })
                          }
                        >
                          <img src="/assets/img/logos/friend.png" className="avatar avatar-xs mr-2" alt="Gmail" />A friend or colleague
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            handleChange({
                              target: {
                                value: "linkedin",
                                name: "whereDidYouHearAboutUs",
                              },
                            })
                          }
                        >
                          <img src="/assets/img/logos/linkedin.png" className="avatar avatar-xs mr-2" alt="Gmail" />
                          LinkedIn
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            handleChange({
                              target: {
                                value: "fbads",
                                name: "whereDidYouHearAboutUs",
                              },
                            })
                          }
                        >
                          <img src="/assets/img/logos/instagram.png" className="avatar avatar-xs mr-2" alt="Gmail" />
                          Instagram Ad
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            handleChange({
                              target: {
                                value: "other",
                                name: "whereDidYouHearAboutUs",
                              },
                            })
                          }
                        >
                          <img src="/assets/svg/logos/other.svg" className="avatar avatar-xs mr-2" alt="Gmail" />
                          Other
                        </span>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label" htmlFor="mainGoal">
                        {t("labels.mainGoal")}
                      </label>
                      <select
                        id="mainGoal"
                        className="form-control"
                        disabled={loading}
                        name="mainGoal"
                        value={formData.mainGoal}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          {t("placeholders.chooseOption")}
                        </option>
                        <option value="email">{t("options.emailCampaigns")}</option>
                        <option value="facebook_ads">{t("options.facebookAds")}</option>
                        <option value="google_ads">{t("options.googleAds")}</option>
                        <option value="sms">{t("options.smsCampaigns")}</option>
                        <option value="whatsapp">{t("options.whatsappCampaigns")}</option>
                        <option value="third_party">{t("options.thirdPartyServices")}</option>
                        <option value="research">{t("options.research")}</option>
                        <option value="other">{t("options.other")}</option>
                      </select>
                    </div>
                    <Button variant="primary" type="submit" id="submit" className="w-100 mt-2" disabled={loading}>
                      {t("buttons.goToAccount")} <ArrowRight className="ms-1 align-middle" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
}

export default Survey;
