import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ProductType {
  id: number;
  slug: string;
  nameKey: string;
  descriptionKey: string;
  price: number;
  image: string;
  published: boolean;
}

interface ProductProps {
  product: ProductType;
}

function Product({ product }: ProductProps) {
  const { t } = useTranslation("Store");

  return (
    <Link to={`/product/${product.slug}`}>
      <div className="card card-bordered card-transition shadow-none h-100">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-shrink-0 my-auto">
              <img className="avatar avatar-sm mr-3" src={product.image} alt="Logo" />
            </div>
            <div className="flex-grow-1 ms-3">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 mr-2">{t(product.nameKey)}</h5>
                <img
                  className="avatar avatar-xss ms-1"
                  src="/assets/svg/illustrations/top-vendor.svg"
                  alt="Top vendor"
                  title="Top Vendor"
                />
              </div>
              <p className="card-text text-body small">{t("productivity")}</p>
            </div>
          </div>
          <p className="mt-3 card-text text-body small">{t(product.descriptionKey)}</p>
        </div>
      </div>
    </Link>
  );
}

export default Product;
