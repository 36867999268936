import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function DeleteConfirmationModal({ show, close, onConfirm, data, text, typeToDelete }: any) {
  const { t } = useTranslation("Component.DeleteConfirmationModal");
  const [deleteText, setDeleteText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    setDeleteText(e.target.value);
  };

  const handleConfirm = async () => {
    if (typeToDelete && deleteText !== "DELETE") {
      return toast.error(t("errorTypeDelete"));
    } else {
      setDeleteText("");
      setLoading(true);
      await onConfirm(data);
      setLoading(false);
      close();
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>{t("confirm")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {text}
        {typeToDelete && (
          <div className="mt-3">
            <label className="form-label">{t("typeDelete")}</label>
            <input value={deleteText} onChange={handleChange} className="form-control" placeholder="DELETE" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" onClick={close} disabled={loading}>
          {t("cancel")}
        </Button>
        <Button variant="danger" disabled={loading || (typeToDelete && deleteText !== "DELETE")} onClick={handleConfirm}>
          {t("delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
