import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Error(props: any) {
  const { t } = useTranslation("Error.index");

  return (
    <div>
      <div className="container">
        <Link className="position-absolute top-0 left-0 right-0" to="/">
          <img
            className="avatar-centered img-fluid mt-3"
            src="/assets/img/logos/mailerfind.png"
            alt="Think"
            style={{
              width: "200px",
            }}
          />
        </Link>
        <div className="footer-height-offset d-flex justify-content-center align-items-center flex-column">
          <div className="row align-items-sm-center w-100">
            <div className="col-sm-6">
              <div className="text-center text-sm-right mr-sm-4 mb-5 mb-sm-0">
                <img
                  className="w-60 w-sm-100 mx-auto"
                  src="/assets/svg/illustrations/think.svg"
                  alt="Think"
                  style={{ maxWidth: "15rem" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-4 text-center text-sm-left">
              <h1 className="display-1 mb-0">{t('error')}</h1>
              <p className="lead">{props.message}</p>
              <Link className="btn btn-primary" to="/">
                {t('go_to_home')}
              </Link>
            </div>
          </div>
          {/* End Row */}
        </div>
      </div>
      <div className="footer text-center" style={{ marginLeft: 0 }}>
        <ul className="list-inline list-separator">
          <li className="list-inline-item">
            <a className="list-separator-link" href="#">
              {t('mailerfind_support')}
            </a>
          </li>
          <li className="list-inline-item">
            <a className="list-separator-link" href="#">
              {t('get_help')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Error;