import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { auth, onAuthStateChanged } from "../lib/firebase/firebase";
import { signOut } from "../lib/firebase/auth";

function ImpersonateStrip() {
  const { t } = useTranslation("Component.ImpersonateStrip");
  const [isImpersonated, setIsImpersonated] = useState(false);

  // This function looks for the isImpersonated claim in the user's JWT token
  const checkIfIsImpersonating = async () => {
    const user = await auth.currentUser?.getIdTokenResult();
    return user?.claims.isImpersonated;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) return;
      const isImpersonated = await checkIfIsImpersonating();
      setIsImpersonated(isImpersonated);
    });

    // Limpiar el observador cuando el componente se desmonte
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    await signOut();
  };

  return (
    <>
      {isImpersonated && (
        <div style={{ position: "sticky", top: 0, zIndex: 990, width: "100%" }} className="bg-warning text-white p-2 text-center">
          {t("warning")}: {t("impersonating")}{" "}
          <u onClick={handleLogout} style={{ cursor: "pointer" }}>
            {t("clickHere")}
          </u>
        </div>
      )}
    </>
  );
}

export default ImpersonateStrip;
