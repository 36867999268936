import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Chart(props: any) {
   const data = {
      labels: ['Total', 'Sent', 'Opened', 'Clicked', 'Unsubscribed'],
      datasets: [
         {
            label: 'Stats',
            data: [props.totals, props.sent, props.read, props.clicks, props.unsubscribed],
            backgroundColor: [
               'rgba(255, 99, 132, 0.2)',
               'rgba(54, 162, 235, 0.2)',
               'rgba(255, 206, 86, 0.2)',
               'rgba(75, 192, 192, 0.2)',
               'rgba(128, 0, 128, 0.2)',
            ],
            borderColor: [
               'rgba(255, 99, 132, 1)',
               'rgba(54, 162, 235, 1)',
               'rgba(255, 206, 86, 1)',
               'rgba(75, 192, 192, 1)',
               'rgba(128, 0, 128, 1)',
            ],

            borderWidth: 1,
         },
      ],
   };

   const options = {
      scales: {
         y: {
            beginAtZero: true,
         },
      },
   };

   return <Bar data={data} options={options} />;
}

export default Chart;
