import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Breadcumb from "../../components/Breadcumb";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../lib/firebase/firebase";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import SendExample from "./SendExample";
import GenerateEmailModal from "./GenerateEmailModal";
import { createEmail, getEmail, updateEmail } from "../../lib/firebase/emails";
import Header from "../../components/Header";
import TemplatesModal from "./TemplatesModal";
import { UserContext } from "../../contexts/UserContext";
import PremiumModal from "../../components/PremiumModal";
import { Email } from "../../types/EmailTypes";
import EmailCreator from "./EmailCreator";
import { STATUS } from "../../utils/constants";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useTranslation } from "react-i18next";

function NewEmail() {
  const { id } = useParams() as any;
  const { t } = useTranslation("NewEmail.index");
  const [loading, setLoading] = useState(true);
  const [authUser] = useState(auth.currentUser) as any;
  const [saved, setSaved] = useState(true);
  const [show, setShow] = useState(false);
  const [emailAiMode, setEmailAiMode] = useState("" as any);
  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const [email, setEmail] = useState<Email>({
    id: null,
    version: 2,
    title: "",
    subject: "",
    body: "",
    analysis: null,
    createdAt: new Date(),
    sent: 0,
    opens: 0,
    clicks: 0,
    senders: null,
    followUp: {
      isAFollowUpEmail: false,
      followUpMode: null,
      email: null,
    },
    status: STATUS.NOT_STARTED,
    project: project?.id || null,
  });

  const navigate = useNavigate();

  const fetchEmail = async () => {
    try {
      const email = await getEmail(authUser.uid, id);
      if (!email) throw new Error(t("email_not_found"));
      setEmail(email);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return setLoading(false);
    fetchEmail();
  }, [id]);

  const handleSubmit = async (e: any) => {
    e && e.preventDefault();
    if (!email.subject) return toast.error(t("subject_required"));
    if (!email.title) return toast.error(t("title_required"));
    if (!email.senders || email.senders.length === 0) return toast.error(t("senders_required"));

    if (email?.followUp?.isAFollowUpEmail) {
      if (!email.followUp.email) return toast.error(t("follow_up_email_required"));
      if (!email.followUp.followUpMode) return toast.error(t("follow_up_mode_required"));
    }

    if (!email.analysis) return toast.error(t("analysis_required"));

    if (id) {
      email.id = null;

      // trim the body, the subject and the title
      email.title = email.title.trim();
      email.body = email.body.trim();
      email.subject = email.subject.trim();

      try {
        setLoading(true);
        await updateEmail(authUser.uid, id, email);
        email.id = id; // restore
        toast.success(t("email_updated_successfully"));
        setSaved(true);
      } catch (error: any) {
        if (error.message.includes("INVALID_ARGUMENT")) {
          toast.error(t("high_email_size"));
        } else toast.error(error.message || t("something_went_wrong"));
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        console.log("Creating email", email);
        const id = await createEmail(authUser.uid, email);
        toast.success(t("email_created_successfully"));
        setSaved(true);
        navigate(`/emails/edit/${id}`);
      } catch (error: any) {
        if (error.message.includes("INVALID_ARGUMENT")) {
          toast.error(t("high_email_size"));
        } else toast.error(error.message || t("something_went_wrong"));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        handleSubmit(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [email]);

  const handleOnResponse = (text: any) => {
    // Aquí actualizas el formulario con el texto generado por la IA.
    setEmail({ ...email, body: text });
    setShow(false);
    setShowTemplatesModal(false);
  };

  const openPopUp = () => {
    setShow(true);
  };

  const openEmailAiModal = (mode: string) => {
    if (user.firebaseUser.plan === "free") {
      setShowPremiumModal(true);
      return;
    }
    setEmailAiMode(mode);
    setShowGenerateModal(true);
  };

  return (
    <div>
      <Breadcumb
        items={[
          { title: t("emails"), to: "/emails" },
          { title: t("new_email"), to: "#" },
        ]}
      />
      <Header title={t("edit_email")} subtitle={email.title || t("email_title")}>
        <button className="mr-2 btn btn-primary" onClick={handleSubmit} disabled={saved || loading} id="save-email-btn">
          {t("save_changes")}
        </button>
        <button
          className="mr-2 btn btn-outline-primary"
          onClick={openPopUp}
          disabled={!saved || loading || !id}
          id="send-email-example-btn"
        >
          <FontAwesomeIcon icon={faPaperPlane} /> {t("send_example")}
        </button>
        <Link
          to={`/emails/send/${id}`}
          className={`text-right btn btn-outline-primary ${(!saved || loading || !id) && "disabled"}`}
          id="send-email-btn"
        >
          <FontAwesomeIcon icon={faPaperPlane} /> {t("send")}
        </Link>
      </Header>

      <EmailCreator
        id={id}
        loading={loading}
        handleSubmit={handleSubmit}
        setSaved={setSaved}
        saved={saved}
        email={email}
        setEmail={setEmail}
        openEmailAiModal={openEmailAiModal}
        openTemplatesModal={() => setShowTemplatesModal(true)}
      />

      <SendExample emailData={email} show={show} handleClose={() => setShow(false)} setShow={setShow} sendTo={user.firebaseUser.email} />

      <GenerateEmailModal
        show={showGenerateModal}
        mode={emailAiMode}
        body={email.body}
        handleClose={() => setShowGenerateModal(false)}
        onResponse={handleOnResponse}
        emailId={id}
      />

      <TemplatesModal show={showTemplatesModal} handleClose={() => setShowTemplatesModal(false)} onResponse={handleOnResponse} />
      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} title={t("upgrade_plan")} />
    </div>
  );
}

export default NewEmail;