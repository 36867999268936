import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import Confetti from "react-confetti";
import Billing from "../MyAccount/billing";
import AnalysisLoop from "../AnalysisManager/AnalysisTable";
import Breadcumb from "../../components/Breadcumb";
import Header from "../../components/Header";
import Skeleton from "react-loading-skeleton";
import EarnFreeCredits from "../../components/EarnFreeCredits";
import ReactPixel from "react-facebook-pixel";
import BeforeYouDiveIn from "../../components/BeforeYouDiveIn";
import toast from "react-hot-toast";
import { acceptInvitation } from "../../api/mailerfind/invitations";
import { auth } from "../../lib/firebase/firebase";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const { user } = useContext(UserContext) as any;
  const showConfetti = window.location.search.includes("success=true");
  const emailVerified = window.location.search.includes("verified=true");

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const { t } = useTranslation("Dashboard.index");

  if (showConfetti) {
    ReactPixel.track("CompleteRegistration", {
      content_name: "CompleteRegistration",
      status: true,
    });
  }

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setDimensions({
      width,
      height,
    });
  }, []);

  useEffect(() => {
    async function showEmailVerifiedToast() {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toast.success(t("emailVerified"));
    }
    async function acceptInvitationIfUserWasInvited() {
      if (!user.firebaseUser.invitedBy) return;
      const jwt = await auth.currentUser!.getIdToken();
      await acceptInvitation(user.firebaseUser.invitedBy.id, jwt);
    }
    if (emailVerified) showEmailVerifiedToast();
    if (emailVerified) acceptInvitationIfUserWasInvited();
  }, [emailVerified]);

  const title = user.firebaseUser?.firstname ? t("welcomeBackWithName", { name: user?.firebaseUser?.firstname }) : t("welcomeBack");

  return (
    <div>
      {showConfetti && <Confetti width={dimensions.width} height={dimensions.height} numberOfPieces={500} recycle={false} />}

      <Breadcumb items={[{ title: "Dashboard", to: "/" }]} />
      {user.firebaseUser ? (
        <Header title={title} subtitle={t("whatToDoToday")} />
      ) : (
        <>
          <h1>
            <Skeleton width={250} />
          </h1>
          <p>
            <Skeleton width={350} />
          </p>
        </>
      )}

      <div className="row">
        <div className="col-md-5">
          <div className="mb-4">
            <BeforeYouDiveIn />
          </div>

          {/*user.firebaseUser.plan === "free" && (
            <div className="mb-4">
              <FreeConsultation />
            </div>
          )*/}

          <div className="mb-4">
            <Billing user={user.firebaseUser} simplified={true} />
          </div>
        </div>
        <div className="col-md-7">
          <div className="mb-4">
            <EarnFreeCredits />
          </div>
          <AnalysisLoop />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
