import { BACKEND_URL } from '../../utils/constants';
import makeRequest from '../client';

interface Email {
   emailId: string;
   to: string;
}

export const send = async (emailId: Email, prospect: any, isTest: boolean, jwt: string): Promise<any> => {
   try {
      const response = await makeRequest({
         url: `${BACKEND_URL}/v1/email/send`,
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
         },
         body: {
            emailId: emailId,
            prospect: prospect,
            isTest: isTest,
         },
      });

      return response;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

export const test = async (senderId: any, jwt: string): Promise<any> => {
   try {
      const response = await makeRequest({
         url: `${BACKEND_URL}/v1/email/test`,
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
         },
         body: {
            senderId,
         },
      });

      return response;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

export const generateEmailBody = async (generateEmailContent: object, jwt: string): Promise<any> => {
   try {
      const response = await makeRequest({
         url: `${BACKEND_URL}/v1/email/generate-content`,
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
         },
         body: {
            generateEmailContent,
         },
      });

      return response;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

export const improveEmailBody = async (content: string, mode: string, jwt: string): Promise<any> => {
   try {
      const response = await makeRequest({
         url: `${BACKEND_URL}/v1/email/improve-content`,
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
         },
         body: {
            content,
            mode,
         },
      });

      return response;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

export const changeContentTone = async (content: string, mode: string, jwt: string): Promise<any> => {
   try {
      const response = await makeRequest({
         url: `${BACKEND_URL}/v1/email/change-content-tone`,
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
         },
         body: {
            content,
            mode,
         },
      });

      return response;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

export const translateEmailBody = async (content: string, language: string, jwt: string): Promise<any> => {
   try {
      const response = await makeRequest({
         url: `${BACKEND_URL}/v1/email/translate-content`,
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
         },
         body: {
            content,
            language,
         },
      });

      return response;
   } catch (err) {
      console.error(err);
      throw err;
   }
};
