import { BACKEND_URL } from '../../utils/constants';
import makeRequest from '../client';

export const getLocationMedia = async (
   locationId: string,
   endCursor: string | null,
   nextPage: number,
   jwt: string
): Promise<any> => {
   return await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-location-media`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         locationId,
         endCursor,
         nextPage,
      },
   });
};

export const getLocationInfo = async (locationId: string, jwt: string): Promise<any> => {
   return await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-location-info`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         locationId,
      },
   });
};
