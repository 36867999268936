import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Rocket from "./Rocket";
import { RocketTakeoffFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function SpeedModal({ show, setShow }: any) {
  const { t } = useTranslation("Wizard.SpeedModal");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    if (activated === false) return;
    // Establece un temporizador para cambiar showModal a true después de 5 segundos
    const timer = setTimeout(() => {
      handleClose();
    }, 3000); // 5000 milisegundos = 5 segundos

    // Limpiar el temporizador si el componente se desmonta antes de que se cumpla el tiempo
    return () => clearTimeout(timer);
  }, [activated]);

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="mx-auto">
            {!activated && (
              <>
                <div
                  className="text-center bg-soft-warning mb-2 mx-auto"
                  style={{ fontSize: "2.5rem", borderRadius: 999, width: 100, height: 100, paddingTop: 20 }}
                >
                  ⚡️
                </div>
                {t("title")}
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center pt-2">
          {activated ? (
            <div className="py-6">
              <Rocket />
              <h4 className="mt-5">{t("activating")}</h4>
            </div>
          ) : (
            <>
              <p>
                {t("gift") + " "} <b>{t("gift2")}</b> {" " + t("gift3")}
              </p>
              <Button variant="primary" onClick={() => setActivated(true)} disabled={loading}>
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <span className="align-middle">{t("activateBoost")}</span>
                    <RocketTakeoffFill className="ml-2" />
                  </>
                )}
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SpeedModal;
