import React from "react";
import { Stars } from "react-bootstrap-icons";
import { formatFollowers } from "../../utils/numberFormatter";
import { useTranslation } from "react-i18next";

function Table(props: any) {
  const { t } = useTranslation("ViewEmail.Table");

  return (
    <div className="table-responsive">
      <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle">
        <thead className="thead-light" style={{ position: "sticky", top: 0, zIndex: 90 }}>
          <tr>
            <th>{t("nameAndUsername")}</th>
            <th>
              {t("firstName")} <Stars size="14" className="mb-1" />
            </th>
            <th>{t("email")}</th>
            <th>{t("website")}</th>
            <th>{t("phone")}</th>
            <th>{t("following")}</th>
            <th>{t("followers")}</th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((user: any, index: any) => (
            <Row data={user} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function Row(props: any) {
  return (
    <tr>
      <td>
        <a
          className="media align-items-center"
          href={`https://instagram.com/${props.data.username}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="avatar avatar-soft-dark avatar-circle mr-3">
            <span className="avatar-initials">
              {
                // the first letter of the username in upper case
                props?.data?.username?.charAt(0).toUpperCase()
              }
            </span>
          </div>
          <div className="media-body">
            <span className="d-block h5 text-hover-primary mb-0">
              {props.data.full_name}
            </span>
            <span className="d-block font-size-sm text-body">
              {props.data.username}
            </span>
          </div>
        </a>
      </td>
      <td>{props.data.first_name || "-"}</td>
      <td>{props.data.email ? props.data.email : "-"}</td>
      <td>
        <a href={props.data.website} target="_blank" rel="noreferrer">
          {props.data.website
            ? // show the website but crop it to the first 20 chars. if it's longer than 20 chars, add "..."
              props.data.website.slice(0, 25) +
              (props.data.website.length > 25 ? "..." : "")
            : "-"}
        </a>
      </td>
      <td>{props.data.phone_number ? props.data.phone_number : "-"}</td>
      <td className="">{formatFollowers(props.data.following, 1)}</td>
      <td className="">{formatFollowers(props.data.followers, 1)}</td>
    </tr>
  );
}

export default Table;