import React, { createContext, useState, useEffect } from "react";
import { auth } from "../lib/firebase/firebase";
import { getUser, updateUser as updateFirestoreUser } from "../lib/firebase/user";
export const UserContext = createContext({}) as any;

export const UserProvider = ({ children, initialFirebaseUser }: any) => {
  const [user, setUser] = useState({
    firebaseUser: initialFirebaseUser,
  }) as any;

  const updateUser = async (updates: any) => {
    // Actualizar el usuario en Firebase con solo los cambios
    await updateFirestoreUser(user.firebaseUser.uid, updates);

    // Actualizar el estado local aplicando solo los cambios
    setUser((currentUser: any) => ({
      ...currentUser,
      firebaseUser: { ...currentUser.firebaseUser, ...updates },
    }));
  };

  const forceRefreshUser = async () => {
    const firebaseUser = await getUser(user.firebaseUser.uid);
    setUser({ firebaseUser: { ...auth.currentUser, ...firebaseUser } });
  };

  // Usar useEffect para inicializar el estado
  useEffect(() => {
    setUser({ ...user, firebaseUser: initialFirebaseUser });
  }, [initialFirebaseUser]);

  return <UserContext.Provider value={{ user, setUser: updateUser, forceRefreshUser }}>{children}</UserContext.Provider>;
};
