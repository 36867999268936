import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { auth } from "../../lib/firebase/firebase";
import { getEmails } from "../../lib/firebase/emails";
import toast from "react-hot-toast";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    },
  }),
};

function EmailSelect({ currentEmailId, onChange, value, disabled }: any) {
  const { t } = useTranslation("NewEmail.EmailSelect");
  const [user] = useState(auth.currentUser) as any;
  const { project } = useContext(ProjectContext) as any;
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]) as any;

  const fetchEmails = async () => {
    try {
      setLoading(true);

      const emails = await getEmails(user.uid, null, { project: project?.id });

      // delete from emails array
      const filteredEmails = emails.filter((email: any) => email.id !== currentEmailId);
      setEmails(filteredEmails);

      // convert emails to {name, value} format
      const formattedEmails = [] as any;
      filteredEmails.forEach((doc: any) => {
        formattedEmails.push({ value: doc.id, label: doc.title });
      });

      setEmails(formattedEmails);
    } catch (err: any) {
      toast.error(err.message || t("error_fetching_emails"));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (selectedOptions: any) => {
    onChange(selectedOptions);
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isDisabled={disabled}
      isLoading={loading}
      isSearchable={true}
      onChange={handleChange}
      name="emails"
      value={emails.map((email: any) => email.value === value && email)}
      options={emails}
      styles={customStyles}
    />
  );
}

export default EmailSelect;