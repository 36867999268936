import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SearchBar from "../../components/SearchBar";
import { STATUS } from "../../utils/constants";
import { ArrowLeft } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const AccountSelect = ({ status, onSelect, handleBack, selectedAccount }: any) => {
  const { t } = useTranslation("AccountSelect");

  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="row">
          <div className="col-auto pr-2">
            <button
              type="button"
              className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              aria-label={t("previousCustomer")}
              data-bs-original-title={t("previousCustomer")}
              onClick={handleBack}
              disabled={status !== STATUS.NOT_STARTED}
            >
              <ArrowLeft />
            </button>
          </div>
          <div className="col-auto my-auto p-0">
            <h3 className="card-header-title m-0 p-0">{t("selectAccountTitle")}</h3>
          </div>
        </div>
        <p>{t("selectUserInstruction")}</p>
        <div className="alert alert-soft-primary" role="alert">
          <FontAwesomeIcon icon={faInfoCircle} /> {t("notePublicAccounts")}
        </div>

        <SearchBar onSelect={onSelect} isDisabled={status !== STATUS.NOT_STARTED} selectedAccount={selectedAccount} />
      </div>
    </div>
  );
};

export default AccountSelect;