import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import emails from "../../assets/json/emails.json";
import { ArrowLeftShort, ArrowRightShort, Globe } from "react-bootstrap-icons";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";

function TemplatesModal({ show, handleClose, onResponse }: any) {
  const { t } = useTranslation("NewEmail.TemplatesModal");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null) as any;
  const { user } = useContext(UserContext) as any;
  const [language, setLanguage] = useState(user.firebaseUser?.preferences?.language || "en");

  const categories = Array.from(new Set(emails.flatMap((email) => email.categories)));

  // Filtrar correos por categoría
  const getEmailsByCategory = (category: string) => {
    return emails.filter((email) => email.categories.includes(category));
  };

  const handleSubmit = () => {
    onResponse(content);
  };

  const handleChange = (content: string) => {
    setContent(content);
  };

  const handleSelectCategory = (category: any) => {
    setSelectedCategory(category);
  };

  const handleSelectEmail = (email: any) => {
    setSelectedEmail(email);
    handleChange(email.content[language]);
  };

  const handleBack = () => {
    setSelectedCategory(null);
  };

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value);
    if (!selectedEmail) return;
    handleChange(selectedEmail.content[e.target.value]);
  };

  // Configuración para desactivar la barra de herramientas
  const modules = {
    toolbar: [],
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className="my-auto">{t("templates")}</Modal.Title>
          <div className="form-group mb-0 my-auto">
            <select
              id="templates-language"
              onChange={handleLanguageChange}
              value={language}
              className="form-control btn btn-ghost-white text-muted "
            >
              <option value="en">English</option>
              <option value="es">Spanish</option>
            </select>
          </div>
        </Modal.Header>
        <Modal.Body className="px-6 py-2">
          <div className="row">
            <div className="col-lg-4 mb-lg-none mb-4">
              <label>
                {t("categories")}{" "}
                {selectedCategory && (
                  <>
                    <ArrowRightShort /> {selectedCategory}
                  </>
                )}
              </label>
              <div className="border rounded" style={{ height: 250 + 40, overflowY: "scroll" }}>
                {selectedCategory ? (
                  <EmailList
                    emails={getEmailsByCategory(selectedCategory)}
                    onSelectEmail={handleSelectEmail}
                    onBack={handleBack}
                    selectedEmail={selectedEmail}
                  />
                ) : (
                  <>
                    {categories.map((category, index) => (
                      <div key={index} className="category-item py-2" onClick={() => handleSelectCategory(category)}>
                        {category}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-8">
              <label>{t("preview")}</label>
              <ReactQuill
                value={content}
                onChange={handleChange}
                modules={modules}
                style={{
                  height: "250px",
                  borderRadius: "5px",
                  marginBottom: "40px",
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" onClick={handleClose} disabled={loading}>
            {t("cancel")}
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : t("insert")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const EmailList = ({ emails, onSelectEmail, onBack, selectedEmail }: any) => {
  const { t } = useTranslation("NewEmail.TemplatesModal");
  return (
    <div className="email-list">
      <div className="back-button py-2 text-primary" onClick={onBack}>
        <ArrowLeftShort /> {t("back")}
      </div>
      {emails.map((email: any, index: number) => (
        <div
          key={index}
          className={`email-item py-2 ${selectedEmail?.id === email.id && "bg-soft-primary"}`}
          onClick={() => onSelectEmail(email)}
        >
          {t("email")} {email.id}
        </div>
      ))}
    </div>
  );
};

export default TemplatesModal;
