import React, { useContext, useState } from "react";
import {
  At,
  GlobeEuropeAfrica,
  Lock,
  PauseFill,
  People,
  PersonGear,
  PlayFill,
  Telephone,
  TicketPerforated,
  Unlock,
} from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { auth } from "../../lib/firebase/firebase";
import { sendEmailVerification } from "firebase/auth";
import { MODE, STATUS } from "../../utils/constants";
import Notification from "../../components/Notification";
import ProgressBar from "../../components/ProgressBar";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import PremiumModal from "../../components/PremiumModal";
import { useTranslation } from "react-i18next";

function Progress({
  cardTitle,
  analysis,
  handleStart,
  handlePause,
  handleCancelRetry,
  errorMessage,
  totalUsersEntered,
  focusMode,
  gradient,
}: any) {
  const { t } = useTranslation("Progress");
  const [advancedView, setAdvancedView] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const { user } = useContext(UserContext) as any;
  const navigate = useNavigate();

  const handleAdvancedViewToggle = () => {
    setAdvancedView((prevState) => !prevState);
  };

  const handleResend = async () => {
    const toastId = toast.loading(t("sending"));
    if (!auth.currentUser) {
      throw new Error("User is not authenticated");
    }
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}/?verified=true`,
        handleCodeInApp: true,
      };
      await sendEmailVerification(auth.currentUser, actionCodeSettings);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      toast.success(t("emailSent"), {
        id: toastId,
      });
    } catch (err) {
      toast.error(t("failed"), {
        id: toastId,
      });
    }
  };

  const handleUpgrade = () => {
    if (user.firebaseUser.plan === "free") {
      setShowPremiumModal(true);
      return;
    }

    navigate("/upgrade");
  };

  return (
    <>
      <div className="card h-100 mb-4">
        <div className="card-header">
          <h3 className="card-title">{cardTitle || t("progress")}</h3>
          <div className="d-flex">
            <span className="mr-3 my-auto mb-0">{t("advancedView")}</span>
            <label className="switch my-auto">
              <input type="checkbox" onChange={handleAdvancedViewToggle} checked={advancedView} />
              <span className="slider round" />
            </label>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6 col-lg">
              {/* Stats */}
              <div className="media align-items-center">
                <span className="mr-3">
                  <People size="20" />
                </span>
                <div className="media-body">
                  <span className="d-block font-size-sm">{t("totalUsersEntered")}</span>
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{(totalUsersEntered || 0).toLocaleString("es-ES")}</h3>
                  </div>
                </div>
              </div>
              {/* End Stats */}
              <div className="d-lg-none">
                <hr />
              </div>
            </div>
            <div className="col-sm-6 col-lg column-divider-lg">
              {/* Stats */}
              <div className="media align-items-center">
                <span className="mr-3">
                  <PersonGear size="20" />
                </span>
                <div className="media-body">
                  <span className="d-block font-size-sm">{t("profilesAnalyzed")}</span>
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{(analysis?.prospectsCount || 0).toLocaleString("es-ES")}</h3>
                  </div>
                </div>
              </div>
              {/* End Stats */}
              <div className="d-lg-none">
                <hr />
              </div>
            </div>
            <div className="col-sm-6 col-lg column-divider-lg">
              {/* Stats */}
              <div className="media align-items-center">
                <span className="mr-3">
                  <TicketPerforated size="20" />
                </span>

                <div className="media-body">
                  <span className="d-block font-size-sm">{t("usedCredits")}</span>
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">
                      <span className="badge bg-soft-success text-primary">
                        <span className="legend-indicator bg-primary" />
                        {(analysis?.creditsUsed || 0).toLocaleString("es-ES")} {t("credits")}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              {/* End Stats */}
              <div className="d-sm-none">
                <hr />
              </div>
            </div>
            <div className="col-sm-6 col-lg column-divider-lg">
              {/* Stats */}
              <div className="media align-items-center">
                <span className="mr-3">
                  <At size="20" />
                </span>
                <div className="media-body">
                  <span className="d-block font-size-sm">{t("emailsFound")}</span>
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{(analysis?.emailsFoundCount || 0).toLocaleString("es-ES")}</h3>
                  </div>
                </div>
              </div>
              {/* End Stats */}
            </div>
          </div>
          {advancedView && (
            <>
              <div className="my-4"></div>
              <div className="row">
                <div className="col-sm-6 col-lg">
                  {/* Stats */}
                  <div className="media align-items-center">
                    <span className="mr-3">
                      <Unlock size="20" />
                    </span>
                    <div className="media-body">
                      <span className="d-block font-size-sm">{t("publicAccounts")}</span>
                      <div className="d-flex align-items-center">
                        <h3 className="mb-0">{(analysis?.publicAccountsCount || 0).toLocaleString("es-ES")}</h3>
                      </div>
                    </div>
                  </div>
                  {/* End Stats */}
                  <div className="d-lg-none">
                    <hr />
                  </div>
                </div>
                <div className="col-sm-6 col-lg column-divider-lg">
                  {/* Stats */}
                  <div className="media align-items-center">
                    <span className="mr-3">
                      <Lock size="20" />
                    </span>
                    <div className="media-body">
                      <span className="d-block font-size-sm">{t("privateAccounts")}</span>
                      <div className="d-flex align-items-center">
                        <h3 className="mb-0">{(analysis?.privateAccountsCount || 0).toLocaleString("es-ES")}</h3>
                      </div>
                    </div>
                  </div>
                  {/* End Stats */}
                  <div className="d-lg-none">
                    <hr />
                  </div>
                </div>
                <div className="col-sm-6 col-lg column-divider-lg">
                  {/* Stats */}
                  <div className="media align-items-center">
                    <span className="mr-3">
                      <Telephone size="20" />
                    </span>

                    <div className="media-body">
                      <span className="d-block font-size-sm">{t("phonesFound")}</span>
                      <div className="d-flex align-items-center">
                        <h3 className="mb-0">{(analysis?.phoneNumbersFoundCount || 0).toLocaleString("es-ES")}</h3>
                      </div>
                    </div>
                  </div>
                  {/* End Stats */}
                  <div className="d-sm-none">
                    <hr />
                  </div>
                </div>
                <div className="col-sm-6 col-lg column-divider-lg">
                  {/* Stats */}
                  <div className="media align-items-center">
                    <span className="mr-3">
                      <GlobeEuropeAfrica size="20" />
                    </span>
                    <div className="media-body">
                      <span className="d-block font-size-sm">{t("websitesFound")}</span>
                      <div className="d-flex align-items-center">
                        <h3 className="mb-0">{(analysis?.websitesFoundCount || 0).toLocaleString("es-ES")}</h3>
                      </div>
                    </div>
                  </div>
                  {/* End Stats */}
                </div>
              </div>
            </>
          )}
        </div>

        <hr className="my-0"></hr>
        <div className="card-body pt-0">
          {errorMessage && (
            <div className="alert alert-soft-danger my-3 d-flex justify-content-between" role="alert">
              <div className="my-auto">{errorMessage}</div>
              {errorMessage.includes("Has alcanzado el límite") && (
                <button className="btn btn-white btn-sm border-0 text-danger my-auto" onClick={handleUpgrade}>
                  {t("upgrade")}
                </button>
              )}
              {errorMessage.includes("verify your email") && (
                <button className="btn btn-white btn-sm border-0 text-danger my-auto" onClick={handleResend}>
                  {t("resend")}
                </button>
              )}
            </div>
          )}
          {analysis.status === STATUS.RUNNING && (
            <div className="alert alert-soft-warning mt-3" role="alert">
              <div className="spinner-border spinner-border-sm mr-3 align-middle" role="status">
                <span className="sr-only">{t("loading")}</span>
              </div>
              {analysis.version <= 2 && <span className="m-0 p-0 align-middle">{t("processRunning")}</span>}
              {analysis.version >= 3 && (
                <span className="m-0 p-0 align-middle">We are working on it. You can close this window if you want.</span>
              )}
            </div>
          )}
          {analysis.status === STATUS.COMPLETED && (
            <div className="mt-3">
              <Notification message={t("processFinished")} type="success" />
            </div>
          )}

          {analysis.mode !== MODE.HASHTAG && analysis.mode !== MODE.LOCATION && (
            <ProgressBar gradient={gradient} progress={((analysis.prospectsCount || 0) / totalUsersEntered) * 100} />
          )}

          <div className="mt-3 text-right">
            {analysis.status === STATUS.NOT_STARTED && (
              <button
                className={`btn btn-primary mr-2 ${focusMode && "micro"}`}
                id="start-analysis"
                onClick={handleStart}
                disabled={analysis.selectedItem === null}
              >
                <span className="mr-1">
                  <PlayFill size={20} />
                </span>
                <span className="align-middle">{t("start")}</span>
              </button>
            )}

            {(analysis.status === STATUS.PAUSED || analysis.status === STATUS.ERROR) && (
              <button className="btn btn-primary mr-2" onClick={handleStart} id="resume-analysis" disabled={false}>
                <span className="mr-1">
                  <PlayFill size={20} />
                </span>
                <span className="align-middle">{t("resume")}</span>
              </button>
            )}

            {analysis.status === STATUS.STARTING && (
              <button className="btn btn-primary mr-2 align-middle" onClick={handleStart} disabled={true} id="analysis-starting">
                <span className="mr-2">
                  <div className="spinner-border spinner-border-sm align-middle" role="status">
                    <span className="sr-only">{t("starting")}</span>
                  </div>
                </span>
                <span className="align-middle">{t("starting")}</span>
              </button>
            )}

            {analysis.status === STATUS.RETRYING && (
              <>
                <button className="btn btn-outline-primary mr-2" onClick={handleCancelRetry} id="cancel-retry">
                  {t("cancel")}
                </button>
                <button className="btn btn-primary mr-2 align-middle" onClick={handleStart} disabled={true}>
                  <span className="mr-2">
                    <div className="spinner-border spinner-border-sm align-middle" role="status">
                      <span className="sr-only">{t("retrying")}</span>
                    </div>
                  </span>
                  <span>{t("retrying")}</span>
                </button>
              </>
            )}

            {analysis.status === STATUS.PAUSING && (
              <button className="btn btn-primary mr-2" onClick={handleStart} disabled={true} id="analysis-pausing">
                <span className="mr-2">
                  <div className="spinner-border spinner-border-sm align-middle" role="status">
                    <span className="sr-only">{t("loading")}</span>
                  </div>
                </span>
                <span className="m-0 p-0 align-middle">{t("pausing")}</span>
              </button>
            )}

            {analysis.status === STATUS.RUNNING && (
              <button className="btn btn-outline-primary mr-2" onClick={handlePause} id="pause-analysis" disabled={false}>
                <span className="mr-1">
                  <PauseFill size={20} />
                </span>
                <span className="align-middle">{t("pause")}</span>
              </button>
            )}

            {analysis.status === STATUS.COMPLETED && (
              <button className="btn btn-primary mr-2" disabled={true}>
                <span className="mr-2">
                  <PlayFill size={20} />
                </span>
                <span>{t("start")}</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} title={t("updatePlanTitle")} />
    </>
  );
}

export default Progress;
