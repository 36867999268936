import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserContext";
import Skeleton from "react-loading-skeleton";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../lib/firebase/firebase";
import toast from "react-hot-toast";

function EmailVerify() {
  const { user } = useContext(UserContext) as any;
  const navigate = useNavigate();
  const { t } = useTranslation("EmailVerify.index");

  useEffect(() => {
    if (auth?.currentUser?.emailVerified === true) {
      navigate("/disclaimer");
    }
  }, [auth?.currentUser?.emailVerified]);

  const handleResend = async () => {
    const toastId = toast.loading(t("sendingToast"));
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}/disclaimer`,
        handleCodeInApp: true,
      };
      await sendEmailVerification(auth.currentUser || user, actionCodeSettings);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      toast.success(t("emailSentToast"), {
        id: toastId,
      });
    } catch (err) {
      toast.error(t("emailFailedToast"), {
        id: toastId,
      });
    }
  };

  return (
    <div>
      <div>
        <div className="container-fluid px-3">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0">
              <header className="py-5 mx-3">
                <div className="d-flex d-lg-none justify-content-between">
                  <Link to="/">
                    <img
                      className="w-100"
                      src="/assets/img/logos/mailerfind.png"
                      alt="Logo"
                      data-hs-theme-appearance="default"
                      style={{ minWidth: "7rem", maxWidth: "7rem" }}
                    />
                  </Link>
                </div>
              </header>
              <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3" style={{ position: "absolute", top: "0", left: "0" }}>
                <div className="d-none d-lg-flex justify-content-between">
                  <Link to="/">
                    <img
                      className="w-100"
                      src="/assets/img/logos/mailerfind.png"
                      alt="Logo"
                      data-hs-theme-appearance="default"
                      style={{ minWidth: "7rem", maxWidth: "7rem" }}
                    />
                  </Link>
                </div>
              </div>
              <div style={{ maxWidth: "23rem" }}>
                <div className="text-center mb-5">
                  <img
                    className="img-fluid"
                    src="/assets/svg/illustrations/oc-chatting.svg"
                    alt="Chatting"
                    style={{ width: "12rem" }}
                    data-hs-theme-appearance="default"
                  />
                </div>
                <div className="mb-5">
                  <h2 className="display-5">{t("promoTitle")}</h2>
                </div>
                <ul className="list-checked list-checked-lg list-checked-primary list-py-2">
                  <li className="list-checked-item">
                    <span className="d-block fw-semibold mb-1">{t("features.reachNewPeople")}</span>
                    {t("features.reachNewPeopleDescription")}
                  </li>
                  <li className="list-checked-item">
                    <span className="d-block fw-semibold mb-1">{t("features.directEmailMarketing")}</span>
                    {t("features.directEmailMarketingDescription")}
                  </li>
                </ul>
                <div className="row justify-content-between mt-5 gx-3">
                  <div className="col">
                    <img className="img-fluid" src="/assets/svg/brands/gitlab-gray.svg" alt="Logo" />
                  </div>
                  <div className="col">
                    <img className="img-fluid" src="/assets/svg/brands/fitbit-gray.svg" alt="Logo" />
                  </div>
                  <div className="col">
                    <img className="img-fluid" src="/assets/svg/brands/flow-xo-gray.svg" alt="Logo" />
                  </div>
                  <div className="col">
                    <img className="img-fluid" src="/assets/svg/brands/layar-gray.svg" alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
              <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: "25rem" }}>
                <div className="text-center">
                  <div className="mb-4">
                    <img
                      className="avatar avatar-xxl avatar-4x3"
                      src="/assets/svg/illustrations/oc-email-verification.svg"
                      alt="Image Description"
                      data-hs-theme-appearance="default"
                    />
                  </div>
                  <h1 className="display-5">{t("verifyEmailTitle")}</h1>
                  <p className="mb-1">{t("verifyEmailMessage")}</p>
                  <span className="d-block text-dark fw-semibold mb-1">
                    {user?.firebaseUser?.email ? <>{user.firebaseUser.email}</> : <Skeleton width={120} />}
                  </span>
                  <p>{t("followLinkMessage")}</p>
                  <div className="mt-4 mb-3">
                    <Link className="btn btn-primary" to="/disclaimer">
                      {t("skipNowButton")}
                    </Link>
                  </div>
                  <p>
                    {t("resendMessage")}{" "}
                    <div className="text-primary" style={{ cursor: "pointer" }} onClick={handleResend}>
                      {t("resendButton")}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerify;