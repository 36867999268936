import React from "react";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import { searchUsers } from "../api/mailerfind/search";
import { auth } from "../lib/firebase/firebase";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function SearchBar(props: any) {
  const { t } = useTranslation("Component.SearchBar");

  const searchForUsers = async (username: any) => {
    if (!username) return [];
    const jwt = (await auth.currentUser?.getIdToken()) as any;
    const response = await searchUsers(username, jwt);
    const users = response.users.map((user: any) => user.user);
    return users;
  };

  const giveResultsOfSearch = async (username: any) => {
    try {
      const users: any = await searchForUsers(username);
      const publicAccounts = users.filter((user: any) => user.is_private === false);
      const options = publicAccounts.map((user: any) => {
        return {
          label: user.username,
          value: user.username,
        };
      });
      return options;
    } catch (err: any) {
      toast.error(err.message || t("searchError"));
    }
  };

  const loadOptions = debounce((inputValue: string, callback: (options: any) => void) => {
    giveResultsOfSearch(inputValue).then((options: any) => {
      callback(options);
    });
  }, 1000);

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      isMulti={false}
      cacheOptions={true}
      defaultOptions={true}
      onChange={props.onSelect}
      value={props.selectedAccount?.username ? { label: props.selectedAccount.username, value: props.selectedAccount.username } : undefined}
      autoFocus={true}
      placeholder={<div>{t("searchPlaceholder")}</div>}
      isDisabled={props.isDisabled || false}
      defaultValue={
        props.selectedAccount?.username ? { label: props.selectedAccount.username, value: props.selectedAccount.username } : undefined
      }
    />
  );
}

export default SearchBar;