function extractEmailFromString(str: string) {
  return str.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+/gi);
}

function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

const capitalizeString = (string: string) => {
  return string
    .split(" ")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

function isValidInstagramURL(url: string) {
  const regex = /https?:\/\/www\.instagram\.com\/(p|reel)\/[\w-]+\/?/;
  return regex.test(url);
}

function isEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

const bytesToMB = (bytes: any) => {
  const mb = bytes / (1024 * 1024);
  return mb.toFixed(2); // Redondea a dos decimales
};

const convertProspect = (user: any, analysisId: Number) => {
  const bioEmail = user.biography && extractEmailFromString(user.biography);
  const fullNameEmail = user.full_name && extractEmailFromString(user.full_name);
  const newUser = {
    pk: user.pk,
    username: user.username,
    biography: user.biography,
    full_name: user.full_name,
    first_name: user.first_name,
    email: user.public_email || bioEmail || fullNameEmail || null,
    followers: user.follower_count || user.followers,
    following: user.following_count || user.following,
    profile_pic_url: user.profile_pic_url,
    is_business: user.is_business_account === undefined ? user.is_business : user.is_business_account,
    website: user.external_url || null,
    phone_number: user.public_phone_number || null,
    phone_extension: user.public_phone_country_code || null,
    city: user.city_name || null,
    analysisId: analysisId,
  };
  return newUser;
};

const analysisIsCloud = (analysisId: any) => {
  const isLocal = /^\d+$/.test(analysisId);
  return !isLocal;
};

const sanitizeName = (name: string) => {
  return name.replace(/[^a-zA-ZñÑ\- áéíóúÁÉÍÓÚ]/g, "");
};

function getBrowserLanguage() {
  // Obtener el idioma del navegador
  const lang = navigator.language || navigator.languages[0];

  // Convertir el idioma a minúsculas para asegurar uniformidad
  const lowerLang = lang.toLowerCase();

  // Verificar si el idioma es inglés o español
  if (lowerLang.startsWith("en")) {
    return "en";
  } else if (lowerLang.startsWith("es")) {
    return "es";
  } else {
    // Valor por defecto
    return "en";
  }
}

function toCamelCase(str: string) {
  return str
    .toLowerCase() // Convertimos todo a minúsculas primero
    .replace(/[-_\s]+(.)?/g, function (match, char) {
      return char ? char.toUpperCase() : "";
    });
}

export default extractEmailFromString;
export {
  isEmail,
  isEmpty,
  capitalizeString,
  isValidInstagramURL,
  bytesToMB,
  toCamelCase,
  convertProspect,
  analysisIsCloud,
  sanitizeName,
  getBrowserLanguage,
};
