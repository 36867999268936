import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

export const exportAnalysis = async (analysisId: any, filter: any, format: string, jwt: any) => {
  const response: any = await makeRequest({
    url: `${BACKEND_URL}/v1/exports/${analysisId}`,
    method: "POST",
    body: {
      analysisId,
      format,
      filter,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};
