import React, { useContext } from "react";
import { useState } from "react";
import RichTextEditor from "../../components/RichTextEditor";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import SenderSelect from "./SenderSelect";
import CodeEditor from "../../components/CodeEditor";
import EmailSelect from "./EmailSelect";
import AnalysisSelect from "./AnalysisSelect";
import { UserContext } from "../../contexts/UserContext";
import { getEmail } from "../../lib/firebase/emails";
import { Email } from "../../types/EmailTypes";
import { useTranslation } from "react-i18next";

function EmailCreator(props: any) {
  const { t } = useTranslation("NewEmail.EmailCreator");
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("text");
  const { user } = useContext(UserContext) as any;

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Dividimos el nombre por los puntos para manejar propiedades anidadas
    const nameParts = name.split(".");

    props.setEmail((prev: any) => {
      let updatedValue = { ...prev };

      // Manejamos correctamente las propiedades anidadas
      if (nameParts.length === 2) {
        if (!updatedValue[nameParts[0]]) {
          updatedValue[nameParts[0]] = {};
        }
        updatedValue[nameParts[0]][nameParts[1]] = value;
      } else {
        updatedValue[name] = value;
      }

      return updatedValue;
    });

    props.setSaved(false);
  };

  const handleSenderSelectChange = (options: any) => {
    const values = options.map((option: any) => option.value); // Only the ids
    props.setEmail((prev: any) => ({ ...prev, senders: values }));
    props.setSaved(false);
  };

  const handleEmailSelectChange = async (option: any) => {
    const email = (await getEmail(user.firebaseUser.uid, option.value)) as Email;
    props.setEmail((prevFormData: any) => ({
      ...prevFormData,
      analysis: email.analysis,
      followUp: {
        ...prevFormData.followUp,
        email: option.value,
      },
    }));
    props.setSaved(false);
  };

  const handleAnalysisSelectChange = (option: any) => {
    props.setEmail((prevFormData: any) => ({
      ...prevFormData,
      analysis: option.value,
    }));
    props.setSaved(false);
  };

  const setFormBody = (value: any) => {
    if (!value) return;
    props.setEmail((prev: any) => ({ ...prev, body: value }));
    props.setSaved(false);
  };

  const handleCheckboxChange = (event: any) => {
    props.setEmail((prevFormData: any) => ({
      ...prevFormData,
      followUp: {
        ...prevFormData.followUp,
        isAFollowUpEmail: event.target.checked,
      },
    }));
    props.setSaved(false);
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row mt-5">
        <div className="col-md-8">
          <div className="card mb-4" id="email-subject-card">
            <div className="card-header">
              <h5 className="card-title">{t("email_subject")}</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  disabled={loading || props.loading}
                  onChange={handleChange}
                  value={props.email?.subject || ""}
                  className="form-control"
                  id="email-subject"
                  placeholder={t("email_subject_placeholder")}
                />
              </div>
            </div>
          </div>

          <div className="card" id="email-body-card">
            <div className="card-header">
              <h5 className="card-title">{t("email_body")}</h5>
              <ul className="nav nav-segment" id="navTab11" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${mode === "text" && "active"}`}
                    id="nav-resultTab11"
                    onClick={() => setMode("text")}
                    href="#nav-result11"
                    data-bs-toggle="pill"
                    data-bs-target="#nav-result11"
                    role="tab"
                    aria-controls="nav-result11"
                    aria-selected="true"
                  >
                    {t("preview")}
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${mode === "code" && "active"}`}
                    id="nav-htmlTab11"
                    onClick={() => setMode("code")}
                    href="#nav-html11"
                    data-bs-toggle="pill"
                    data-bs-target="#nav-html11"
                    role="tab"
                    aria-controls="nav-html11"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    {t("html")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              {mode === "text" ? (
                <RichTextEditor
                  value={props?.email?.body}
                  setValue={setFormBody}
                  showVariables={true}
                  showEmailAi={true}
                  showTemplates={true}
                  openEmailAiModal={props.openEmailAiModal}
                  openTemplatesModal={props.openTemplatesModal}
                />
              ) : (
                <CodeEditor value={props?.email?.body} setValue={setFormBody} />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card" id="email-settings-card">
            <div className="card-header">
              <h5 className="card-title">{t("email_settings")}</h5>
            </div>
            <div className="card-body">
              <div className="form-group" id="email-title-input">
                <label htmlFor="email-subject">{t("email_title_internal")}</label>
                <input
                  type="text"
                  disabled={loading || props.loading}
                  autoFocus={true}
                  onChange={handleChange}
                  value={props?.email?.title}
                  name="title"
                  className="form-control"
                  id="email-title"
                  placeholder={t("email_title_placeholder")}
                />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group" id="email-analysis-input">
                    <label htmlFor="email-subject">{t("analysis")}</label>
                    <AnalysisSelect
                      value={props?.email?.analysis}
                      disabled={
                        loading || props?.email?.status !== "NOT_STARTED" || props.loading || props.email?.followUp?.isAFollowUpEmail
                      }
                      onChange={handleAnalysisSelectChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" id="email-sender-input">
                    <label htmlFor="accounts-to-use">
                      <a href="/senders" target="_blank">
                        {t("accounts_to_use")} <BoxArrowUpRight className="mb-1 ml-1" size="10" />
                      </a>
                    </label>
                    <SenderSelect email={props.email} onChange={handleSenderSelectChange} />
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="d-flex">
                    <label className="switch my-auto">
                      <input
                        onChange={handleCheckboxChange}
                        checked={props?.email?.followUp?.isAFollowUpEmail}
                        type="checkbox"
                        disabled={props?.email?.status !== "NOT_STARTED"}
                      />
                      <span className="slider round" />
                    </label>
                    <span className="ml-2 my-auto mb-0">{t("follow_up_email")}</span>
                  </div>
                </div>
                {props?.email?.followUp?.isAFollowUpEmail && (
                  <>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="follow-up-mode">{t("email_to_follow_up")}</label>
                        <EmailSelect
                          currentEmailId={props?.email?.id}
                          value={props?.email?.followUp?.email}
                          onChange={handleEmailSelectChange}
                          disabled={props?.email?.status !== "NOT_STARTED"}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="follow-up-mode">{t("follow_up_mode")}</label>
                        <select
                          className="form-control"
                          disabled={loading || props.loading || props?.email?.status !== "NOT_STARTED"}
                          onChange={handleChange}
                          name="followUp.followUpMode"
                          value={props?.email?.followUp?.followUpMode || -1}
                        >
                          <option value="-1" disabled>
                            {t("please_select")}
                          </option>
                          <option value="open">{t("recipients_not_opened")}</option>
                          <option value="click">{t("recipients_not_clicked")}</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EmailCreator;
