import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "../../lib/firebase/firebase";
import Chart from "./Chart";
import Loading from "../../components/Loading";
import { getClickedProspects, getReadProspects } from "../../lib/firebase/prospects";
import { analysisIsCloud } from "../../utils/helpers";
import Table from "./Table";
import { ArrowClockwise, Cursor, EnvelopeOpen, PatchCheckFill, PencilFill, SendFill } from "react-bootstrap-icons";
import { getAnalysisById as getCloudAnalysis } from "../../lib/firebase/analysis";
import { getAnalysis as getLocalAnalysis } from "../../lib/daos/analysisDAO";
import { getEmail } from "../../lib/firebase/emails";
import toast from "react-hot-toast";
import TableClicked from "./TableClicked";

function ViewEmail() {
  const { t } = useTranslation("ViewEmail.index");
  const { id } = useParams() as any;
  const [loading, setLoading] = useState(true);
  const [user] = useState(auth.currentUser) as any;

  // Email related data
  const [analysis, setAnalysis] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [readProspects, setReadProspects] = useState<any[]>([]);
  const [clickedProspects, setClickedProspects] = useState<any[]>([]) as any;
  const [totalUsers, setTotalUsers] = useState(0);

  async function loadInitialData() {
    try {
      let email = null as any;
      let followUpEmail = null as any;
      let analysis = null as any;

      email = await getEmail(user.uid, id);
      if (!email) throw new Error(t("errors.emailNotFound"));
      setEmail(email);

      if (email?.followUp?.isAFollowUpEmail === true) {
        followUpEmail = await getEmail(user.uid, email.followUp.email);
        if (!followUpEmail) throw new Error(t("errors.followUpEmailNotFound"));
      }

      const loadAnalysis = async () => {
        analysis = analysisIsCloud(email.analysis)
          ? await getCloudAnalysis(user.uid, email.analysis)
          : await getLocalAnalysis(null, Number(email.analysis));
        if (!analysis) throw new Error(t("errors.analysisNotFound"));
        setAnalysis(analysis);
      };

      await loadAnalysis();
      const totalUsers = calculateTotalUsers(email, followUpEmail, analysis);
      setTotalUsers(totalUsers);

      // Carga concurrente si es análisis en la nube
      if (analysisIsCloud(email.analysis)) {
        const [readProspectsResponse, clickedProspectsResponse] = await Promise.all([
          getReadProspects(email.analysis, email.id, user.uid, 1000, null),
          getClickedProspects(email.analysis, email.id, user.uid, 1000, null),
        ]);

        setReadProspects(readProspectsResponse.prospects);
        setClickedProspects(clickedProspectsResponse.prospects);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const calculateTotalUsers = (email: any, followUpEmail: any, analysis: any) => {
    if (email?.followUp?.isAFollowUpEmail === true) {
      switch (email?.followUp?.followUpMode) {
        case "open":
          return analysis.emailsFoundCount - followUpEmail.opens;
        case "click":
          return analysis.emailsFoundCount - (followUpEmail.uniqueClicks || followUpEmail.clicks);
        default:
          return toast.error(t("errors.followUpModeNotRecognized"));
      }
    } else {
      return analysis.emailsFoundCount;
    }
  };

  useEffect(() => {
    if (!id) return setLoading(false);
    loadInitialData();
  }, [id]);

  if (loading) return <Loading/>;
  if (!email || !analysis)
    return (
      <div className="row justify-content-center align-items-sm-center py-sm-10">
        <div className="col-9 col-sm-6 col-lg-4">
          <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
            <img src="/assets/svg/illustrations/oc-thinking.svg" className="img-fluid" alt={t("thinkingAlt")} />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 text-center text-sm-start">
          <h1 className="display-1 mb-0">404</h1>
          <p className="lead">
            {t("oops")}, {!email ? t("errors.email") : !analysis ? t("errors.analysis") : t("errors.sender")} {t("notFound")}
          </p>
          <Link className="btn btn-primary" to="/emails">
            {t("goBack")}
          </Link>
        </div>
      </div>
    );

  return (
    <>
      <Header
        title={t("viewEmailStats")}
        subtitle={email.title}
        breadCrumbItems={[
          { title: t("dashboard"), href: "/" },
          { title: t("emails"), href: "/emails" },
          { title: email.title },
        ]}
      >
        <button className="btn btn-outline-primary mr-2" onClick={loadInitialData}>
          <ArrowClockwise />
        </button>
        <Link to={`/emails/edit/${id}`} className={`mr-2 text-right btn btn-outline-primary ${loading && "disabled"}`}>
          <PencilFill /> {t("edit")}
        </Link>
        <Link to={`/emails/send/${id}`} className={`text-right btn btn-primary ${loading && "disabled"}`}>
          <SendFill /> {t("send")}
        </Link>
      </Header>
      <div className="row">
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("totalUsers")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{totalUsers || 0}</h2>
                </div>
              </div>
              <span className="badge bg-soft-primary text-primary">{analysis?.name?.toUpperCase()}</span>
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("sent")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{email?.sent - (email?.skipped || 0) || 0}</h2>
                </div>
              </div>
              <span className="badge bg-soft-primary text-primary">
                {(((email?.sent - (email?.skipped || 0) || 0) / (totalUsers || 1)) * 100).toFixed(2)}%
              </span>
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("openRate")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">
                    {(((email?.opens || 0) / (email?.sent - (email?.skipped || 0) || 1)) * 100).toFixed(0)}%
                  </h2>
                </div>
              </div>
              {email?.sent === 0 ? (
                <span className="badge bg-soft-secondary text-secondary">{t("startSendingToViewStats")}</span>
              ) : (
                <>
                  {Number((((email?.opens || 0) / (email?.sent - (email?.skipped || 0) || 1)) * 100).toFixed(0)) < 10 && (
                    <span className="badge bg-soft-danger text-danger">{t("probablySpam")}</span>
                  )}
                  {Number((((email?.opens || 0) / (email?.sent - (email?.skipped || 0) || 1)) * 100).toFixed(0)) >= 10 &&
                    Number((((email?.opens || 0) / (email?.sent - (email?.skipped || 0) || 1)) * 100).toFixed(0)) < 40 && (
                      <span className="badge bg-soft-warning text-warning">{t("canBeImproved")}</span>
                    )}
                  {Number((((email?.opens || 0) / (email?.sent - (email?.skipped || 0) || 1)) * 100).toFixed(0)) >= 40 &&
                    Number((((email?.opens || 0) / (email?.sent - (email?.skipped || 0) || 1)) * 100).toFixed(0)) < 75 && (
                      <span className="badge bg-soft-warning text-warning">{t("canBeImproved")}</span>
                    )}
                  {Number((((email?.opens || 0) / (email?.sent - (email?.skipped || 0) || 1)) * 100).toFixed(0)) >= 75 && (
                    <span className="badge bg-soft-success text-success">{t("good")}</span>
                  )}
                </>
              )}
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("uniqueClicks")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{email?.uniqueClicks || email?.clicks || 0}</h2>
                </div>
              </div>
              <span className="badge bg-soft-primary text-primary">
                {(((email?.uniqueClicks || email?.clicks || 0) / (email?.opens || 1)) * 100).toFixed(2)}% {t("clickRate")}
              </span>
            </div>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="card card-hover-shadow h-100">
            <div className="card-header">
              <h5 className="card-title">{t("emailStats")}</h5>
            </div>
            <div className="card-body">
              <Chart
                totals={totalUsers || 0}
                sent={email?.sent - (email?.skipped || 0) || 0}
                read={email?.opens || 0}
                clicks={email?.uniqueClicks || email?.clicks || 0}
                unsubscribed={email?.unsubscribed || 0}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          {analysisIsCloud(email.analysis) ? (
            <div className="card card-hover-shadow h-100" style={{ maxHeight: "380px", overflowY: "auto" }}>
              <div className="card-header">
                <h5 className="card-title">
                  <EnvelopeOpen /> {t("openedBy")}
                </h5>
              </div>
              {readProspects.length === 0 ? (
                <div className="text-center py-5">
                  <img src="/assets/svg/illustrations/graphs.svg" className="img-fluid" style={{ maxWidth: "220px" }} />
                  <h5 className="mt-3">{t("noProspectsYet")}</h5>
                  <p>{t("startSendingToViewWhoOpened")}</p>
                </div>
              ) : (
                <Table users={readProspects} />
              )}
            </div>
          ) : (
            <div className="card card-hover-shadow h-100">
              <div className="card-header">
                <h5 className="card-title">
                  <EnvelopeOpen /> {t("openedBy")}
                </h5>
              </div>
              <div className="card-body p-0 m-0 pb-2 position-relative">
                <img src="/assets/img/pro.png" className="round img-fluid" alt="pro" />
                <div className="h1 text-white text-center position-absolute" style={{ top: "50%", width: "100%" }}>
                  <span className="badge bg-primary rounded-pill text-uppercase">
                    <PatchCheckFill /> <span className="mt-3">{t("unlimited")}</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-3">
          {analysisIsCloud(email.analysis) ? (
            <div className="card card-hover-shadow h-100" style={{ maxHeight: "380px", overflowY: "auto" }}>
              <div className="card-header">
                <h5 className="card-title">
                  <Cursor /> {t("clickedBy")}
                </h5>
              </div>
              {clickedProspects.length === 0 ? (
                <div className="text-center py-5">
                  <img src="/assets/svg/illustrations/graphs.svg" className="img-fluid" style={{ maxWidth: "220px" }} />
                  <h5 className="mt-3">{t("noProspectsYet")}</h5>
                  <p>{t("startSendingToViewWhoClicked")}</p>
                </div>
              ) : (
                <TableClicked users={clickedProspects} />
              )}
            </div>
          ) : (
            <div className="card card-hover-shadow h-100">
              <div className="card-header">
                <h5 className="card-title">
                  <Cursor /> {t("clickedBy")}
                </h5>
              </div>
              <div className="card-body p-0 m-0 pb-2 position-relative">
                <img src="/assets/img/clicks.jpg" className="round img-fluid" alt="clicks" />
                <div className="h1 text-white text-center position-absolute" style={{ top: "50%", width: "100%" }}>
                  <span className="badge bg-primary rounded-pill text-uppercase">
                    <PatchCheckFill /> <span className="mt-3">{t("unlimited")}</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewEmail;