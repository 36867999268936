import React from 'react';
import { CheckCircleFill, InfoCircleFill, XCircleFill } from 'react-bootstrap-icons';

type NotificationProps = {
   type: 'danger' | 'warning' | 'success' | 'primary' | 'info';
   dismissible?: boolean;
   message: React.ReactNode;
};

const Notification: React.FC<NotificationProps> = ({ type, dismissible = false, message }) => {
   if (!message) return null;

   const alertClassName = `alert alert-soft-${type} my-3${dismissible ? ' alert-dismissible fade show' : ''}`;

   return (
      <div className={alertClassName} role="alert">
         {type === 'info' && <InfoCircleFill className="mr-2 mb-1" />}
         {type === 'danger' && <XCircleFill className="mr-2 mb-1" />}
         {type === 'success' && <CheckCircleFill className="mr-2" />}
         {message}
      </div>
   );
};

export default Notification;
