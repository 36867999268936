import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

export const startAnalysis = async (analysisId: string, jwt: string): Promise<any> => {
  const response = await makeRequest({
    url: `${BACKEND_URL}/v1/analysis/start/${analysisId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });

  return response;
};

export const pauseAnalysis = async (analysisId: string, jwt: string): Promise<any> => {
  const response = await makeRequest({
    url: `${BACKEND_URL}/v1/analysis/pause/${analysisId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });

  return response;
};
