import React from "react";
import Header from "../../components/Header";
import Product from "./Product";
import { useTranslation } from "react-i18next";

const products = [
  {
    id: 1,
    nameKey: "consulting",
    slug: "consultoria-personalizada",
    descriptionKey: "consultingDescription",
    price: 10,
    published: false,
    image: "https://via.placeholder.com/150x100",
  },
  {
    id: 2,
    nameKey: "vipSupport",
    slug: "vip-support",
    descriptionKey: "vipSupportDescription",
    price: 20,
    published: false,
    image: "https://via.placeholder.com/150x100",
  },
  {
    id: 3,
    nameKey: "extraCredits",
    slug: "creditos-adicionales",
    descriptionKey: "extraCreditsDescription",
    price: 5,
    published: false,
    image: "https://via.placeholder.com/150x100",
  },
  {
    id: 4,
    nameKey: "speedBoost",
    slug: "speed-boost",
    descriptionKey: "speedBoostDescription",
    price: 5,
    published: true,
    image: "/assets/vendor/duotone-icons/gen/gen002.svg",
  },
  {
    id: 4,
    nameKey: "technicalSetup",
    slug: "tech-setup",
    descriptionKey: "technicalSetupDescription",
    price: 5,
    published: true,
    image: "/assets/vendor/duotone-icons/cod/cod009.svg",
  },
];

function Store() {
  const { t } = useTranslation("Store");

  return (
    <div>
      <Header title={t("storeTitle")} subtitle={t("storeSubtitle")} breadCrumbItems={[{ href: "/store", title: t("storeTitle") }]}></Header>
      <div className="row">
        {products.map((product, index) => {
          return (
            <React.Fragment key={index}>
              {product.published && (
                <div className="col-lg-3">
                  <Product product={product} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Store;
