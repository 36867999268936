import React, { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Skeleton from "react-loading-skeleton";
import { getInstagramPicture } from "../../api/mailerfind/users";
import { auth } from "../../lib/firebase/firebase";
import { STATUS } from "../../utils/constants";
import { ArrowLeft } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function PostSelect(props: any) {
  const { t } = useTranslation("PostSelect");

  return (
    <div className="row d-flex align-items-stretch">
      <div className="col-lg-8 mb-4">
        <PostSelector
          onPostUrlChange={props.onSelect}
          postUrl={props?.selectedPost && `https://www.instagram.com/p/${props?.selectedPost?.code}/`}
          handleConfirm={props.handleConfirm}
          status={props.status}
          mode={props?.mode}
          handleBack={props.handleBack}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <PostInformation selectedPost={props.selectedPost} postIsLoading={props.spostIsLoading} />
      </div>
    </div>
  );
}

function PostSelector(props: any) {
  const { t } = useTranslation("PostSelect");

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-auto pr-2">
            <button
              type="button"
              className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              aria-label={t("profile")}
              data-bs-original-title={t("profile")}
              onClick={props.handleBack}
              disabled={props.status !== STATUS.NOT_STARTED}
            >
              <ArrowLeft />
            </button>
          </div>
          <div className="col-auto my-auto p-0">
            <h3 className="card-header-title m-0 p-0">{t("selectPost")}</h3>
          </div>
        </div>
        <p>{t("insertPostLink")}</p>
        {props?.mode === "likers" ? (
          <Notification message={t("temporaryUnavailable")} type="danger" />
        ) : (
          <Notification message={t("onlyPublicPosts")} type="primary" />
        )}
        <input
          type="url"
          className="form-control"
          placeholder="https://www.instagram.com/p/Cbku6vkAiGG/"
          onChange={props.onPostUrlChange}
          value={props.postUrl}
          disabled={props.mode === "likers" || props.status !== STATUS.NOT_STARTED}
        ></input>
      </div>
    </div>
  );
}

function PostInformation(props: any) {
  const { t } = useTranslation("PostSelect");
  const [postImage, setPostImage] = useState() as any;
  const [profileImage, setProfileImage] = useState() as any;

  const retrieveInstagramPicture = async (url: any) => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      const blob: any = await getInstagramPicture(url, jwt);
      return blob;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!props.selectedPost) return;
    return;
    retrieveInstagramPicture(
      props.selectedPost?.image_versions2?.candidates[0]?.url || props.selectedPost?.carousel_media[0]?.image_versions2?.candidates[0]?.url
    ).then((blob) => {
      setPostImage(URL.createObjectURL(blob));
    });
  }, [props.selectedPost]);

  useEffect(() => {
    if (!props.selectedPost) return;
    retrieveInstagramPicture(props.selectedPost.user.profile_pic_url).then((blob) => {
      setProfileImage(URL.createObjectURL(blob));
    });
  }, [props.selectedPost]);

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="row">
          <div className="col-auto">
            {props.postIsLoading || !props.selectedPost ? (
              <Skeleton circle={true} width={30} height={30} />
            ) : (
              <img
                src={profileImage || "/assets/img/160x160/img1.jpg"}
                alt={t("profile")}
                className="avatar avatar-sm avatar-circle"
                style={{ width: "30px", height: "30px" }}
              />
            )}
          </div>
          <div className="col my-auto pl-0">
            {props.postIsLoading || !props.selectedPost ? (
              <Skeleton width={100} />
            ) : (
              <p className="my-auto text-dark">
                <b>{props?.selectedPost?.user?.username || t("username")}</b>
              </p>
            )}
          </div>
        </div>

        <img
          src={postImage || "/assets/img/160x160/img1.jpg"}
          alt="Post"
          className="img-fluid round mt-3"
          style={{
            width: 128,
            height: 128,
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
      </div>
    </div>
  );
}

export default PostSelect;