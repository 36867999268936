import { BACKEND_URL } from '../../utils/constants';
import makeRequest from '../client';

export const getMediaLikers = async (mediaId: string, endCursor: string | null, jwt: string): Promise<any> => {
   const response = await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-media-likers`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         mediaId,
         endCursor,
      },
   });

   return response;
};

export const getMediaCommenters = async (mediaId: string, endCursor: string | null, jwt: string): Promise<any> => {
   const response = await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-media-commenters`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         mediaId,
         endCursor,
      },
   });

   return response;
};

export const getMediaInfo = async (shortcode: string, jwt: string): Promise<any> => {
   const response = await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-media-info`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         shortcode,
      },
   });

   return response;
};
