import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { db } from "./firebase";
import { Email } from "../../types/EmailTypes";

export const getEmail = async (userId: string, emailId: string) => {
  const userRef = doc(db, "users", userId);
  const emailRef = doc(userRef, "emails", emailId);
  const docSnap = await getDoc(emailRef);
  if (!docSnap.exists()) return null;
  const data = docSnap.data();
  const email: Email = {
    version: data.version,
    title: data.title,
    subject: data.subject,
    body: data.body,
    senders: data.senders,
    analysis: data.analysis, // Asegúrate de que el tipo de dato coincida o transformalo aquí
    createdAt: data.createdAt.toDate(), // Convertimos de Timestamp de Firestore a Date de JavaScript
    sent: data.sent,
    opens: data.opens,
    clicks: data.clicks,
    followUp: {
      isAFollowUpEmail: data.followUp?.isAFollowUpEmail ?? false, // Asegúrate de manejar los valores predeterminados correctamente
      followUpMode: data.followUp?.followUpMode ?? null,
      email: data.followUp?.email ?? null,
    },
    status: data.status,
    id: docSnap.id, // Agregamos el ID si es necesario en tu FormData
    project: data.project,
  };
  return email;
};

export const getEmails = async (userId: any, orderByParam = null as any, filterParams = null as any) => {
  const userRef = doc(db, "users", userId);
  const emailsRef = collection(userRef, "emails");

  let q = query(emailsRef);

  // Aplicar filtros si se proporcionan
  if (filterParams) {
    const whereClauses = [];
    for (const [field, values] of Object.entries(filterParams) as any) {
      if (Array.isArray(values) && (values.length > 10 || values.length === 0)) {
        // Manejar el caso donde hay más de 10 valores.
        // Firestore 'in' no soporta más de 10 valores.
        // Podrías dividir esto en múltiples consultas o reconsiderar el enfoque.
      } else {
        // Usa 'in' para crear una condición que matchee cualquiera de los valores especificados.
        if (Array.isArray(values)) {
          whereClauses.push(where(field, "in", values));
        } else {
          values !== undefined && whereClauses.push(where(field, "==", values));
        }
      }
    }

    // Si se proporciona orderByParam, incluirlo en la consulta
    if (orderByParam) {
      if (Array.isArray(orderByParam) && orderByParam.length === 2) {
        q = query(emailsRef, ...whereClauses, orderBy(orderByParam[0], orderByParam[1]));
      } else {
        // Si orderByParam es un string, poner orden descendente
        q = query(emailsRef, ...whereClauses, orderBy(orderByParam, "desc"));
      }
    } else {
      // Si no se proporciona orderByParam, crea la consulta solo con los filtros
      q = query(emailsRef, ...whereClauses);
    }
  } else {
    // Si no se proporcionan filtros pero sí ordenamiento
    if (orderByParam) {
      if (Array.isArray(orderByParam) && orderByParam.length === 2) {
        q = query(emailsRef, orderBy(orderByParam[0], orderByParam[1]));
      } else {
        q = query(emailsRef, orderBy(orderByParam, "desc"));
      }
    }
  }

  const querySnapshot = await getDocs(q);
  const emails = [] as any;
  querySnapshot.forEach((doc) => {
    const email = doc.data();
    email.id = doc.id;
    emails.push(email);
  });
  return emails;
};

export const createEmail = async (userId: any, email: any) => {
  const userRef = doc(db, "users", userId);
  const { id } = await addDoc(collection(userRef, "emails"), email);
  return id;
};

export const updateEmail = async (userId: any, emailId: any, email: any) => {
  // Referencia al documento que quieres actualizar
  const emailRef = doc(db, "users", userId, "emails", emailId);

  // Actualizar el documento
  try {
    await updateDoc(emailRef, email);
  } catch (error) {
    console.error("Error updating email: ", error);
    throw error;
  }
};

export const deleteEmail = async (userId: any, emailId: any) => {
  const userRef = doc(db, "users", userId);
  const emailRef = doc(userRef, "emails", emailId);
  await deleteDoc(emailRef);
};

export const duplicateEmail = async (userId: any, emailId: any) => {
  const userRef = doc(db, "users", userId);
  const emailRef = doc(userRef, "emails", emailId);
  const docSnap = await getDoc(emailRef);
  if (!docSnap.exists()) return null;
  const email = docSnap.data();
  email.id = docSnap.id;
  email.title = email.title + " (copy)";
  email.createdAt = new Date();
  email.clicks = 0;
  email.opens = 0;
  email.uniqueClicks = 0;
  email.sent = 0;
  email.skipped = 0;
  email.status = "NOT_STARTED";
  const newEmailId = await createEmail(userId, email);
  return newEmailId;
};
