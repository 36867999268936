import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";

import "ace-builds/webpack-resolver";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";

import { html } from "js-beautify";

function CodeEditor({ value, setValue }: any) {
  const [editorValue, setEditorValue] = useState(value);

  useEffect(() => {
    const beautifyOptions = {
      indent_size: 2,
      space_in_empty_paren: true,
      // Otras opciones si son necesarias
    };
    setEditorValue(html(value, beautifyOptions));
  }, [value]); // Solo se ejecutará cuando `value` cambie

  const handleEditorChange = (newValue: string) => {
    setEditorValue(newValue); // Actualiza el valor interno del editor
    setValue(newValue); // Propaga los cambios hacia afuera si es necesario
  };

  return (
    <AceEditor
      mode="html"
      style={{ width: "100%", borderRadius: "8px", height: "347px", padding: "20px" }}
      highlightActiveLine={false}
      value={editorValue}
      showPrintMargin={true}
      showGutter={true}
      fontSize={14}
      theme="monokai"
      onChange={handleEditorChange}
      name="mf_code_editor"
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
      editorProps={{ $blockScrolling: true }}
    />
  );
}

export default CodeEditor;
