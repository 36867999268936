import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import { auth } from "../lib/firebase/firebase";
import { getMyCoupon } from "../api/mailerfind/stripe";
import toast from "react-hot-toast";
import PremiumModal from "./PremiumModal";
import { useTranslation } from 'react-i18next';

function formatNumber(number: number) {
  return String(number).padStart(2, "0");
}

function CountdownTimer() {
  const { t } = useTranslation("Component.CountdownTimer");
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [coupon, setCoupon] = useState(null) as any;
  const [loading, setLoading] = useState(false);

  const getCoupon = async () => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser?.getIdToken();
      const response = await getMyCoupon(jwt);
      if (!response.coupon) return;
      if (response.coupon.redeem_by < Math.floor(Date.now() / 1000)) {
        response.coupon.expired = true;
      } else response.coupon.expired = false;
      setCoupon(response.coupon);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCoupon();
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return (
        <h1 className="text-dark button btn btn-primary" style={{ position: "sticky", top: 0, zIndex: 999, width: "100%" }}>
          {t("completed")}
        </h1>
      );
    } else {
      // Render a countdown
      return (
        <div
          style={{ position: "sticky", top: 0, zIndex: 9, width: "100%" }}
          className="bg-primary text-white p-2 d-flex align-items-center justify-content-center"
        >
          <span className="font-weight-bolder countdown-column">🚨 {t("attentionMessage")}</span>
          <span className="countdown-column">
            <span className="countdown-item">{formatNumber(days)}</span>
            <span className="countdown-item">{formatNumber(hours)}</span>
            <span className="countdown-item">{formatNumber(minutes)}</span>
            <span className="countdown-item">{formatNumber(seconds)}</span>
          </span>
          <span className="countdown-column">
            <button onClick={() => setShowPremiumModal(true)} className="btn btn-light btn-xs text-dark font-weight-bolder">
              {t("upgrade")}
            </button>
          </span>
        </div>
      );
    }
  };

  return (
    <>
      {coupon && coupon.redeem_by && !coupon.expired && (
        <>
          <Countdown date={new Date(coupon?.redeem_by * 1000)} zeroPadTime={2} renderer={renderer} />
          <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} title={t("upgradeTitle")} />
        </>
      )}
    </>
  );
}

export default CountdownTimer;