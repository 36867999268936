import React, { createContext, useContext, useState } from "react";
import { Wizard } from "../types/WizardTypes";
import { auth } from "../lib/firebase/firebase";
import { UserContext } from "./UserContext";

export const WizardContext = createContext({}) as any;

export const WizardProvider = ({ children }: any) => {
  const { setUser } = useContext(UserContext) as any;
  const [wizard, setWizard] = useState<Wizard>({
    currentStep: 1,
    completed: false,
    completedAt: null,
    analysisId: null,
    senderId: null,
    emailId: null,
  });

  const updateWizard = async (updates: any) => {
    // Asegúrate de que el usuario esté autenticado
    if (!auth || !auth.currentUser) {
      console.error("No authenticated user available.");
      return;
    }

    const mergedUpdates = { ...wizard, ...updates };
    await setUser({ wizard: mergedUpdates }).then(() => {});

    // Intenta realizar la operación asíncrona
    try {
      // Si la operación es exitosa, actualiza el estado de React
      setWizard((prevWizard) => {
        const mergedUpdates = { ...prevWizard, ...updates };
        setUser({ wizard: mergedUpdates }).then(() => {});
        // Aquí usamos el estado previo para asegurarnos de tener la versión más reciente
        return { ...prevWizard, ...updates };
      });
    } catch (error) {
      // Maneja cualquier error que pueda ocurrir durante la operación asíncrona
      console.error("Failed to update wizard:", error);
    }
  };

  return <WizardContext.Provider value={{ wizard, updateWizard }}>{children}</WizardContext.Provider>;
};
