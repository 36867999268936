import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Dependencies
import "./assets/scss/main.scss";
import "./assets/vendor/icon-set/style.css";
import "./assets/vendor/hs-unfold/dist/hs-unfold.css";

const root = ReactDOM.createRoot(document.getElementById("mailerfind"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
