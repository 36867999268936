import React from "react";
import { Modal } from "react-bootstrap";
import { Check } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function ExportLoadingPopUp(props: any) {
  const { t } = useTranslation("Component.ExportLoadingPopUp");

  return (
    <Modal show={props.show} centered size="sm">
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {props.finished ? (
            <>
              <div className="bg-success mx-auto p-0" style={{ width: "65px", height: "65px", borderRadius: 999 }}>
                <Check className="text-white" size={60} style={{ marginTop: "4px" }} />
              </div>
              <p className="modal-title text-dark mt-3">
                {t("exportSuccessMessage")}
              </p>
              <button className="mt-3 btn btn-white btn-sm" onClick={props.close}>
                {t("ok")}
              </button>
            </>
          ) : (
            <>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{t("loading")}</span>
              </div>
              <p className="modal-title text-dark mt-2">
                <b>{t("exporting")}</b>
              </p>
              <p>{t("pleaseDoNotClose")}</p>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ExportLoadingPopUp;