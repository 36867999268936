import React, { useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import Analysis from "../Analysis/index";
import SpeedModal from "./SpeedModal";
import { STATUS } from "../../utils/constants";
import { Wizard } from "../../types/WizardTypes";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

interface Props {
  wizard: Wizard;
}

function Step2({ wizard }: Props) {
  const { t } = useTranslation("Wizard.Step2");
  const CURRENT_STEP = 2;
  const [showModal, setShowModal] = useState(false);
  const [analysis, setAnalysis] = useState(null) as any;
  const [analysisHasStarted, setAnalysisHasStarted] = useState(false);
  const [modalShowHasFinished, setModalShowHasFinished] = useState(false);

  useEffect(() => {
    if (!analysisHasStarted) return;
    // Establece un temporizador para cambiar showModal a true después de 7 segundos
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 7000); // 7000 milisegundos = 7 segundos

    // Limpiar el temporizador si el componente se desmonta antes de que se cumpla el tiempo
    return () => clearTimeout(timer);
  }, [analysisHasStarted]);

  const handleChange = (analysis: any) => {
    if (analysis.status === STATUS.RUNNING && !analysisHasStarted) {
      setAnalysisHasStarted(true);
    }

    if (analysis.status === STATUS.COMPLETED) {
      setModalShowHasFinished(true);
    }

    setAnalysis(analysis);
  };

  useEffect(() => {
    if (!analysisHasStarted || showModal) return;
    setModalShowHasFinished(true);
  }, [showModal]);

  if (wizard.currentStep === 1) return <Loading />;

  return (
    <>
      <Wrapper
        step={CURRENT_STEP}
        title={t("title", { username: analysis?.selectedItem?.username })}
        subtitle={t("subtitle", { fullName: analysis?.selectedItem?.full_name })}
        cardWidth="65rem"
        disabled={analysis?.creditsUsed === 0 && !modalShowHasFinished && wizard.currentStep <= CURRENT_STEP}
        focus={modalShowHasFinished}
        newTab={analysis?.version <= 2 && analysis?.status === STATUS.RUNNING}
      >
        <Analysis id={wizard.analysisId} focusMode={true} onChange={handleChange} />
      </Wrapper>
      <SpeedModal show={showModal} setShow={setShowModal} />
    </>
  );
}

export default Step2;
