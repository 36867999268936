import React from "react";
import Breadcumb from "./Breadcumb";

interface BreadcrumbItem {
  title: string;
  href?: string;
}

interface HeaderProps {
  title: string;
  subtitle: string;
  breadCrumbItems?: BreadcrumbItem[];
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  breadCrumbItems,
  children,
}) => (
  <div className="row align-items-end">
    <div className="col-sm mb-2 mb-sm-0">
      {breadCrumbItems && <Breadcumb items={breadCrumbItems} />}
      <h1 className="page-header-title">{title}</h1>
      <p>{subtitle}</p>
    </div>
    <div className="col-sm-auto my-auto">{children}</div>
  </div>
);

export default Header;
