import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import { auth } from "../../lib/firebase/firebase";
import { BACKEND_URL } from "../../utils/constants";
import Notification from "../../components/Notification";
import Sender from "../Sender";
import { createSender, getSender, getSenders } from "../../lib/firebase/senders";
import { Sender as SenderType } from "../../types/SenderTypes";
import { useNavigate } from "react-router-dom";
import { ChevronDown, LightningChargeFill } from "react-bootstrap-icons";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { UserContext } from "../../contexts/UserContext";
import toast from "react-hot-toast";
import Loading from "../../components/Loading";
import { Wizard } from "../../types/WizardTypes";
import { useTranslation } from "react-i18next";

interface Props {
  wizard: Wizard;
  setSenderId: (id: string) => void;
}

function CustomToggle({ children, eventKey }: any) {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log("totally custom!"));

  return (
    <button type="button" className="btn btn-ghost-primary" onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

function Step3({ wizard, setSenderId }: Props) {
  const { t } = useTranslation("Wizard.Step3");
  const CURRENT_STEP = 3;
  const { user } = useContext(UserContext) as any;
  const [provider, setProvider] = useState("");
  const navigate = useNavigate();
  const [newSenderId, setNewSenderId] = useState();
  const [loading, setLoading] = useState(false);
  const [sender, setSender] = useState<SenderType>({
    name: (user.firebaseUser?.firstname || "") + " " + (user.firebaseUser?.surname || ""),
    email: user.firebaseUser?.email || "",
    provider: provider as string,
    project: null,
    messagesPerDay: 50,
    delayBetweenEmails: 5,
    lastEmailSent: null,
    signature: `<p class="ql-align-center"><span class="ql-variable"><a href={{&nbsp;unsubscribe_link&nbsp;}}>Unsubscribe</a></span></p>`,
    isValid: false,
  });

  const loadGmailSender = async () => {
    const senders = await getSenders(user.firebaseUser.uid, { provider: "google" });
    if (senders.length === 0) return;
    setNewSenderId(senders[0].id);
    setSenderId(senders[0].id);
    navigate(`/onboarding/step/${CURRENT_STEP + 1}`);
  };

  const fetchSender = async (senderId: string) => {
    try {
      setLoading(true);
      const sender = await getSender(user.firebaseUser.uid, senderId);
      setSender(sender);
      setProvider(sender.provider);
      setNewSenderId(sender.id);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (wizard.senderId) fetchSender(wizard.senderId);
  }, [wizard.senderId]);

  useEffect(() => {
    // Función para manejar el cambio de visibilidad
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (provider === "google") loadGmailSender();
      }
    };

    // Agregar el listener para el evento de cambio de visibilidad
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Limpiar el listener cuando el componente se desmonta
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [provider]);

  const handleConnect = async (provider: any) => {
    setProvider(provider);
    if (provider === "smtp" || provider === "sendgrid") {
      sender.provider = provider;
      const newSender = (await createSender(user.firebaseUser.uid, sender)) as any;
      setNewSenderId(newSender.id);
      setSenderId(newSender.id);
      return;
    }
    const jwt = await auth.currentUser?.getIdToken();
    const url = `${BACKEND_URL}/v1/auth/${provider}?idToken=${jwt}&project=${null}`;
    window.open(url, "_blank");
  };

  const handleCancel = () => {
    setProvider("");
    setSenderId("");
  };

  return (
    <Wrapper title={t("title")} subtitle={t("subtitle")} step={CURRENT_STEP} disabled={!wizard.senderId} skippable={true}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {provider && newSenderId && provider !== "google" ? (
            <div className="px-4">
              <Sender id={newSenderId} focusMode={true} handleCancel={handleCancel} />
            </div>
          ) : (
            <ProviderSelector handleConnect={handleConnect} />
          )}
        </>
      )}
    </Wrapper>
  );
}

function ProviderSelector({ handleConnect }: any) {
  const { t } = useTranslation("Wizard.Step3");

  return (
    <div className="row">
      <div className="col-lg-4 mb-4">
        <div className="card border border-success w-100" onClick={() => handleConnect("google")} style={{ cursor: "pointer" }}>
          <div className="card-body text-center">
            <img className="avatar mb-2" src="/assets/img/logos/gmail.jpg" alt="Gmail Logo" />
            <h6 className="m-0 p-0">Gmail</h6>
          </div>
          <small
            className="bg-success text-white px-2 py-1 rounded-pill position-absolute"
            style={{ transform: "translateY(-15px)", fontSize: 12 }}
          >
            <LightningChargeFill /> <span className="align-middle">{t("faster")}</span>
          </small>
        </div>
      </div>
      <div className="col-lg-4 mb-4">
        <div className="card" onClick={() => handleConnect("sendgrid")} style={{ cursor: "pointer" }}>
          <div className="card-body text-center">
            <img className="avatar mb-2" src="/assets/svg/brands/sendgrid.svg" alt="Sendgrid Logo" />
            <h6 className="m-0 p-0">Sendgrid</h6>
          </div>
        </div>
      </div>
      <div className="col-lg-4 mb-4">
        <div className="card" onClick={() => handleConnect("smtp")} style={{ cursor: "pointer" }}>
          <div className="card-body text-center">
            <img className="avatar mb-2" src="/assets/img/logos/ses.png" alt="Sendgrid Logo" />
            <h6 className="m-0 p-0">Amazon SES</h6>
          </div>
        </div>
      </div>
      <div className="col-12">
        <Accordion>
          <div className="text-center">
            <CustomToggle eventKey="0">
              {t("showAll")} <ChevronDown />
            </CustomToggle>
          </div>
          <Accordion.Collapse eventKey="0">
            <div className="row">
              <div className="col-lg-4 mb-4 mt-4 mt-lg-0">
                <div className="card" onClick={() => handleConnect("smtp")} style={{ cursor: "pointer" }}>
                  <div className="card-body text-center">
                    <img className="avatar mb-2" src="/assets/svg/other.svg" alt="Other Logo" />
                    <h6 className="m-0 p-0">{t("other")}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>

      <div className="col-12">
        <Notification message={t("noMailServerMessage")} type="primary" />
      </div>
    </div>
  );
}

export default Step3;
