import React from "react";

function Testimonial({ text, author, position, avatar }: any) {
  return (
    <div className="card h-100">
      <div className="card-body">
        {/* Rating */}
        <div className="d-flex gap-1 mb-2">
          <img src="../assets/svg/illustrations/star.svg" alt="Review rating" width={16} />
          <img src="../assets/svg/illustrations/star.svg" alt="Review rating" width={16} />
          <img src="../assets/svg/illustrations/star.svg" alt="Review rating" width={16} />
          <img src="../assets/svg/illustrations/star.svg" alt="Review rating" width={16} />
          <img src="../assets/svg/illustrations/star.svg" alt="Review rating" width={16} />
        </div>
        {/* End Rating */}
        <div className="mb-auto">
          <p className="card-text">{text}</p>
        </div>
      </div>
      <div className="card-footer ">
        {/* Media */}
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <img className="avatar avatar-circle" src={avatar || "/assets/img/160x160/img8.jpg"} alt="Profile pic" />
          </div>
          <div className="flex-grow-1 ml-3 mt-1">
            <h5 className="card-title mb-0">{author}</h5>
            <p className="card-text small">{position}</p>
          </div>
        </div>
        {/* End Media */}
      </div>
    </div>
  );
}

export default Testimonial;
