import React, { useEffect, useState } from "react";
import { Eye } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { auth, signInWithEmailAndPassword } from "../../lib/firebase/firebase";
import { Link, useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { sanitizeName } from "../../utils/helpers";
import { createMauticContact } from "../../api/mailerfind/users";
import { getIdToken, getUser, updateUser } from "../../lib/firebase/user";
import { useTranslation } from "react-i18next";

function Login() {
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordInput, setPasswordInput] = useState("password");
  const navigate = useNavigate();
  const { t } = useTranslation("login");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (auth.currentUser) {
      navigate("/");
    }
  }, [auth.currentUser]);

  // handle change function
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleViewPassword = () => {
    if (passwordInput === "password") {
      setPasswordInput("text");
    } else {
      setPasswordInput("password");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    const { email, password } = formData;
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        toast.success(t("welcomeBack"));
      })
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        switch (errorCode) {
          case "auth/invalid-email":
            setErrorMessage(t("invalidEmail"));
            break;
          case "auth/user-disabled":
            setErrorMessage(t("userDisabled"));
            break;
          case "auth/user-not-found":
            setErrorMessage(t("userNotFound"));
            break;
          case "auth/wrong-password":
            setErrorMessage(t("wrongPassword"));
            break;
          default:
            setErrorMessage(errorMessage);
        }
        setLoading(false);
      });
  };

  const handleSignInWithGoogle = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    auth.useDeviceLanguage();

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result) as any;
      const token = credential.accessToken;
      const user = result.user as any;
      const newUser = {
        name: user.displayName,
        surname: "",
      };

      // Verificar la fecha de creación del usuario
      const userCreationTime = new Date(user.metadata.creationTime).getTime();
      const currentTime = Date.now();
      const timeDifferenceInSeconds = (currentTime - userCreationTime) / 1000;

      // Si el usuario fue creado hace menos de 20 segundos
      if (timeDifferenceInSeconds <= 20) {
        await updateFirestoreUser(newUser, token);
      } else {
        // Esperar sin hacer nada más
        await new Promise((resolve) => setTimeout(resolve, 1500));
      }
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateFirestoreUser = async (user: any, token: string) => {
    let firebaseUser = await getUser(auth.currentUser!.uid);

    while (!firebaseUser) {
      firebaseUser = await getUser(auth.currentUser!.uid);
      await new Promise((r) => setTimeout(r, 500));
    }

    await updateUser(auth.currentUser!.uid, {
      firstname: sanitizeName(user.name),
      name: sanitizeName(user.name),
      surname: sanitizeName(user.surname),
      referral: localStorage.getItem("referral") || null,
      "preferences.communications": user.communications || false,
      "preferences.language": "en",
      "preferences.earlyRelease": false,
      "preferences.currency": localStorage.getItem("currency") || "eur",
    });

    const jwt = await getIdToken();
    await createMauticContact(jwt);
  };

  return (
    <div>
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          backgroundImage: "url(./assets/svg/components/abstract-bg-4.svg)",
        }}
      >
        {/* SVG Bottom Shape */}
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
        {/* End SVG Bottom Shape */}
      </div>
      {/* Content */}
      <div className="container py-5 py-sm-7">
        <Link className="d-flex justify-content-center mb-5" to="/login">
          <img className="z-index-2" src="./assets/img/logos/mailerfind.png" alt="Logo" style={{ width: "14rem" }} />
        </Link>
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            {/* Card */}
            <div className="card card-lg mb-5 mx-auto" style={{ maxWidth: "30rem" }}>
              <div className="card-body">
                <div className="text-center">
                  <div className="mb-5">
                    <h1 className="display-4">{t("title")}</h1>
                    <p>
                      {t("noAccount")}{" "}
                      <Link to="/register">
                        <b>{t("signUpHere")}</b>
                      </Link>
                    </p>
                  </div>
                  <div className="d-grid mb-4">
                    <button className="btn btn-white btn-lg btn-block" onClick={handleSignInWithGoogle}>
                      <span className="d-flex justify-content-center align-items-center">
                        <img className="avatar avatar-xss mr-2" src="/assets/svg/brands/google-icon.svg" alt="Image Description" />
                        {t("signInWithGoogle")}
                      </span>
                    </button>
                  </div>
                  <span className="divider-center text-muted mb-4">{t("dividerText")}</span>
                </div>
                {/* Form */}
                <form className="js-validate" onSubmit={handleSubmit}>
                  {/* Form Group */}
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="email">
                      {t("form.yourEmail")}
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      name="email"
                      id="email"
                      tabIndex={1}
                      placeholder={t("form.emailPlaceholder")}
                      aria-label={t("form.emailAria")}
                      required
                      data-msg={t("form.emailInvalid")}
                    />
                  </div>
                  {/* End Form Group */}
                  {/* Form Group */}
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="password" tabIndex={0}>
                      <span className="d-flex justify-content-between align-items-center">
                        {t("form.password")}
                        <Link className="input-label-primary" to="/forgot-password" rel="noreferrer">
                          <b>{t("form.forgotPassword")}</b>
                        </Link>
                      </span>
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type={passwordInput}
                        value={formData.password}
                        onChange={handleChange}
                        className="js-toggle-password form-control form-control-lg"
                        name="password"
                        tabIndex={2}
                        id="password"
                        placeholder={t("form.passwordPlaceholder")}
                        aria-label={t("form.passwordAria")}
                        required
                        data-msg={t("form.passwordInvalid")}
                      />
                      <div id="changePassTarget" className="input-group-append">
                        <div className="input-group-text" onClick={handleViewPassword} style={{ cursor: "pointer" }}>
                          <Eye size="20px" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Form Group */}
                  {/* Checkbox */}
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input tabIndex={3} type="checkbox" className="custom-control-input" id="termsCheckbox" name="termsCheckbox" />
                      <label className="custom-control-label text-muted" htmlFor="termsCheckbox">
                        {t("form.rememberMe")}
                      </label>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="alert alert-soft-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  {/* End Checkbox */}
                  <button tabIndex={4} type="submit" id="submit" className="btn btn-block btn-primary btn-lg" disabled={loading}>
                    {t("form.signIn")}
                  </button>
                </form>
                {/* End Form */}
              </div>
            </div>
            {/* End Card */}
          </div>
        </div>
      </div>
      {/* End Content */}
    </div>
  );
}

export default Login;