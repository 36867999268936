import db from '../db/db';

export const createProspect = async (user) => {
   try {
      const id = await db.prospects.add(user);
      return id;
   } catch (error) {
      throw error;
   }
};

export const createProspects = async (users) => {
   try {
      const ids = await db.prospects.bulkAdd(users, { allKeys: true });
      return ids;
   } catch (error) {
      throw error;
   }
};

export const addProspectsToAnalysis = async (analysisId, users) => {
   try {
      for (let i = 0; i < users.length; i++) {
         users[i].analysisId = analysisId;
      }
      const ids = await db.prospects.bulkAdd(users, { allKeys: true });
      return ids;
   } catch (error) {
      throw error;
   }
};

export const getAllProspects = async (page = 0, perPage = 100) => {
   try {
      const prospects = await db.prospects
         .offset(page * perPage)
         .limit(perPage)
         .toArray();
      return prospects;
   } catch (error) {
      throw error;
   }
};

export const getAllProspectsInAnalysis = async (analysisId, page = 0, perPage = 100) => {
   try {
      const prospects = await db.prospects
         .where('analysisId')
         .equals(analysisId)
         .offset(page * perPage)
         .limit(perPage)
         .toArray();
      return prospects;
   } catch (error) {
      throw error;
   }
};

export const getAllProspectsInAnalysisWithFilter = async (analysisId, filter, page = 0, perPage = 100) => {
   try {
      let collection = db.prospects.where('analysisId').equals(analysisId);

      if (filter) {
         switch (filter) {
            case 'email':
               collection = collection.and((prospect) => prospect.email && prospect?.email !== '');
               break;
            case 'phone_number':
               collection = collection.and(
                  (prospect) => prospect.phone_number && prospect?.phone_number?.trim() !== ''
               );
               break;
            case 'website':
               collection = collection.and((prospect) => prospect.website && prospect?.website?.trim() !== '');
               break;

            case 'name_and_email':
               collection = collection.and(
                  (prospect) =>
                     prospect.email && prospect?.email !== '' && prospect.first_name && prospect?.first_name !== ''
               );
               break;
            default:
               break; // No additional filtering
         }
      }

      const prospects = await collection
         .offset(page * perPage)
         .limit(perPage)
         .toArray();
      return prospects;
   } catch (error) {
      throw error;
   }
};

export const getProspectsWithEmail = async (analysisId, page = 0, perPage = 100) => {
   try {
      const prospects = await db.prospects
         .where('analysisId')
         .equals(analysisId)
         .filter((prospect) => prospect.email)
         .offset(page * perPage)
         .limit(perPage)
         .toArray();
      return prospects;
   } catch (error) {
      throw error;
   }
};

export const searchProspects = async (analysisId, query, page = 0, perPage = 100) => {
   try {
      const prospects = await db.prospects
         .where('analysisId')
         .equals(analysisId)
         .filter(
            (prospect) =>
               (prospect.email && prospect.email.includes(query)) ||
               (prospect.first_name && prospect.first_name.includes(query)) ||
               (prospect.full_name && prospect.full_name.includes(query)) ||
               (prospect.username && prospect.username.includes(query))
         )
         .offset(page * perPage)
         .limit(perPage)
         .toArray();
      return prospects;
   } catch (error) {
      throw error;
   }
};

export const getNotSentProspects = async (analysisId, emailId, page = 0, perPage = 100) => {
   try {
      const prospects = await db.prospects
         .where('analysisId')
         .equals(analysisId)
         .filter((prospect) => prospect.email)
         .offset(page * perPage)
         .limit(perPage)
         .toArray()
         .then((prospects) => {
            return prospects;
         })
         .catch((error) => {
            console.error('Error:', error);
         });

      const prospectsNotSent = prospects.filter((prospect) => {
         if (!prospect.emails_sent) return true;
         const emailSent = prospect.emails_sent.find((email) => email.id === emailId);
         if (!emailSent) return true;
         return false;
      });

      return prospectsNotSent;
   } catch (error) {
      throw error;
   }
};

export const addEmailSentToProspect = async (prospectId, emailId) => {
   try {
      const prospect = await db.prospects.get(prospectId);

      // Añadir el emailId al array emails_sent
      if (!prospect.emails_sent) prospect.emails_sent = []; // Si el array no existe, inicialízalo
      prospect.emails_sent.push({ id: emailId, sentAt: new Date(), opened: false, opens: [] });

      // Sumar 1 a la propiedad sent_emails_count
      if (typeof prospect.sent_emails_count === 'undefined') {
         prospect.sent_emails_count = 1; // Si la propiedad no existe, inicialízala con 1
      } else {
         prospect.sent_emails_count += 1; // Si ya existe, simplemente suma 1
      }

      await db.prospects.put(prospect);
      return prospect;
   } catch (error) {
      throw error;
   }
};

export const clearAllProspects = async () => {
   db.prospects
      .clear()
      .then(() => {
         console.log('Todos los registros han sido eliminados.');
      })
      .catch((error) => {
         console.error('Error al eliminar los registros:', error);
         throw error;
      });
};
