import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../../lib/firebase/firebase";
import { getCurrentPeriodCreditsUsed } from "../../api/mailerfind/users";
import { getCustomerPortalURL, getMySubscription } from "../../api/mailerfind/stripe";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import { ArrowRepeat, ArrowUpCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function Billing({ user, simplified = false }: any) {
  const { t } = useTranslation("MyAccount.Billing");

  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null) as any;
  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(false);
  const [currentPeriodUsedCredits, setCurrentPeriodUsedCredits] = useState(0);
  const location = useLocation();

  const getUserUsedCredits = async () => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      const response = await getCurrentPeriodCreditsUsed(jwt);
      if (!response.currentPeriodRequests) return;
      setCurrentPeriodUsedCredits(response.currentPeriodRequests);
    } catch (err) {
      console.error(err);
    }
  };

  const getUserSubscription = async () => {
    try {
      setSubscriptionIsLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const { subscription } = await getMySubscription(jwt);
      setSubscription(subscription);
    } catch (err) {
      console.error(err);
    } finally {
      setSubscriptionIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) return;
    getUserSubscription();
  }, [auth.currentUser]);

  useEffect(() => {
    if (!user) return;
    getUserUsedCredits();
  }, [auth.currentUser]);

  const buildStripeReturnUrl = () => {
    return `http://${window.location.hostname}:${window.location.port}${location.pathname}`;
  };

  const checkIfIsImpersonating = async () => {
    const user = await auth.currentUser?.getIdTokenResult();
    return user?.claims.isImpersonated;
  };

  const redirectToCustomerPortal = async () => {
    if (!user) return;

    const isImpersonated = await checkIfIsImpersonating();
    if (isImpersonated) {
      toast.error(t("impersonating_error"));
      return;
    }

    setLoading(true);
    const stripeReturnUrl = buildStripeReturnUrl();
    try {
      const jwt = await auth.currentUser!.getIdToken();
      const response = await getCustomerPortalURL(stripeReturnUrl, jwt);
      window.location.href = response.link;
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  return (
    <div>
      <div className="card mb-5">
        <div className="card-header card-header-content-between border-bottom">
          <h4 className="card-header-title">{t("overview")}</h4>
        </div>
        <div className="card-body">
          <div className="row">
            {subscription || subscriptionIsLoading ? (
              <>
                {!simplified && (
                  <>
                    <div className="col-md-8 mb-4 mb-md-0">
                      <div className="mb-4">
                        <span className="card-subtitle">
                          {subscriptionIsLoading ? (
                            <Skeleton />
                          ) : (
                            <span>
                              {t("your_plan")} ({t("billed")} {subscription?.items?.data[0]?.plan?.interval === "month" ? t("monthly") : ""}
                              )
                            </span>
                          )}
                        </span>
                        <h3>
                          {subscriptionIsLoading ? <Skeleton /> : subscription?.items?.data[0].plan?.metadata?.nickname || "Mailerfind"}
                        </h3>
                      </div>
                      <span className="card-subtitle mt-4">
                        {subscriptionIsLoading ? (
                          <Skeleton />
                        ) : (
                          <>
                            <div>{t("status")}</div>
                            {subscription.status === "active" && (
                              <div id="billing_status" className="badge bg-soft-success text-success">
                                {subscription.status}
                              </div>
                            )}
                            {subscription.status === "trialing" && (
                              <div id="billing_status" className="badge bg-soft-success text-success">
                                {subscription.status}
                              </div>
                            )}
                            {subscription.status === "cancelled" && (
                              <div id="billing_status" className="badge bg-soft-danger text-danger">
                                {subscription.status}
                              </div>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-column">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          disabled={loading || subscriptionIsLoading}
                          onClick={redirectToCustomerPortal}
                        >
                          {loading ? (
                            <div className="px-6">
                              <span className="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
                            </div>
                          ) : (
                            <>
                              <ArrowRepeat className="mr-2" />
                              <span className="align-middle">{t("manage_subscription")}</span>
                            </>
                          )}
                        </button>
                        {user?.plan !== "unlimited" && (
                          <Link to="/upgrade" className="btn btn-white w-100">
                            <ArrowUpCircle className="mr-2" />
                            <span className="align-middle">{t("upgrade_plan")}</span>
                          </Link>
                        )}
                      </div>
                    </div>
                    <hr className="my-3 w-100" />
                  </>
                )}

                <div className="card-body pb-0">
                  <div className="row align-items-center flex-grow-1 mb-2">
                    <div className="col">
                      <h4 className="card-header-title">{t("analysis_usage")}</h4>
                    </div>
                    <div className="col-auto">
                      <span className="text-dark fw-semibold">
                        {currentPeriodUsedCredits} {t("users_analyzed")}
                      </span>{" "}
                      {t("of")} {user?.plan === "unlimited" ? t("unlimited") : user?.monthlyAnalysisLimit} {t("credits")}
                    </div>
                  </div>
                  <div className="progress mb-3" style={{ height: "25px" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          currentPeriodUsedCredits === 0 ? 0 : `${(currentPeriodUsedCredits / user?.monthlyAnalysisLimit || 1) * 100}%`
                        }`,
                      }}
                      aria-valuenow={
                        currentPeriodUsedCredits === 0 ? 0 : (currentPeriodUsedCredits / user?.monthlyAnalysisLimit || 1) * 100
                      }
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <ul className="list-inline list-px-2">
                    <li className="list-inline-item">
                      <span className="legend-indicator bg-primary" /> {t("credits_used")}
                    </li>
                    <li className="list-inline-item">
                      <span className="legend-indicator" /> {t("unused")}
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="col-md mb-4 mb-md-0 mx-auto text-center py-5">
                  <img
                    className="img-fluid mb-5"
                    src="/assets/svg/illustrations/oc-collaboration.svg"
                    alt="Collaboration"
                    data-hs-theme-appearance="default"
                    style={{ width: 150 }}
                  />
                  <h1>{t("no_active_subscription")}</h1>
                  <p>{t("no_active_subscription_message")}</p>
                  <a className="btn btn-primary" href="https://mailerfind.com/pricing">
                    {t("subscribe_plan")}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;
