// Importamos el módulo Dexie.js que nos permite trabajar con IndexedDB de una manera más sencilla.
import Dexie from "dexie";

// Creamos una nueva instancia de Dexie para nuestra base de datos 'localDatabase'.
const db = new Dexie("localDatabase");

// Función que define las tiendas para una versión dada de la base de datos.
// Esto ayuda a mantener nuestro código organizado y hace más fácil cambiar la estructura de la base de datos en el futuro.
function defineStoresForVersion1(db) {
  db.version(14).stores({
    analysis:
      "++id, name, mode, status, selectedItem, prospectsCount, cursor, emailsFoundCount, businessAccountsCount, publicAccountsCount, privateAccountsCount, creditsUsed, phoneNumbersFoundCount, websitesFoundCount, createdAt, ownerId, project",

    prospects:
      "++id, *analysisId, username, first_name, full_name, email, phone_number, website, is_private, is_business, profile_pic_url, followers, following, city, biography, emails_sent, unsubscribed, createdAt",
  });
}

// Llamamos a nuestra función para definir las tiendas de la versión 1 de la base de datos.
defineStoresForVersion1(db);

// Exportamos la instancia de nuestra base de datos para que pueda ser usada en otros módulos de nuestra aplicación.
export default db;
