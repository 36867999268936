import { BACKEND_URL } from '../../utils/constants';
import makeRequest from '../client';

export const getHashtagMedia = async (
   hashtag: string,
   endCursor: string | null,
   nextPage: number,
   jwt: string
): Promise<any> => {
   return await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-hashtag-media`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         hashtag,
         endCursor,
         nextPage,
      },
   });
};

export const getHashtagInfo = async (hashtag: string, jwt: string): Promise<any> => {
   return await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/get-instagram-hashtag-info`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         name: hashtag,
      },
   });
};
