export const SPEED = {
  SLOW: "slow",
  NORMAL: "normal",
  FAST: "fast",
  ULTRA_FAST: "ultra_fast",
  CUSTOM: "custom",
} as const;

export const STATUS = {
  NOT_STARTED: "NOT_STARTED",
  STARTING: "STARTING",
  PAUSED: "PAUSED",
  PAUSING: "PAUSING",
  RUNNING: "RUNNING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
  RETRYING: "RETRYING",
} as const;

export const STATUS_TYPES = [
  { status: "RUNNING", color: "bg-primary", show: true },
  { status: "COMPLETED", color: "bg-success", show: true },
  { status: "NOT_STARTED", color: "bg-secondary", show: true },
  { status: "PAUSED", color: "bg-warning", show: true },
  { status: "PAUSING", color: "bg-warning", show: false },
  { status: "STARTING", color: "bg-primary", show: false },
  { status: "ERROR", color: "bg-danger", show: true },
] as const;

export const MODE = {
  FOLLOWERS: "followers",
  FOLLOWING: "following",
  LIKERS: "likers",
  COMMENTERS: "commenters",
  HASHTAG: "hashtag",
  LOCATION: "location",
} as any;

export const EMAIL_AI_OPTIONS = {
  WRITE: "write",
  IMPROVE: "improve",
  TONE: "tone",
  TRANSLATE: "translate",
} as const;

export const PRICES = {
  free: {
    month: {
      eur: {
        development: "price_1O15sBGhTxQgzhwgOmaYH4AZ", // OK
        production: "price_1O0hJKGhTxQgzhwgis1R7lvM", // OK
      },
      usd: {
        development: "price_1NPl45GhTxQgzhwgsVV53Inv,price_1NPlI1GhTxQgzhwgSrv6nrtq",
        production: "price_1NPlL0GhTxQgzhwgGPfGCYrP", // OK
      },
    },
  },
  starter: {
    month: {
      eur: {
        development: "price_1Lb1biGhTxQgzhwgBxFyoZXh", // OK
        production: "price_1MPTwPGhTxQgzhwgAnHTuEQG", // OK
      },
      usd: {
        development: "price_1LaMbUGhTxQgzhwgOpTJBhO2",
        production: "price_1LaMbUGhTxQgzhwgOpTJBhO2", // OK
      },
    },
    year: {
      eur: {
        development: "price_1Q9WS3GhTxQgzhwgoV7CTeHP", // OK
        production: "price_1MkVLyGhTxQgzhwgseCdMiwF", // OK
      },
      usd: {
        development: "price_1MkVMSGhTxQgzhwgWUbikpDF",
        production: "price_1MkVMSGhTxQgzhwgWUbikpDF", // OK
      },
    },
  },
  enterprise: {
    month: {
      eur: {
        development: "price_1MkWRKGhTxQgzhwgoFs8CTOf",
        production: "price_1Mle1YGhTxQgzhwgJNu7OO5V", // OK
      },
      usd: {
        development: "price_1Mle1yGhTxQgzhwgPl8b9Mp0",
        production: "price_1Mle1yGhTxQgzhwgPl8b9Mp0", // OK
      },
    },
    year: {
      eur: {
        development: "price_1Q9WT2GhTxQgzhwgQb4SrRaK", // OK
        production: "price_1MkVOJGhTxQgzhwgSfdYLEFF", // OK
      },
      usd: {
        development: "price_1MkVOaGhTxQgzhwgXQKPLbYU",
        production: "price_1MkVOaGhTxQgzhwgXQKPLbYU", // OK
      },
    },
  },
  unlimited: {
    month: {
      eur: {
        development: "price_1O84s8GhTxQgzhwgHqUJBNrW", // OK
        production: "price_1O7a0XGhTxQgzhwgOfrHNkDC", // OK
      },
      usd: {
        development: "price_1MldziGhTxQgzhwgNPBby5lj",
        production: "price_1MldziGhTxQgzhwgNPBby5lj", // OK
      },
    },
    year: {
      eur: {
        development: "price_1Q9WUCGhTxQgzhwgNvZFlvpa",
        production: "price_1Q9WwFGhTxQgzhwgkFlHL8cR", // OK
      },
      usd: {
        development: "price_1MkVPJGhTxQgzhwgplHcZ9Y0",
        production: "price_1MkVPJGhTxQgzhwgplHcZ9Y0", // OK
      },
    },
  },
} as any;

export const DEFAULT_ANALYSIS_NAME = "New Analysis";

export const BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5001/mailerfind/us-central1/mailerfindAPI"
    : "https://us-central1-mailerfind.cloudfunctions.net/mailerfindAPI";

export const FRONTEND_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://app.mailerfind.com";
