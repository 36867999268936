import React, { useEffect, useState } from "react";
import { getAllInvoices } from "../../api/mailerfind/stripe";
import toast from "react-hot-toast";
import { auth } from "../../lib/firebase/firebase";
import Moment from "react-moment";
import { ChevronLeft, ChevronRight, FiletypePdf } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

function OrderHistory() {
  const [invoices, setInvoices] = useState([]) as any;
  const [loading, setLoading] = useState(false);
  const [pageHistory, setPageHistory] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { t } = useTranslation("OrderHistory");

  const fetchInvoices = async (startAfter = "", resetHistory = false) => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const response = await getAllInvoices(startAfter, 10, jwt);

      if (resetHistory) {
        setPageHistory([startAfter]);
      } else {
        setPageHistory((prev) => [...prev, startAfter]);
      }

      setHasNextPage(response.data.has_more);
      setInvoices(response.data.data);
    } catch (err) {
      toast.error(t("errorFetchingInvoices"));
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = async () => {
    const startAfter = invoices.length ? invoices[invoices.length - 1]?.id : "";
    await fetchInvoices(startAfter);
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = async () => {
    const previousStartAfter = pageHistory[currentPage - 1];
    await fetchInvoices(previousStartAfter);
    setCurrentPage((prev) => prev - 1);
  };

  useEffect(() => {
    if (!auth.currentUser) return;
    fetchInvoices("", true);
  }, [auth.currentUser]);

  return (
    <div className="card mb-5">
      <div className="card-header card-header-content-between border-bottom">
        <h4 className="card-header-title">{t("orderHistoryTitle")}</h4>
      </div>
      <div className="">
        {loading ? (
          <table className="table table-borderless table-align-middle table-nowrap">
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ paddingLeft: "2rem" }}>
                  {t("date")}
                </th>
                <th scope="col">{t("status")}</th>
                <th scope="col">{t("amount")}</th>
                <th scope="col">{t("invoice")}</th>
              </tr>
            </thead>
            <tbody>
              {Array(6)
                .fill(0)
                .map((_, index) => (
                  <tr key={index}>
                    <td className="pl-5">
                      <Skeleton width={100} />
                    </td>
                    <td>
                      <Skeleton width={70} />
                    </td>
                    <td>
                      <Skeleton width={70} />
                    </td>
                    <td>
                      <Skeleton width={60} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : invoices.length > 0 ? (
          <table className="table table-borderless table-align-middle table-nowrap">
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ paddingLeft: "2rem" }}>
                  {t("date")}
                </th>
                <th scope="col">{t("status")}</th>
                <th scope="col">{t("amount")}</th>
                <th scope="col">{t("invoice")}</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice: any, index: number) => (
                <tr key={index}>
                  <td className="pl-5">
                    <Moment unix format="DD/MM/YYYY">
                      {invoice.effective_at}
                    </Moment>
                  </td>
                  <td>
                    <span className="badge bg-soft-success text-success">{invoice.status.toUpperCase()}</span>
                  </td>
                  <td>
                    <span>
                      {new Intl.NumberFormat("es-ES", {
                        style: "currency",
                        currency: invoice.currency.toUpperCase(),
                      }).format(invoice.amount_paid / 100)}
                    </span>
                  </td>
                  <td>
                    <a className="btn btn-white btn-sm px-4" href={invoice.invoice_pdf} target="_blank" rel="noopener noreferrer">
                      <FiletypePdf className="bi-file-earmark-arrow-down-fill mr-1" /> PDF
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="card-body d-flex flex-column text-center">
            <img
              className="avatar avatar-xxl my-3 mx-auto"
              src="/assets/svg/illustrations/oc-browse.svg"
              alt={t("imageAltDescription")}
              data-hs-theme-appearance="default"
            />
            <p className="m-0">{t("noInvoicesMessage")}</p>
            <p>
              {t("contactSupportMessage")} <a href="mailto:support@mailerfind.com">{t("contactSupportLink")}</a>.
            </p>
          </div>
        )}
      </div>
      <div className="card-footer">
        <div className="row justify-content-center justify-content-sm-end align-items-sm-center">
          <div className="col-sm-auto">
            <div className="d-flex justify-content-center justify-content-sm-end">
              <button
                className="btn paginate_button previous page-link mr-2"
                onClick={handlePrevPage}
                disabled={currentPage === 0 || loading}
              >
                <ChevronLeft className="mr-1" size={12} />
                <span className="align-middle">{t("prev")}</span>
              </button>
              <button className="btn paginate_button previous page-link" onClick={handleNextPage} disabled={!hasNextPage || loading}>
                <span className="align-middle">{t("next")}</span>
                <ChevronRight className="ml-1" size={12} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
