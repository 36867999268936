import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { auth } from "../../lib/firebase/firebase";
import { ProjectContext } from "../../contexts/ProjectContext";
import { getSenders } from "../../lib/firebase/senders";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    },
  }),
};

function SenderSelect(props: any) {
  const { t } = useTranslation("NewEmail.SenderSelect");
  const [user] = useState(auth.currentUser) as any;
  const { project } = useContext(ProjectContext) as any;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [senders, setSenders] = useState([]) as any;

  const fetchSenders = async () => {
    try {
      setLoading(true);
      const senders = await getSenders(user.uid, { project: project?.id || null });
      const transformedOptions = senders.map((option: any) => ({
        value: option.id,
        label: `${option.name} <${option.email}>`,
      }));
      setSenders(senders);
      setOptions(transformedOptions);
    } catch (err: any) {
      toast.error(err.message || t("error_fetching_senders"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSenders();
  }, []);

  const handleChange = (selectedOptions: any) => {
    props.onChange(selectedOptions);
  };

  const selectedSenders = props?.email?.senders
    ?.map((senderId: any) => {
      const sender = senders.find((option: any) => option.id === senderId);
      return sender ? { value: sender.id, label: `${sender.name} <${sender.email}>` } : null;
    })
    .filter(Boolean);

  return <Select options={options} value={selectedSenders} isMulti onChange={handleChange} onFocus={fetchSenders} styles={customStyles} />;
}

export default SenderSelect;