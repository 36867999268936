import { FilloutStandardEmbed } from "@fillout/react";
import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { UserContext } from "../../../contexts/UserContext";

function PurchasedModal({ show, setShow }: any) {
  const { user } = useContext(UserContext) as any;
  return (
    <Modal show={show} size="lg" onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Technical Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ width: "100%", height: 500 }}
          data-fillout-id="wWymauxrXQus"
          data-fillout-embed-type="standard"
          data-fillout-inherit-parameters
          data-fillout-dynamic-resize
        >
          <FilloutStandardEmbed
            filloutId="wWymauxrXQus"
            parameters={{
              email: user.firebaseUser.email,
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PurchasedModal;
