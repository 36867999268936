import React from "react";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { capitalizeString } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

function InvitesTable({ invitations, loading, handlePageChange, totalPages }: any) {
  const { t } = useTranslation("Invitations.InvitesTable");

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">{t("invitations")}</h4>
      </div>
      {/* Table */}
      {!loading && invitations.length === 0 ? (
        <NoInvites />
      ) : (
        <>
          <div className="table-responsive">
            <table className="table table-nowrap mb-0 table-borderless table-align-middle">
              <thead className="thead-light">
                <tr>
                  <th className="px-4">{t("name")}</th>
                  <th>{t("invited_at")}</th>
                  <th>{t("status")}</th>
                  <th>{t("accepted_at")}</th>
                  <th>{t("rewards")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <>
                    <Row invitation={null} loading={loading} />
                    <Row invitation={null} loading={loading} />
                    <Row invitation={null} loading={loading} />
                    <Row invitation={null} loading={loading} />
                    <Row invitation={null} loading={loading} />
                  </>
                ) : (
                  <>
                    {invitations.map((invitation: any, index: number) => {
                      return <Row key={index} invitation={invitation} loading={loading} />;
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            {/* Pagination */}
            <div className="row justify-content-center justify-content-sm-end align-items-sm-center">
              {/* End Col */}
              <div className="col-sm-auto">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  {/* Pagination */}
                  <ReactPaginate
                    className="dataTables_paginate paging_simple_numbers pagination datatable-custom-pagination"
                    pageClassName="paginate_item page-item" // li (button)
                    pageLinkClassName="paginate_button page-link" // a (button)
                    previousClassName="paginate_item page-item" // li (button)
                    previousLinkClassName="paginate_button previous page-link" // a (button)
                    nextClassName="paginate_item page-item" // li (button)
                    nextLinkClassName="paginate_button next page-link" // a (button)
                    activeClassName="active"
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    breakLabel={"..."}
                    previousLabel={t("prev")}
                    nextLabel={t("next")}
                  />
                </div>
              </div>
              {/* End Col */}
            </div>
            {/* End Pagination */}
          </div>
        </>
      )}

      {/* End Table */}
    </div>
  );
}

function Row({ invitation, loading }: any) {
  const { t } = useTranslation("Invitations.InvitesTable");

  return (
    <tr>
      <td className="px-4 py-3">
        <div className="d-flex">
          <div className="flex-shrink-0">
            <div className="avatar avatar-circle">
              <div className="flex-shrink-0">
                <div className="avatar avatar-soft-primary avatar-circle">
                  {loading ? (
                    <span className="avatar-initials">A</span>
                  ) : (
                    <span className="avatar-initials">{invitation.email.slice(0, 1)}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 ml-3">
            <span className="d-block h5 text-inherit mb-0">
              {loading ? (
                <Skeleton width={190} />
              ) : (
                <>
                  {invitation.invitee ? (
                    <>
                      {/* if invitee has firstname or has surname then show them, if not, show "-"*/}
                      {invitation.invitee.firstName || invitation.invitee.surname ? (
                        <>
                          {invitation.invitee.firstname} {invitation.invitee.surname}
                        </>
                      ) : (
                        <>{invitation.email}</>
                      )}
                    </>
                  ) : (
                    <>{invitation.email}</>
                  )}
                </>
              )}
            </span>
            <span className="d-block font-size-sm text-body">{loading ? <Skeleton width={120} /> : <>{invitation.email}</>}</span>
          </div>
        </div>
      </td>
      <td>{loading ? <Skeleton width={87} /> : <Moment format="DD MMM YYYY">{invitation.invitedAt}</Moment>}</td>
      <td>
        {loading ? (
          <Skeleton width={90} />
        ) : (
          <>
            {invitation.status.toLowerCase() === "accepted" && <span className="legend-indicator bg-success" />}
            {invitation.status.toLowerCase() === "pending" && <span className="legend-indicator bg-warning" />}
            {invitation.status.toLowerCase() === "rejected" && <span className="legend-indicator bg-danger" />}
            {capitalizeString(t(invitation.status.toLowerCase()))}
          </>
        )}
      </td>
      <td>
        {loading ? (
          <Skeleton width={87} />
        ) : (
          <>{invitation.acceptedAt ? <Moment format="DD MMM YYYY">{invitation.acceptedAt}</Moment> : "-"}</>
        )}
      </td>
      <td>
        {loading ? (
          <Skeleton width={80} />
        ) : (
          <span>{invitation.status.toLowerCase() === "accepted" ? <span className="text-success">{t("credits")}</span> : "-"}</span>
        )}
      </td>
    </tr>
  );
}

function NoInvites() {
  const { t } = useTranslation("Invitations.InvitesTable");

  return (
    <div className="py-4 w-100">
      <div className="py-4">
        <div className="text-center">
          <img src="/assets/svg/illustrations/oc-browse.svg" alt="Empty" className="img-fluid mb-4" style={{ maxWidth: "10rem" }} />
          <p className="mb-0 text-center mb-4">{t("no_invitations")}</p>
        </div>
      </div>
    </div>
  );
}

export default InvitesTable;