import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { UserContext } from "../../contexts/UserContext";
import Step4 from "./Step4";
import toast from "react-hot-toast";
import { WizardContext } from "../../contexts/WizardContext";
import Step5 from "./Step5";
import Loading from "../../components/Loading";

function Wizard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext) as any;
  const { wizard, updateWizard } = useContext(WizardContext) as any;

  const setAnalysisId = async (analysisId: string) => {
    await updateWizard({ analysisId });
  };

  const setSenderId = async (senderId: string) => {
    await updateWizard({ senderId });
  };

  const setEmailId = async (emailId: string | null) => {
    await updateWizard({ emailId });
  };

  const fetchWizard = async () => {
    if (user?.firebaseUser?.wizard) {
      await updateWizard(user?.firebaseUser?.wizard);
    }
  };

  useEffect(() => {
    if (!user) return;

    fetchWizard();

    if (user?.firebaseUser?.wizard?.completed) {
      navigate("/");
      toast.error("The onboarding process has already been completed");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!id || !user || user?.firebaseUser?.wizard?.completed) return <Loading />;

  // El usuario no puede ir a un paso superior manualmente
  if ((wizard?.currentStep || 1) < id) {
    // navigate("/onboarding/step/" + (wizard?.currentStep || 1));
  }

  switch (id) {
    case "1":
      return <Step1 setAnalysisId={setAnalysisId} />;

    case "2":
      return <Step2 wizard={wizard} />;

    case "3":
      return <Step3 wizard={wizard} setSenderId={setSenderId} />;

    case "4":
      return <Step4 wizard={wizard} setEmailId={setEmailId} />;

    case "5":
      return <Step5 wizard={wizard} />;

    default:
      return <></>;
  }
}

export default Wizard;
