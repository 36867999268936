import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { sendPasswordResetEmail } from "../../api/mailerfind/users";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const { t } = useTranslation("ForgotPassword.index");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await sendPasswordResetEmail(email);
      toast.success(t("password_reset_email_sent"));
      setEmail("");
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        className="position-fixed"
        style={{
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          right: "0",
          left: "0",
          top: "0",
          height: "318px",
          width: "150%",
          backgroundImage: "url(/assets/svg/components/card-6.svg)",
        }}
      >
        {/* Shape */}
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
        {/* End Shape */}
      </div>
      {/* Content */}
      <div className="container py-5 py-sm-7">
        <Link to="/" className="d-flex justify-content-center mb-5">
          <img
            className="zi-2"
            src="/assets/img/logos/mailerfind.png"
            alt="Logo"
            style={{ width: "8rem" }}
          />
        </Link>
        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          {/* Card */}
          <div className="card card-lg mb-5">
            <div className="card-body">
              {/* Form */}
              <form
                className="js-validate needs-validation"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="text-center">
                  <div className="mb-5">
                    <h1 className="display-5">{t("forgot_password")}</h1>
                    <p>{t("enter_email_instructions")}</p>
                  </div>
                </div>
                {/* Form */}
                <div className="mb-4">
                  <label className="form-label" htmlFor="email" tabIndex={0}>
                    {t("your_email")}
                  </label>
                  <input
                    type="email"
                    onChange={handleChange}
                    className="form-control"
                    name="email"
                    id="email"
                    tabIndex={1}
                    value={email}
                    placeholder={t("enter_email_placeholder")}
                    aria-label={t("enter_email_placeholder")}
                    required
                  />
                  <span className="invalid-feedback">
                    {t("invalid_email_message")}
                  </span>
                </div>
                {/* End Form */}
                <div className="d-grid gap-2">
                  <button
                    type="submit"
                    id="submit"
                    className="btn btn-primary btn-block"
                    disabled={loading}
                  >
                    {t("submit")}
                  </button>
                  <div className="text-center">
                    <Link to="/login" className="btn btn-link">
                      <i className="bi-chevron-left" /> {t("back_to_sign_in")}
                    </Link>
                  </div>
                </div>
              </form>
              {/* End Form */}
            </div>
          </div>
          {/* End Card */}
        </div>
      </div>
      {/* End Content */}
    </div>
  );
}

export default ForgotPassword;