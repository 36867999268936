import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import Loading from "./Loading";

const ProtectedRoute = ({ children }: any) => {
  const { user } = useContext(UserContext) as any;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.firebaseUser) {
      navigate("/login", { state: { from: location.pathname } });
    }
  }, [user, location, navigate]);

  if (!user.firebaseUser) {
    return <Loading />;
  }

  return children;
};

export default ProtectedRoute;
