import { BACKEND_URL } from '../../utils/constants';
import makeRequest from '../client';

export const searchUsers = async (username: string, jwt: string): Promise<any> => {
   return await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/instagram-search-users`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         query: username,
      },
   });
};

export const searchHashtags = async (hashtag: string, jwt: string): Promise<any> => {
   return await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/instagram-search-hashtags`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         query: hashtag,
      },
   });
};

export const searchPlaces = async (place: string, jwt: string): Promise<any> => {
   return await makeRequest({
      url: `${BACKEND_URL}/v1/mailerfind/instagram-search-places`,
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${jwt}`,
      },
      body: {
         query: place,
      },
   });
};
