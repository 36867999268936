import React, { useContext, useEffect, useState } from "react";
import { auth } from "../lib/firebase/firebase";
import { sendEmailVerification } from "firebase/auth";
import toast from "react-hot-toast";
import { getMySubscription } from "../api/mailerfind/stripe";
import { ExclamationTriangleFill, InfoCircleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useTranslation } from "react-i18next";
import UpdateBillingModal from "./UpdateBillingModal";

function AccountStatusAlert() {
  const { user } = useContext(UserContext) as any;
  const [subscription, setSubscription] = useState(null) as any;
  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(false);
  const { t } = useTranslation("Component.AccountStatusAlert");

  useEffect(() => {
    if (!auth.currentUser) return;
    getUserSubscription();
  }, [auth.currentUser]);

  const getUserSubscription = async () => {
    try {
      setSubscriptionIsLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const { subscription } = await getMySubscription(jwt);
      setSubscription(subscription);
    } catch (err: any) {
      console.log(err);
    } finally {
      setSubscriptionIsLoading(false);
    }
  };

  const handleResend = async () => {
    const toastId = toast.loading("Sending...");
    if (!auth.currentUser) {
      throw new Error("User is not authenticated");
    }
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}/?verified=true`,
        handleCodeInApp: true,
      };
      await sendEmailVerification(auth.currentUser, actionCodeSettings);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      toast.success("Email sent", {
        id: toastId,
      });
    } catch (err) {
      toast.error("Failed", {
        id: toastId,
      });
    }
  };

  return (
    <>
      {auth.currentUser?.emailVerified === false && (
        <div className="alert alert-soft-warning d-flex justify-content-between" role="alert">
          <p className="m-0 p-0 my-auto">
            <ExclamationTriangleFill className="align-middle mr-2" /> {t("verifyEmailMessage")}
          </p>
          <button className="btn btn-xs btn-outline-warning" onClick={handleResend}>
            {t("resendButton")}
          </button>
        </div>
      )}
      {!subscriptionIsLoading && !subscription && (
        <div className="alert alert-soft-warning d-flex justify-content-between" role="alert">
          <p className="m-0 p-0 my-auto">
            <ExclamationTriangleFill className="align-middle mr-2" />
            {t("noSubscriptionMessage")}
          </p>
          <Link className="btn btn-xs btn-outline-warning" to="/register?plan=free">
            {t("activateButton")}
          </Link>
        </div>
      )}
      <UpdateBillingModal />
    </>
  );
}

export default AccountStatusAlert;
